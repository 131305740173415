import React from "react";
import { Redirect, Switch } from "react-router-dom";

import { useSubheader, ContentRoute } from "../../../../_metronic/layout";
import LibrariesList from "./libraries-list/LibrariesList";
import LibraryDetails from "./library-details/LibraryDetails";
import LessonDetails from "./lesson-details/LessonDetails";
import LibraryCreate from "./library-crud/library-create/LibraryCreate";
import LibraryUpdate from "./library-crud/library-update/LibraryUpdate";

export default function LibrariesPage() {
	const suhbeader = useSubheader();
	suhbeader.setTitle("Libraries Page");

	return (
		<Switch>
			{
				<Redirect
					exact={true}
					from="/libraries"
					to="/libraries/trainings"
				/>
			}

			<ContentRoute exact from="/libraries/create-new-library" component={LibraryCreate}/>
			<ContentRoute exact from="/libraries/update-library/:library_party_id" component={LibraryUpdate}/>
			<ContentRoute exact from="/libraries/:library_type" component={LibrariesList} />
			<ContentRoute exact from="/libraries/:library_type/:library_party_id" component={LibraryDetails}/>
			<ContentRoute exact from="/libraries/:library_type/:library_party_id/module/:module_resource_id/lesson/:lesson_resource_id" component={LessonDetails}/>

		</Switch>
	);
}
