import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function get_owner_companies_list(isSuperAdmin = "") {
    return await axios.get(API_URL + "/company/get-owner-companies-list" + "?isSuperAdmin=" + isSuperAdmin);
}

export async function create_new_company(values) {

    let dataPost = new FormData();

	for (const [key, value] of Object.entries(values)) {
		dataPost.append(key, value);
	}

    return await axios.post(API_URL + "/company/create-new-company", dataPost);
}

export async function get_owner_company(slug, isSuperAdmin = "") {
    return await axios.get(API_URL + "/company/get-owner-company/" + slug + "?isSuperAdmin=" + isSuperAdmin);
}

export async function create_owner_company_library(values) {
    return await axios.post(API_URL + "/company/create-owner-company-library", values);
}

export async function get_owner_company_libraries_list(slug) {
    return await axios.get(API_URL + "/company/get-owner-company-libraries-list/" + slug);
}

export async function get_student_companies_list() {
    return await axios.get(API_URL + "/company/get-student-companies-list");
}

export async function get_student_company_libraries_list(slug) {
    return await axios.get(API_URL + "/company/get-student-company-libraries-list/" + slug);
}

export async function sign_up_as_student_to_company(values) {
    return await axios.post(API_URL + "/auth/sign-up-as-student-to-company", values);
}

export async function sign_up_as_student_to_library_company(values) {
    return await axios.post(API_URL + "/auth/sign-up-as-student-to-library-company", values);
}

export async function sign_up_as_mentor_to_library_company(values) {
    return await axios.post(API_URL + "/auth/sign-up-as-mentor-to-library-company", values);
}

export async function import_as_student_to_company(values) {
    let dataPost = new FormData();

	for(const [key, value] of Object.entries(values)) {
		dataPost.append(key, value);
	}

    return await axios.post(API_URL + "/auth/import-as-student-to-company", dataPost);
}

export async function get_company_users(slug, isSuperAdmin = "") {
    return await axios.get(API_URL + "/company/get-owner-company-students-list/" + slug + "?isSuperAdmin=" + isSuperAdmin);
}

export async function get_student_company(slug) {
    return await axios.get(API_URL + "/company/get-student-company/" + slug);
}

export async function get_student_company_library_details(library_party_id, company_slug) {
    return await axios.get(API_URL + "/company/get-student-company-library-details/" + library_party_id + "?company_slug=" + company_slug);
}

export async function get_student_company_library_content(library_party_id, company_slug) {
    return await axios.get(API_URL + "/company/get-student-company-library-content/" + library_party_id + "?company_slug=" + company_slug);
}

export async function get_student_company_lesson_details(library_party_id, module_resource_id, lesson_resource_id, company_slug) {
    return await axios.get(API_URL + "/company/get-student-company-lesson-details/" + lesson_resource_id + `?library_party_id=${library_party_id}&module_resource_id=${module_resource_id}&company_slug=${company_slug}`);
}

export async function get_student_company_lesson_content(library_party_id, module_resource_id, lesson_resource_id, company_slug) {
    return await axios.get(API_URL + "/company/get-student-company-lesson-content/" + lesson_resource_id + `?library_party_id=${library_party_id}&module_resource_id=${module_resource_id}&company_slug=${company_slug}`);
}

export async function submit_student_company_element_response(values) {
    return await axios.post(API_URL + "/company/submit-student-company-element-response", values);
}

export async function get_student_company_attachment_responses(library_party_id, module_resource_id, lesson_resource_id, element_id, element_attachment_id, company_slug) {
    return await axios.get(API_URL + "/company/get-student-company-attachment-responses/" + element_attachment_id + `?library_party_id=${library_party_id}&module_resource_id=${module_resource_id}&lesson_resource_id=${lesson_resource_id}&element_id=${element_id}&company_slug=${company_slug}`);
}

export async function request_company_student_library_access(post_values) {
    return await axios.post(API_URL + "/library/request-company-student-library-access", post_values);
}

export async function update_owner_company(company_slug, values) {

    let dataPost = new FormData();

	for (const [key, value] of Object.entries(values)) {
		dataPost.append(key, value);
	}

    return await axios.post(API_URL + "/company/update-owner-company/" + company_slug, dataPost);
}

export async function update_allow_teams_status(company_slug, value) {
    let dataPost = new FormData();
	
    dataPost.append("allow_teams", value);

    return await axios.post(API_URL + "/company/update-allow-teams-status/" + company_slug, dataPost);
}

export async function update_allow_positions_status(company_slug, value) {
    let dataPost = new FormData();
	
    dataPost.append("allow_positions", value);

    return await axios.post(API_URL + "/company/update-allow-positions-status/" + company_slug, dataPost);
}