import React from "react";
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik'

import { get_element_attachment_by_code } from '../pages/Libraries/_redux/LibrariesCrud';

import { 
	FormControlLabel,
	Checkbox,
	TextField
}
from '@material-ui/core';

export const QuickAccessSettings = ({...props}) => {
	return (
		<div className="form-group">
			<FormControlLabel
				style={{
					'display': 'block',
					'textAlign': 'left'
				}}
				control={
					<Checkbox
						name="has_quick_access_link"
						color="primary"
						checked={
							!props.values.has_quick_access_link
							? false
							: true
						}
						onChange={() => {
							let newVal = typeof props.values.has_quick_access_link === "undefined" || props.values.has_quick_access_link == 0
							? 1
							: 0

							props.setFieldValue('has_quick_access_link', newVal);
						}}
					/>
				}
				label="Add quick access link"
			/>

			{
				props.values.has_quick_access_link == 1 && (
					<TextField
						label="Quick Access Link (Ex.: https://go.lernnex.com/me/(text here))"
						color="secondary"
						name="quick_access_link"
						placeholder="Ex.: https://go.lernnex.com/me/(text here)"
						margin="normal"
						variant="outlined"
						onBlur={props.handleBlur}
						onChange={props.handleChange}
						value={props.values.quick_access_link}
					/>
				)
			}
		</div>
	);
}

export const EnterPin = ({ ...props }) => {
	return (
		<Formik
			initialValues={{
				pin: ""
			}}
			onSubmit={(values, { setSubmitting }) => {
				get_element_attachment_by_code(values.pin).then((response) => {
					if(response.data.init[0].status == "error") {
						props.snackbarAlert('error', response.data.init[0].message);
					}
					else {
						props.setAttachment(response.data.data[0]);
						props.setIsValidPin(true);
						props.actions.setQuickAccessPin(values.pin);
						props.setCustomizedText("");
					}

					setSubmitting(false);
				});
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				setSubmitting
			}) => (
				<form onSubmit={handleSubmit}>
					<div className={ props.isRow ? "pin-container-row" : "pin-container" }>
						<div className="pin-wrapper">
							<h2>{ props.isRow ? "Enter Another PIN" : "Enter PIN" }</h2>
							<TextField
								hiddenLabel
								className="d-block w-100 my-3"
								name="pin"
								variant="outlined"
								placeholder="Please enter the pin"
								value={values.pin}
								onBlur={handleBlur}
								onChange={handleChange}
								InputProps={{
									className: "w-100",
									style:{ fontSize: "1.5rem", fontWeight: "600"}
								}}
							/>
							<Button
								type="submit"
								className="w-100 px-2 py-5"
								style={{ fontSize: "1.2rem", fontWeight: "600" }}
								disabled={isSubmitting}
							>
								Submit
							</Button>
						</div>
					</div>
				</form>
			)}
		</Formik>
	)
}