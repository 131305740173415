export const DEFAULT_AUTH_HEADER = {
	headers: {
		"Authorization": "Bearer " + process.env.REACT_APP_DEFAULT_API_KEY
	}
};

export const CHATGPT_AUTH_HEADER = {
	headers: {
		"Authorization": "Bearer " + process.env.REACT_APP_DEFAULT_API_KEY,
		"Content-Type": "application/json",
		"Accept": "text/html"
	}
};