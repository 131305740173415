import React, { useState, useEffect } from 'react'
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import { useSnackbar } from "notistack";

import { 
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
    Typography,
	TextField,
	Avatar,
 } from '@material-ui/core';
 
import { 
	get_element_types, 
	create_owner_element, 
	get_student_attachment_responses, 
	get_owner_attachment_responses, 
	delete_owner_element,
	create_mentor_element,
} from '../../../_redux/LibrariesCrud';
import { getUserByToken } from '../../../../../modules/Auth/_redux/authCrud';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const CreateOwnerElement = injectIntl(({ elements, setElements, params, lesson, intl }) => {
	const [open, setOpen] = useState(false);
    const [elementTypes, setElementTypes] = useState([]);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

    useEffect(() => {
        get_element_types().then(({ data: { element_types_query } }) => {
            if(element_types_query) {
                setElementTypes(element_types_query);
            }
        }).catch(err => console.log(err));
    }, []);

	return <Formik
		enableReinitialize
		initialValues={{
			element_type_id: 0,
            library_party_id: params.library_party_id,
            module_resource_id: params.module_resource_id,
            lesson_resource_id: params.lesson_resource_id,
			elements_count: elements.length
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			create_owner_element(values).then(({ data: { new_element } }) => {
				if(new_element) {
					let newElements = elements;
					newElements.push(new_element);
					setElements([]);
					setElements(newElements);
					setSubmitting(false);
					resetForm();
					window.scrollTo(0,document.body.scrollHeight);
					handleClose();
				}
			}).catch(err => {
				setSubmitting(false);
				console.log(err);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
                <button 
                    type="button"
                    className="btn btn-primary btn-block mx-auto w-100"
                    onClick={handleClickOpen}
                >
                    Add a Content
                </button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
					<DialogTitle id="form-dialog-title">Create a new element</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Lesson: </span>{lesson.title}
						</DialogContentText>
						
                        <div className="row justify-content-center">
                            {elementTypes.map((indiv_element_type) => {
                                return <div key={indiv_element_type.id} className="col-6 col-lg-3 col-md-4" style={{textAlignLast: "center"}}>
                                    <button 
                                        type="submit"
                                        className={`btn ${values.element_type_id === indiv_element_type.id && "border-success"}`}
                                        onClick={() => {
											setFieldValue("element_type_id", indiv_element_type.id);
											handleSubmit();
										}}
										disabled={isSubmitting}
                                    >
                                        <img 
                                            src={indiv_element_type.image_src}
                                            alt="Element Button"
                                            style={{ maxHeight: "10vh", diplay: "block" }}
                                        />
                                    </button>
                                    <Typography align="center">
                                        {indiv_element_type.name}
                                    </Typography>
                                </div>
                            })}
                        </div>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					{/* <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button> */}
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const ImportOwnnerElement = injectIntl(({ params, lesson, elements, intl }) => {
	const [open, setOpen] = useState(false);
    const [elementTypes, setElementTypes] = useState([]);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

    useEffect(() => {
        get_element_types().then(({ data: { element_types_query } }) => {
            if(element_types_query) {
                setElementTypes(element_types_query);
            }
        }).catch(err => console.log(err));
    }, []);

	return <Formik
		enableReinitialize
		initialValues={{
            library_party_id: params.library_party_id,
            module_resource_id: params.module_resource_id,
            lesson_resource_id: params.lesson_resource_id,
			element_count: elements.length,
			element_id: 0
		}}
		validate={values => {
			const errors = {};

			if (!values.element_type_id) {
				errors.element_type_id = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			create_owner_element(values).then(({ data: { new_element } }) => {
				if(new_element) {
					setSubmitting(false);
					resetForm();
					window.scrollTo(0,document.body.scrollHeight);
					handleClose();
				}
			}).catch(err => {
				setSubmitting(false);
				console.log(err);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
                <button 
                    type="button"
                    className="btn btn-secondary btn-block mx-auto w-100"
                    onClick={handleClickOpen}
                >
                    Import a Content (Soon)
                </button>

				<Dialog open={open && false} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
					<DialogTitle id="form-dialog-title">Create a new element</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Lesson: </span>{lesson.title}
						</DialogContentText>
						
                        <div className="row justify-content-center">
                            {elementTypes.map((indiv_element_type) => {
                                return <div key={indiv_element_type.id} className="col-6 col-lg-3 col-md-4">
                                    <button 
                                        type="button"
                                        className={`btn ${values.element_type_id === indiv_element_type.id && "border-success"}`}
                                        onClick={() => setFieldValue("element_type_id", indiv_element_type.id)}
                                    >
                                        <img 
                                            src={indiv_element_type.image_src}
                                            alt="Element Button"
                                            style={{ maxHeight: "10vh", diplay: "block" }}
                                        />
                                    </button>
                                    <Typography align="center">
                                        {indiv_element_type.name}
                                    </Typography>
                                </div>
                            })}
                        </div>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const ShowResponses = ({ params, element, attachment, owner }) => {
	const [attachmentResponses, setAttachmentResponses] = useState([]);
	const [init, setInit] = useState(false);
	const [responseTake, setResponseTake] = useState(0);
	const [responseSkip, setResponseSkip] = useState(0);
	const [responses, setResponses] = useState([]);

	useEffect(() => {
		getUserByToken().then(({ data: { user } }) => {
			if(user) {
				if(owner) {
					let is_mentor = "";
		
					if(owner == "mentor") {
						is_mentor = "mentor";
					}
		
					get_owner_attachment_responses(params.library_party_id, params.module_resource_id, params.lesson_resource_id, element.id, attachment.id, is_mentor).then(({ data: { attachment_responses_query } }) => {
						setAttachmentResponses(attachment_responses_query);
						setInit(true);
					}).catch(err => console.log(err))
				} else {
					get_student_attachment_responses(params.library_party_id, params.module_resource_id, params.lesson_resource_id, element.id, attachment.id).then(({ data: { attachment_responses_query } }) => {
						let new_attachment_response = attachment_responses_query.filter(x => x.user.party_id != user.party_id)
						setAttachmentResponses(new_attachment_response);
						setInit(true);
					}).catch(err => console.log(err))
				}
			}
		}).catch(err => console.log(err));
	}, [params.library_party_id, params.module_resource_id, params.lesson_resource_id, element.id, attachment.id, owner]);

	const dateFormatter = (date) => {
		let arr_date = (new Date(date)).toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric'}).split(" ");
		
		return arr_date[1] + " " + arr_date[0] + ", " + arr_date[2];
	}
	
	useEffect(() => {
		responseBatches();
	}, [attachmentResponses, responseTake])

	const responseBatches = () => {
		let oldArr = responses;
		let newArr = []

		if(responseTake == 0) {
			for(var j = 0; j < 3; j++) {
				if(attachmentResponses[j]) {
					newArr.push(attachmentResponses[j])
				}
			}

			setResponseSkip(3);
		}
		else {
			for(var j = responseSkip; j < responseTake + 3; j++) {
				if(attachmentResponses[j]) {
					newArr.push(attachmentResponses[j])
				}
			}

			setResponseSkip(responseTake + 3);
		}

		setResponses(oldArr.concat(newArr))
	}

	return <>
		<Typography className="mt-5" style={{marginBottom: "20px"}}>
			<b>See What Your Peers Said:</b>
		</Typography>

		<div style={{overflowY: "auto", maxHeight: "250px"}}>
			{attachmentResponses.length ? (
				<>
					{responses.map((indiv_response, response_index) => {
						return <div key={response_index}>
							<div className="d-flex">
								<Avatar 
									className="mr-2" 
									height={60} 
									alt="Avatar" 
									src={indiv_response.user.image_file ? `${process.env.REACT_APP_S3_BUCKET}/user_uploads/images/${indiv_response.user.image_file}` : "https://lernnex.s3.amazonaws.com/user_profile_images/default_user_image.png"} 
								/>
								<div>
									<div style={{display: "flex"}}>
										<Typography className="font-weight-bold">
											<Link to="#">{indiv_response.user.firstname}</Link>
										</Typography>

										<Typography variant="subtitle2" style={{marginTop: "1px", marginLeft: "7px"}}>
											<small>{dateFormatter(indiv_response.created_at)}</small>
										</Typography>
									</div>

									<Typography>
										{indiv_response.content}
									</Typography>
								</div>
							</div>

							<hr />
						</div>
					})}

					{(attachmentResponses.length > responseTake) && (
						<button 
							type="button" 
							className="btn btn-primary d-block mx-auto my-3"
							style={{backgroundColor: "#0269d4"}}
							onClick={() => setResponseTake(prevCount => { return prevCount + 10 })}
						>
							View Other Responses
						</button>
					)}
				</>
			) : !init ? <Spinner animation="grow" /> : <Typography align="center"> No responses yet... </Typography>}
		</div>

		{/* <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
			<DialogTitle id="form-dialog-title">Others' Response</DialogTitle>
			<DialogContent>
				{attachmentResponses.length ? attachmentResponses.map((indiv_response, response_index) => {
					return <div key={response_index}>
						<div className="d-flex">
							<Avatar 
								className="mr-2" 
								height={60} 
								alt="Avatar" 
								src={indiv_response.user.image_file ? `${process.env.REACT_APP_S3_BUCKET}/user_uploads/images/${indiv_response.user.image_file}` : "https://lernnex.s3.amazonaws.com/user_profile_images/default_user_image.png"} 
							/>
							<div>
								<Typography className="font-weight-bold">
									<Link to="#">{indiv_response.user.firstname}</Link>
								</Typography>
								<Typography variant="subtitle2">
									<small>{(new Date(indiv_response.created_at)).toLocaleString(undefined, { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit' })}</small>
								</Typography>
								<Typography className="font-weight-bolder">
									Response: 
								</Typography>

								<Typography>
									{indiv_response.content}
								</Typography>
							</div>
						</div>

						<hr />
					</div>
				}) : !init ? <Spinner animation="grow" /> : <Typography align="center"> No responses yet... </Typography>}
			</DialogContent>
			<DialogActions>
			<Button onClick={handleClose} className="text-danger">
				Close
			</Button>
			</DialogActions>
		</Dialog>
		<button 
			type="button" 
			className="btn btn-primary d-block mx-auto my-3"
			onClick={handleClickOpen}
		>
			View Other Responses
		</button> */}
	</>
}

export const SubmitLessonQuestion = injectIntl(({ lesson, intl, openLessonQuestion, setOpenLessonQuestion }) => {

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	function handleClose() {
		setOpenLessonQuestion(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			question: "",
			lesson_resource_id: lesson.resource_id
		}}
		validate={values => {
			const errors = {};

			if (!values.question) {
				errors.question = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			handleClose();
			resetForm();
			snackbarAlert("success", "Your question was submitted!");
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<Dialog open={openLessonQuestion} onClose={handleClose} aria-labelledby="form-dialog-question" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-question">Submit Lesson Question</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Lesson: </span>{lesson.question}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Title<span className="text-danger">*</span></span>}
                            name="question"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.question}
                            helperText={touched.question && errors.question}
                            error={Boolean(touched.question && errors.question)}
						/>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const DeleteOwnerElement = ({ params, element, elementIndex, setSelectedElement, elements, setElements }) => {
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	function handleClickOpen() {
		setOpen(true);
		setSelectedElement(elementIndex);
	}

	function handleClose() {
		setOpen(false);
		setSelectedElement(null);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			
		}}
		validate={values => {
			const errors = {};

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			await delete_owner_element(element.id, params).then(({ status }) => {
				if(status === 200) {
					let newElements = [...elements];
					newElements.splice(elementIndex, 1);
					setElements(newElements);
					snackbarAlert("warning", "Element Deleted!");
					handleClose();
				}
			}).catch(err => {
				console.log(err);
				snackbarAlert("error", "Something went wrong!");
			}).finally(() => setSubmitting(false));
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button 
					type="button"
					className="btn btn-danger my-auto"
					onClick={handleClickOpen}
				>
					Delete
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
					<DialogTitle id="form-dialog-title" className="text-center">Delete Element</DialogTitle>
					<DialogContent>
						<DialogContentText className="text-center">
							<span className="font-weight-bolder">Element {elementIndex + 1} ({(() => {
								switch(element.element_type_id) {
									case 1:
										return "Image";
									case 2:
										return "Audio";
									case 3:
										return "Document";
									case 4:
										return "Video";
									case 5:
										return "Text";
									case 6:
										return "Reflection";
									case 7:
										return "Signature";
									case 8:
										return "Survey";
									case 9:
										return "Link";
									case 10:
										return "Download";
									default:
										return null;
								}
							})()})</span>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
					<div>
						<Button onClick={handleClose} className="text-danger">
							Cancel
						</Button>
						<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
							Delete 
						</Button>
					</div>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
}

export const CreateMentorElement = injectIntl(({ elements, setElements, params, lesson, intl }) => {
	const [open, setOpen] = useState(false);
    const [elementTypes, setElementTypes] = useState([]);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

    useEffect(() => {
        get_element_types().then(({ data: { element_types_query } }) => {
            if(element_types_query) {
                setElementTypes(element_types_query);
            }
        }).catch(err => console.log(err));
    }, []);

	return <Formik
		enableReinitialize
		initialValues={{
			element_type_id: 0,
            library_party_id: params.library_party_id,
            module_resource_id: params.module_resource_id,
            lesson_resource_id: params.lesson_resource_id,
			elements_count: elements.length
		}}
		validate={values => {
			const errors = {};

			if (!values.element_type_id) {
				errors.element_type_id = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			create_mentor_element(values).then(({ data: { new_element } }) => {
				if(new_element) {
					let newElements = elements;
					newElements.push(new_element);
					setElements([]);
					setElements(newElements);
					setSubmitting(false);
					resetForm();
					window.scrollTo(0,document.body.scrollHeight);
					handleClose();
				}
			}).catch(err => {
				setSubmitting(false);
				console.log(err);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
                <button 
                    type="button"
                    className="btn btn-primary btn-block mx-auto w-100"
                    onClick={handleClickOpen}
                >
                    Add a Content
                </button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
					<DialogTitle id="form-dialog-title">Create a new element</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Lesson: </span>{lesson.title}
						</DialogContentText>
						
                        <div className="row justify-content-center">
                            {elementTypes.map((indiv_element_type) => {
                                return <div key={indiv_element_type.id} className="col-6 col-lg-3 col-md-4">
                                    <button 
                                        type="button"
                                        className={`btn ${values.element_type_id === indiv_element_type.id && "border-success"}`}
                                        onClick={() => setFieldValue("element_type_id", indiv_element_type.id)}
                                    >
                                        <img 
                                            src={indiv_element_type.image_src}
                                            alt="Element Button"
                                            style={{ maxHeight: "10vh", diplay: "block" }}
                                        />
                                    </button>
                                    <Typography align="center">
                                        {indiv_element_type.name}
                                    </Typography>
                                </div>
                            })}
                        </div>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})