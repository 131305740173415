import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";

import { LibrariesPage } from "./pages/Libraries"; //use this for direct loading
// const LibrariesPage = lazy(() =>
//   import("./modules/Libraries/pages/LibrariesPage")
// );

// import { DashboardPage } from "./modules/Dashboards"; //use this for direct loading
const DashboardPage = lazy(() =>
  import("./modules/Dashboards/pages/DashboardPage")
);

const TeamsPage = lazy(() =>
  import("./modules/Teams/pages/TeamsPage")
);

const PositionsPage = lazy(() =>
  import("./modules/Positions/pages/PositionsPage")
);

const CompaniesPage = lazy(() =>
  import("./modules/Companies/pages/CompaniesPage")
);

const CategoriesPage = lazy(() =>
  import("./modules/Categories/pages/CategoriesPage")
);


// Junk
const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);



export default function BasePage(props) {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          props.auth.user.roles[0].id == 4 ? <Redirect exact from="/" to="/companies" /> : <Redirect exact from="/" to="/libraries" />
        }

        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/teams" component={TeamsPage} />
        <ContentRoute path="/positions" component={PositionsPage} />
        <ContentRoute path="/companies" component={CompaniesPage} />
        <ContentRoute path="/categories" component={CategoriesPage} />
        <Route path="/libraries" component={LibrariesPage} />


        {/* Junk */}
        <ContentRoute path="/builder" component={BuilderPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
