import React, { useState } from 'react'
import {
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Avatar,
    LinearProgress,
    TextField,
    Select,
    MenuItem,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { 
    update_owner_student_library_access, 
    update_mentor_student_library_access,
    delete_owner_library_mentor
} from '../../../_redux/LibrariesCrud';
import { useSnackbar } from "notistack";

const update_owner_enrollee_access = async (enrollee, library, setEnrolleesList, snackbarAlert) => {
    try {
        const { status, data: { enrollees_list_query, access } } = await update_owner_student_library_access({ library_party_id: library.party_id, account_party_id: enrollee.account_party_id })
        if(status === 200) {
            setEnrolleesList(enrollees_list_query);
            snackbarAlert(access ? "success" : "warning", access ? "Access Granted" : "Access Removed");
        }
    } catch(err) {
        console.log(err);
        snackbarAlert("error", "Something went wrong");
    }
    
    return;
}

const update_mentor_enrollee_access = async (enrollee, library, setEnrolleesList, snackbarAlert) => {
    try {
        const { status, data: { enrollees_list_query, access } } = await update_mentor_student_library_access({ library_party_id: library.party_id, account_party_id: enrollee.account_party_id })
        if(status === 200) {
            setEnrolleesList(enrollees_list_query);
            snackbarAlert(access ? "success" : "warning", access ? "Access Granted" : "Access Removed");
        }
    } catch(err) {
        console.log(err);
        snackbarAlert("error", "Something went wrong");
    }
    
    return;
}

export const OwnerEnrolleesList = ({ enrolleesList, setEnrolleesList, library }) => {
    const [filterTable, setFilterTable] = useState("");

    const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

    return <>
        <div className="row">
            <div className="col-lg-3">
                <TextField
                    label="Filter Table"
                    color="secondary"
                    name="explanation_description"
                    placeholder="Filter by Firstname, Lastname or Email"
                    margin="normal"
                    onChange={e => setFilterTable(e.target.value)}
                    value={filterTable}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <div className="col-lg-2 d-flex align-items-end">
                <button
                    type="button"
                    className="btn btn-white border"
                >
                    Download List
                </button>
            </div>
        </div>
        
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Date Enrolled
                    </TableCell>
                    <TableCell>
                        Date Started
                    </TableCell>
                    <TableCell>
                        Date Completed
                    </TableCell>
                    <TableCell>
                        Days Overdue
                    </TableCell>
                    <TableCell>
                        Progress
                    </TableCell>
                    <TableCell>
                        Actions
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {enrolleesList.map((indiv_enrollee, enrollee_index) => {
                    if(indiv_enrollee.firstname.toLowerCase().match(filterTable) || indiv_enrollee.lastname.toLowerCase().match(filterTable) || indiv_enrollee.email.toLowerCase().match(filterTable)) {
                        return <TableRow key={enrollee_index}>
                            <TableCell>
                                <div className="d-flex align-items-center">
                                    <Avatar 
                                        className="mr-2" 
                                        height={60} 
                                        alt="Avatar" 
                                        src={indiv_enrollee ? indiv_enrollee.image_file : "https://lernnex.s3.amazonaws.com/user_profile_images/default_user_image.png"} 
                                    />
                                    <div>
                                        <Typography className="font-weight-bold">
                                            <Link to="#">{indiv_enrollee.lastname}, {indiv_enrollee.firstname}</Link>
                                        </Typography>
                                        <Typography variant="subtitle2" className="font-weight-bold">
                                            {indiv_enrollee.email}
                                        </Typography>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {Boolean(indiv_enrollee.enrolled_at) && (new Date(indiv_enrollee.enrolled_at)).toLocaleDateString("en-US")}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {Boolean(indiv_enrollee.started_at) && (new Date(indiv_enrollee.started_at)).toLocaleDateString("en-US")}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {Boolean(indiv_enrollee.completed_at) && (new Date(indiv_enrollee.completed_at)).toLocaleDateString("en-US")}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                {Boolean(indiv_enrollee.days_overdue) && <Typography className="text-danger">{indiv_enrollee.days_overdue}</Typography>}
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {parseFloat(indiv_enrollee.progress).toFixed(2)}%
                                </Typography>
                                <LinearProgress 
                                    variant="determinate" 
                                    value={indiv_enrollee.progress}
                                    color={indiv_enrollee.progress >= 100 ? "primary" : "secondary"}
                                />
                            </TableCell>
                            <TableCell>
                                <UpdateStudentLibraryAccessButton 
                                    enrollee={indiv_enrollee} 
                                    library={library}
                                    snackbarAlert={snackbarAlert}
                                    setEnrolleesList={setEnrolleesList}
                                />
                            </TableCell>
                        </TableRow>
                    } else {
                        return null;
                    }
                })}
            </TableBody>
        </Table>
    
    </>
}

export const CompaniesEnrolledList = ({ companiesEnrolled }) => {
    const [selectedCompany, setSelectedCompany] = useState(0);

    if(!companiesEnrolled.length) {
		return <Typography align="center">No companies yet...</Typography>
	}

    return <>
        <div className="d-flex align-items-center">
            <Typography className="mr-3">
                Current Company: 
            </Typography>
            <Select
                color="secondary"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                variant="outlined"
            >
                <MenuItem
                    value={"placeholder"}
                    disabled={true}
                >
                    Select a company
                </MenuItem>

                {companiesEnrolled.map((indiv_company, company_index) => {
                    return <MenuItem
                        key={company_index}
                        value={company_index}
                    >
                        {indiv_company.name}
                    </MenuItem>
                })}
            </Select>
        </div>
        
        <Table className="w-50">
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Email
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Boolean(companiesEnrolled.length && selectedCompany !== "placeholder") && companiesEnrolled[selectedCompany].students.map((indiv_enrollee, enrollee_index) => {
                    return <TableRow key={enrollee_index}>
                        <TableCell>
                            <div className="d-flex align-items-center">
                                <Avatar 
                                    className="mr-2" 
                                    height={60} 
                                    alt="Avatar" 
                                    src={indiv_enrollee ? indiv_enrollee.image_file : "https://lernnex.s3.amazonaws.com/user_profile_images/default_user_image.png"} 
                                />
                                <div>
                                    <Typography className="font-weight-bold">
                                        <Link to="#">{indiv_enrollee.lastname}, {indiv_enrollee.firstname}</Link>
                                    </Typography>
                                </div>
                            </div>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {indiv_enrollee.email}
                            </Typography>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

export const TeamsEnrolledList = ({ teamsEnrolled }) => {
    const [selectedTeam, setSelectedTeam] = useState(0);

    if(!teamsEnrolled.length) {
		return <Typography align="center">No teams yet...</Typography>
	}

    return <>
        <div className="d-flex align-items-center">
            <Typography className="mr-3">
                Current team: 
            </Typography>
            <Select
                color="secondary"
                value={selectedTeam}
                onChange={(e) => setSelectedTeam(e.target.value)}
                variant="outlined"
            >
                <MenuItem
                    value={"placeholder"}
                    disabled={true}
                >
                    Select a team
                </MenuItem>

                {teamsEnrolled.map((indiv_team, team_index) => {
                    return <MenuItem
                        key={team_index}
                        value={team_index}
                    >
                        {indiv_team.name}
                    </MenuItem>
                })}
            </Select>
        </div>
        
        <Table className="w-50">
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Email
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Boolean(teamsEnrolled.length && selectedTeam !== "placeholder") && teamsEnrolled[selectedTeam].students.map((indiv_enrollee, enrollee_index) => {
                    return <TableRow key={enrollee_index}>
                        <TableCell>
                            <div className="d-flex align-items-center">
                                <Avatar 
                                    className="mr-2" 
                                    height={60} 
                                    alt="Avatar" 
                                    src={indiv_enrollee ? indiv_enrollee.image_file : "https://lernnex.s3.amazonaws.com/user_profile_images/default_user_image.png"} 
                                />
                                <div>
                                    <Typography className="font-weight-bold">
                                        <Link to="#">{indiv_enrollee.lastname}, {indiv_enrollee.firstname}</Link>
                                    </Typography>
                                </div>
                            </div>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {indiv_enrollee.email}
                            </Typography>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

export const PositionsEnrolledList = ({ positionsEnrolled }) => {
    const [selectedPosition, setSelectedPosition] = useState(0);

    if(!positionsEnrolled.length) {
		return <Typography align="center">No positions yet...</Typography>
	}

    return <>
        <div className="d-flex align-items-center">
            <Typography className="mr-3">
                Current position: 
            </Typography>
            <Select
                color="secondary"
                value={selectedPosition}
                onChange={(e) => setSelectedPosition(e.target.value)}
                variant="outlined"
            >
                <MenuItem
                    value={"placeholder"}
                    disabled={true}
                >
                    Select a position
                </MenuItem>

                {positionsEnrolled.map((indiv_position, position_index) => {
                    return <MenuItem
                        key={position_index}
                        value={position_index}
                    >
                        {indiv_position.name}
                    </MenuItem>
                })}
            </Select>
        </div>
        
        <Table className="w-50">
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Email
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Boolean(positionsEnrolled.length && selectedPosition !== "placeholder") && positionsEnrolled[selectedPosition].students.map((indiv_enrollee, enrollee_index) => {
                    return <TableRow key={enrollee_index}>
                        <TableCell>
                            <div className="d-flex align-items-center">
                                <Avatar 
                                    className="mr-2" 
                                    height={60} 
                                    alt="Avatar" 
                                    src={indiv_enrollee ? indiv_enrollee.image_file : "https://lernnex.s3.amazonaws.com/user_profile_images/default_user_image.png"} 
                                />
                                <div>
                                    <Typography className="font-weight-bold">
                                        <Link to="#">{indiv_enrollee.lastname}, {indiv_enrollee.firstname}</Link>
                                    </Typography>
                                </div>
                            </div>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {indiv_enrollee.email}
                            </Typography>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

export const UpdateStudentLibraryAccessButton = ({ enrollee, library, setEnrolleesList, snackbarAlert, role_id }) => {
    const [disableButton, setDisableButton] = useState(false);

    return <button
        disabled={disableButton}
        type="button"
        className={`btn ${enrollee.allowed_access ? "btn-danger" : "btn-success"}`}
        onClick={async () => {
            setDisableButton(true);
            if(role_id === 3) {
                await update_mentor_enrollee_access(enrollee, library, setEnrolleesList, snackbarAlert);
            } else {
                await update_owner_enrollee_access(enrollee, library, setEnrolleesList, snackbarAlert);
            }
            setDisableButton(false);
        }}
    >
        {enrollee.allowed_access ? "Block Access" : "Allow Access"}
    </button>
}

export const MentorsList = ({ mentorsList, setMentorsList, library }) => {

    const [disableRemove, setDisableRemove] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

    if(!mentorsList) {
		return <Typography align="center">No mentors yet...</Typography>
	}

    return <>
        <Table className="w-50">
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Email
                    </TableCell>
                    <TableCell>
                        Actions
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {mentorsList.map((indiv_mentor, mentor_index) => {
                    return <TableRow key={mentor_index}>
                        <TableCell>
                            {`${indiv_mentor.firstname} ${indiv_mentor.lastname}`}
                        </TableCell>
                        <TableCell>
                            {indiv_mentor.email}
                        </TableCell>
                        <TableCell>
                            <button 
                                disabled={disableRemove}
                                className="btn btn-danger"
                                type="button"
                                onClick={async () => {
                                    setDisableRemove(true);
                                    await delete_owner_library_mentor(library.party_id, { person_party_id: indiv_mentor.party_id }).then(({ data: { mentors_query } }) => {
                                        if(mentors_query) {
                                            setMentorsList(mentors_query);
                                            snackbarAlert("warning", "Mentor Removed");
                                        }
                                        setDisableRemove(false);
                                    }).catch(err => {
                                        console.log(err);
                                        setDisableRemove(false);
                                        snackbarAlert("error", err.response.data.message);
                                    });
                                }}
                            >
                                Remove
                            </button>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

export const MentorEnrolleesList = ({ enrolleesList, setEnrolleesList, library }) => {
    const [filterTable, setFilterTable] = useState("");

    const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

    return <>
        <div className="row">
            <div className="col-lg-3">
                <TextField
                    label="Filter Table"
                    color="secondary"
                    name="explanation_description"
                    placeholder="Filter by Firstname, Lastname or Email"
                    margin="normal"
                    onChange={e => setFilterTable(e.target.value)}
                    value={filterTable}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <div className="col-lg-2 d-flex align-items-end">
                <button
                    type="button"
                    className="btn btn-white border"
                >
                    Download List
                </button>
            </div>
        </div>
        
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Date Enrolled
                    </TableCell>
                    <TableCell>
                        Date Started
                    </TableCell>
                    <TableCell>
                        Date Completed
                    </TableCell>
                    <TableCell>
                        Days Overdue
                    </TableCell>
                    <TableCell>
                        Progress
                    </TableCell>
                    <TableCell>
                        Actions
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {enrolleesList.map((indiv_enrollee, enrollee_index) => {
                    if(indiv_enrollee.firstname.toLowerCase().match(filterTable) || indiv_enrollee.lastname.toLowerCase().match(filterTable) || indiv_enrollee.email.toLowerCase().match(filterTable)) {
                        return <TableRow key={enrollee_index}>
                            <TableCell>
                                <div className="d-flex align-items-center">
                                    <Avatar 
                                        className="mr-2" 
                                        height={60} 
                                        alt="Avatar" 
                                        src={indiv_enrollee ? indiv_enrollee.image_file : "https://lernnex.s3.amazonaws.com/user_profile_images/default_user_image.png"} 
                                    />
                                    <div>
                                        <Typography className="font-weight-bold">
                                            <Link to="#">{indiv_enrollee.lastname}, {indiv_enrollee.firstname}</Link>
                                        </Typography>
                                        <Typography variant="subtitle2" className="font-weight-bold">
                                            {indiv_enrollee.email}
                                        </Typography>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {Boolean(indiv_enrollee.enrolled_at) && (new Date(indiv_enrollee.enrolled_at)).toLocaleDateString("en-US")}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {Boolean(indiv_enrollee.started_at) && (new Date(indiv_enrollee.started_at)).toLocaleDateString("en-US")}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {Boolean(indiv_enrollee.completed_at) && (new Date(indiv_enrollee.completed_at)).toLocaleDateString("en-US")}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                {Boolean(indiv_enrollee.days_overdue) && <Typography className="text-danger">{indiv_enrollee.days_overdue}</Typography>}
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {parseFloat(indiv_enrollee.progress).toFixed(2)}%
                                </Typography>
                                <LinearProgress 
                                    variant="determinate" 
                                    value={indiv_enrollee.progress}
                                    color={indiv_enrollee.progress >= 100 ? "primary" : "secondary"}
                                />
                            </TableCell>
                            <TableCell>
                                <UpdateStudentLibraryAccessButton 
                                    enrollee={indiv_enrollee} 
                                    library={library}
                                    snackbarAlert={snackbarAlert}
                                    setEnrolleesList={setEnrolleesList}
                                    role_id={3}
                                />
                            </TableCell>
                        </TableRow>
                    } else {
                        return null;
                    }
                })}
            </TableBody>
        </Table>
    
    </>
}