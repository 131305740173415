import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as Yup from "yup";
import { injectIntl } from 'react-intl';
import { Spinner } from 'react-bootstrap';
import { 
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
	TextField,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Select,
    MenuItem
 } from '@material-ui/core';
import { sign_up_as_student_to_company, import_as_student_to_company, sign_up_as_mentor_to_library_company, sign_up_as_student_to_library_company } from '../../../_redux/CompaniesCrud';
import { emailVerification, register } from '../../../../Auth/_redux/authCrud';
import { get_owner_teams } from '../../../../Teams/pages/_redux/TeamsCrud';
import { get_owner_positions } from '../../../../Positions/pages/_redux/PositionsCrud';
import { useSnackbar } from "notistack";

export const AddCompanyUserModal = injectIntl(({ params, company, students, setStudents, studentsList, setStudentsList, intl }) => {
    const { enqueueSnackbar } = useSnackbar();
    const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

    const [canSubmit, setCanSubmit] = useState(false);
    const [open, setOpen] = useState(false);
    const [importStudent, setImportStudent] = useState(false);
    const [teams, setTeams] = useState([]);
    const [positions, setPositions] = useState([]);

    useEffect(() => {
        get_owner_teams(company.party_id).then(({ data: { teams } }) => {
			setTeams(teams);
		})
		.catch(err => {
			console.log(err)
		})

        get_owner_positions(company.party_id).then(({ data: { positions } }) => {
			setPositions(positions);
		})
		.catch(err => {
			console.log(err)
		})
    }, [company.party_id])

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}
    
    return <>
        <button
            type="button"
            className="btn btn-primary mr-5"
            onClick={handleClickOpen}
        >
            Add User
        </button>
        
        {/* <button
            type="button"
            className="btn btn-primary"
            onClick={() => setImportStudent(true)}
        >
            Import Students
        </button> */}
        
        <Formik
            enableReinitialize
            initialValues={{
                company_party_id: company.party_id,
                email: "",
                username: "",
                firstname: "",
                lastname: "",
                user_role: "student",
                team_party_id: 0,
                position_party_id: 0,
                existingUser: false
            }}
            validationSchema={Yup.object().shape({
                existingUser: Yup.boolean(),
                // team_party_id: Yup.number()
                //     .when("existingUser", {
                //         is: false,
                //         then: Yup.number()
                //             .min(1, "Please Choose Team")
                //             .required(
                //                 intl.formatMessage({
                //                     id: "AUTH.VALIDATION.REQUIRED_FIELD",
                //                 })
                //             )
                //     }),
                // position_party_id: Yup.number()
                //     .when("existingUser", {
                //         is: false,
                //         then: Yup.number()
                //             .min(1, "Please Choose Position")
                //             .required(
                //                 intl.formatMessage({
                //                     id: "AUTH.VALIDATION.REQUIRED_FIELD",
                //                 })
                //             )
                //     }),
                email: Yup.string()
                    .email("Wrong email format")
                    .min(3, "Minimum 3 charaters")
                    .max(50, "Maximum 50 charaters")
                    .required(
                        intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD",
                        })
                    ),
                // username: Yup.string()
                //     .when("existingUser", {
                //         is: false,
                //         then: Yup.string()
                //             .min(3, "Minimum 3 charaters")
                //             .max(50, "Maximum 50 charaters")
                //             .required(
                //                 intl.formatMessage({
                //                     id: "AUTH.VALIDATION.REQUIRED_FIELD",
                //                 })
                //             )
                //     }),
                firstname: Yup.string()
                    .when("existingUser", {
                        is: false,
                        then: Yup.string()
                            .min(3, "Minimum 3 charaters")
                            .max(50, "Maximum 50 charaters")
                            .required(
                                intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                                })
                            )
                    }),
                lastname: Yup.string()
                    .when("existingUser", {
                        is: false,
                        then: Yup.string()
                            .min(3, "Minimum 3 charaters")
                            .max(50, "Maximum 50 charaters")
                            .required(
                                intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                                })
                            )
                    })
            })}
            onSubmit={(values, { resetForm, setSubmitting }) => {
                if(values.user_role == "admin") {
                    register(values).then((response) => {
                        if(response.data.new_person) {
                            let new_students = [...students]
                            new_students.push(response.data.new_person);
                            setStudents([]);
                            setStudents(new_students);
                            resetForm();
                            handleClose();
                            setSubmitting(false);
                            snackbarAlert("success", "User Created Successfully");
                        }
                        else if(response.data.message) {
                            resetForm();
                            handleClose();
                            setSubmitting(false);
                            snackbarAlert("success", "User Created Successfully");
                        }
                    }).catch(err => {
                        console.log(err);
                        snackbarAlert("error", "Something Went Wrong");
                        setSubmitting(false);
                    })
                }
                else {
                    sign_up_as_student_to_company(values).then((response) => {
                        if(response.data.new_person) {
                            let new_students = [...students]
                            new_students.push(response.data.new_person);
                            setStudents([]);
                            setStudents(new_students);
                            resetForm();
                            handleClose();
                            setSubmitting(false);
                            snackbarAlert("success", "User Created Successfully");
                        }
                        else if(response.data.message) {
                            setSubmitting(false);
                            snackbarAlert("error", response.data.message);
                        }
                    }).catch(err => {
                        console.log(err);
                        snackbarAlert("error", "Something Went Wrong");
                        setSubmitting(false);
                    })
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                handleReset,
            }) => (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                >
                    {console.log(errors)}
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
                        <DialogTitle id="form-dialog-title">Add a user</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <span className="font-weight-bold">Target Company: </span>{company.name}
                            </DialogContentText>

                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    color="secondary"
                                    label={<span>Firstname<span className="text-danger">*</span></span>}
                                    name="firstname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Firstname"
                                    disabled={canSubmit}
                                    hidden={values.existingUser}
                                    value={values.firstname}
                                    helperText={touched.firstname && errors.firstname}
                                    error={Boolean(touched.firstname && errors.firstname)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    color="secondary"
                                    label={<span>Lastname<span className="text-danger">*</span></span>}
                                    name="lastname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Lastname"
                                    value={values.lastname}
                                    disabled={canSubmit}
                                    hidden={values.existingUser}
                                    helperText={touched.lastname && errors.lastname}
                                    error={Boolean(touched.lastname && errors.lastname)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    autoFocus
                                    margin="normal"
                                    color="secondary"
                                    label={<span>Email<span className="text-danger">*</span></span>}
                                    name="email"
                                    onBlur={(e) => {
                                        setCanSubmit(true)
                                        emailVerification(e.target.value).then((response) => {
                                            if(response.data.is_existing) {
                                                setFieldValue("existingUser", true)
                                                snackbarAlert("success", "User exists");
                                            }
                                            else {
                                                setFieldValue("existingUser", false)
                                            }
                                            setCanSubmit(false)
                                        })
                                    }}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    placeholder="Email Address"
                                    value={values.email}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(touched.email && errors.email)}
                                />
                            </div>

                            {Boolean(company.allow_teams) &&
                                <div className="form-group">
                                    <Typography>
                                        Team
                                    </Typography>
                                    <Select
                                        className="w-100"
                                        value={values.team_party_id}
                                        disabled={canSubmit}
                                        onChange={(e) => setFieldValue("team_party_id", e.target.value)}
                                        error={Boolean(touched.team_party_id && errors.team_party_id)}
                                    >
                                        {
                                            teams.map((indiv_team, team_index) => {
                                                return (
                                                    <MenuItem key={team_index} value={indiv_team.party_id}>{indiv_team.name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                    <small className="text-danger">
                                        {touched.team_party_id && errors.team_party_id}
                                    </small>
                                </div>
                            }

                            {Boolean(company.allow_positions) &&
                                <div className="form-group">
                                    <Typography>
                                        Position
                                    </Typography>
                                    <Select
                                        className="w-100"
                                        value={values.position_party_id}
                                        disabled={canSubmit}
                                        onChange={(e) => setFieldValue("position_party_id", e.target.value)}
                                        error={Boolean(touched.position_party_id && errors.position_party_id)}
                                    >
                                        {
                                            positions.map((indiv_position, position_index) => {
                                                return (
                                                    <MenuItem key={position_index} value={indiv_position.party_id}>{indiv_position.name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                    <small className="text-danger">
                                        {touched.position_party_id && errors.position_party_id}
                                    </small>
                                </div>
                            }
                            
                            <div className="form-group">
                                <Typography>
                                    User Access <span className="text-danger">*</span>
                                </Typography>
                                <Select
                                    className="w-100"
                                    value={values.user_role}
                                    disabled={canSubmit}
                                    onChange={(e) => setFieldValue("user_role", e.target.value)}
                                >
                                    <MenuItem value={"student"}>Student</MenuItem>
                                    <MenuItem value={"admin"}>Admin</MenuItem>
                                </Select>
                            </div>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} className="text-danger">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
                            Submit
                        </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
        
        <Formik
            enableReinitialize
            initialValues={{
                company_party_id: company.party_id,
                upload_students: null
            }}
            validate = {values => {
                const errors = {};

                if(!values.upload_students) {
                    errors.value = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                }

                return errors;
            }}
            onSubmit={(values, { resetForm, setSubmitting }) => {
                import_as_student_to_company(values).then(({ data: { new_people } }) => {
                    if(new_people) {
                        setStudentsList(new_people);
                        resetForm();
                        setSubmitting(false)
                    }
                }).catch(err => {
                    console.log(err);
                    setSubmitting(false);
                })
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                handleReset
            }) => (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                >
                    <Dialog open={importStudent} onClose={() => setImportStudent(false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
                        <DialogTitle id="form-dialog-title">Import students</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <span className="font-weight-bold">Target Company: </span>{company.name}
                            </DialogContentText>

                            <div className="d-flex">
                                <a
                                    href="https://lernnex-stgv2.s3.amazonaws.com/template/company_student_template.xlsx"
                                    download="company_student_template.xlsx"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img 
                                        width="50"
                                        src="https://cdn2.iconfinder.com/data/icons/office-extras/512/Spreadsheet_Document-512.png" 
                                        alt="Upload Template" 
                                        className="d-block" 
                                    />
                                </a>

                                <Typography	variant="body1">
                                    Upload Template:
                                    <br />
                                    (Download template and input the info needed)
                                    <br />
                                    {
                                        (() => {
                                            if(values.upload_students) {
                                                return (
                                                    <a
                                                        href={window.URL.createObjectURL(
                                                            values.upload_students
                                                        )}
                                                        download={
                                                            values.upload_students.name
                                                        }
                                                        className="btn p-0"
                                                    >
                                                        <span>{values.upload_students.name} - Check!</span>
                                                    </a>
                                                );
                                            }
                                            else if(touched.upload_students || errors.upload_students) {
                                                return <span className="text-danger">[Upload File Required]</span>
                                            }
                                            else {
                                                return "[Upload File]"
                                            }
                                        })()
                                    }
                                </Typography>
                            </div>
                            
                            {(studentsList.length > 0) && (
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Username</TableCell>
                                                <TableCell>Firstname</TableCell>
                                                <TableCell>Lastname</TableCell>
                                                <TableCell>Remarks</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {studentsList.map((indiv_student, student_index) => {
                                                return (
                                                    <TableRow key={student_index}>
                                                        <TableCell>{indiv_student.email}</TableCell>
                                                        <TableCell>{indiv_student.username}</TableCell>
                                                        <TableCell>{indiv_student.firstname}</TableCell>
                                                        <TableCell>{indiv_student.lastname}</TableCell>
                                                        <TableCell>{indiv_student.message}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                            )}
                            
                        </DialogContent>

                        <DialogActions>
                            <button
                                disabled={isSubmitting}
                                type="button"
                                className="btn text-danger"
                            >
                                Close
                            </button>

                            <Button
                                disabled={isSubmitting}
                                variant="text"
                                className="btn btn-primary"
                                component="label"
                                size="large"
                            >
                                Upload

                                <input
                                    disabled={isSubmitting}
                                    type="file"
                                    className="d-none"
                                    accept=".xlsx, .xls"
                                    onChange={tempDocumentBLOB => {
                                        if(tempDocumentBLOB.currentTarget.files[0]) {
                                            setFieldValue("upload_students", tempDocumentBLOB.currentTarget.files[0]);
                                        }
                                    }}
                                />
                            </Button>

                            <Button
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                                variant="text"
                                component="label"
                                size="large"
                                className="btn btn-success"
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                    
                </form>
            )}
        </Formik>
    </>
})

export const AddMentorModal = injectIntl(({createMentorDialog, setCreateMentorDialog, library_party_id, intl}) => {
    const { enqueueSnackbar } = useSnackbar();
    const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

    function handleClose() {
        setCreateMentorDialog(false);
    }

    return <>
        <Formik
            enableReinitialize
            initialValues={{
                email: "",
                username: "",
                firstname: "",
                lastname: "",
                library_party_id: library_party_id
            }}
            validationSchema={Yup.object().shape({
                existingUser: Yup.boolean(),
                email: Yup.string()
                    .email("Wrong email format")
                    .min(3, "Minimum 3 charaters")
                    .max(50, "Maximum 50 charaters")
                    .required(
                        intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                        })
                    ),
                username: Yup.string()
                    .min(3, "Minimum 3 charaters")
                    .max(50, "Maximum 50 charaters")
                    .required(
                        intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                        })
                    ),
                firstname: Yup.string()
                    .min(3, "Minimum 3 charaters")
                    .max(50, "Maximum 50 charaters")
                    .required(
                        intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                        })
                    ),
                lastname: Yup.string()
                    .min(3, "Minimum 3 charaters")
                    .max(50, "Maximum 50 charaters")
                    .required(
                        intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                        })
                    )
            })}
            onSubmit={(values, { resetForm, setSubmitting }) => {
                sign_up_as_mentor_to_library_company(values).then((response) => {
                    if(response.data.new_person) {
                        resetForm();
                        handleClose();
                        setSubmitting(false);
                        snackbarAlert("success", "User Created Successfully");
                    }
                    else if(response.data.message) {
                        resetForm();
                        handleClose();
                        setSubmitting(false);
                        snackbarAlert("success", "User Created Successfully");
                    }
                }).catch(err => {
                    console.log(err);
                    snackbarAlert("error", "Something Went Wrong");
                    setSubmitting(false);
                })
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                handleReset,
            }) => (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                >
                    <Dialog open={createMentorDialog} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
                        <DialogTitle id="form-dialog-title">Add a user</DialogTitle>
                        <DialogContent>
                            <div className="form-group">
                                <TextField
                                    autoFocus
                                    margin="normal"
                                    color="secondary"
                                    label={<span>Email<span className="text-danger">*</span></span>}
                                    name="email"
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    placeholder="Email Address"
                                    value={values.email}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(touched.email && errors.email)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    color="secondary"
                                    label={<span>Username<span className="text-danger">*</span></span>}
                                    name="username"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Username"
                                    disabled={isSubmitting}
                                    value={values.username}
                                    helperText={touched.username && errors.username}
                                    error={Boolean(touched.username && errors.username)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    color="secondary"
                                    label={<span>Firstname<span className="text-danger">*</span></span>}
                                    name="firstname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Firstname"
                                    disabled={isSubmitting}
                                    value={values.firstname}
                                    helperText={touched.firstname && errors.firstname}
                                    error={Boolean(touched.firstname && errors.firstname)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    color="secondary"
                                    label={<span>Lastname<span className="text-danger">*</span></span>}
                                    name="lastname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Lastname"
                                    value={values.lastname}
                                    disabled={isSubmitting}
                                    helperText={touched.lastname && errors.lastname}
                                    error={Boolean(touched.lastname && errors.lastname)}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} className="text-danger">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
                            Submit
                        </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    </>
})

export const AddStudentModal = injectIntl(({createStudentDialog, setCreateStudentDialog, library_party_id, intl}) => {
    const { enqueueSnackbar } = useSnackbar();
    const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

    function handleClose() {
        setCreateStudentDialog(false);
    }

    return <>
        <Formik
            enableReinitialize
            initialValues={{
                email: "",
                username: "",
                firstname: "",
                lastname: "",
                library_party_id: library_party_id
            }}
            validationSchema={Yup.object().shape({
                existingUser: Yup.boolean(),
                email: Yup.string()
                    .email("Wrong email format")
                    .min(3, "Minimum 3 charaters")
                    .max(50, "Maximum 50 charaters")
                    .required(
                        intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                        })
                    ),
                username: Yup.string()
                    .min(3, "Minimum 3 charaters")
                    .max(50, "Maximum 50 charaters")
                    .required(
                        intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                        })
                    ),
                firstname: Yup.string()
                    .min(3, "Minimum 3 charaters")
                    .max(50, "Maximum 50 charaters")
                    .required(
                        intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                        })
                    ),
                lastname: Yup.string()
                    .min(3, "Minimum 3 charaters")
                    .max(50, "Maximum 50 charaters")
                    .required(
                        intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD",
                        })
                    )
            })}
            onSubmit={(values, { resetForm, setSubmitting }) => {
                sign_up_as_student_to_library_company(values).then((response) => {
                    if(response.data.new_person) {
                        resetForm();
                        handleClose();
                        setSubmitting(false);
                        snackbarAlert("success", "User Created Successfully");
                    }
                    else if(response.data.message) {
                        resetForm();
                        handleClose();
                        setSubmitting(false);
                        snackbarAlert("success", "User Created Successfully");
                    }
                }).catch(err => {
                    console.log(err);
                    snackbarAlert("error", "Something Went Wrong");
                    setSubmitting(false);
                })
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                handleReset,
            }) => (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                >
                    <Dialog open={createStudentDialog} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
                        <DialogTitle id="form-dialog-title">Add a user</DialogTitle>
                        <DialogContent>
                            <div className="form-group">
                                <TextField
                                    autoFocus
                                    margin="normal"
                                    color="secondary"
                                    label={<span>Email<span className="text-danger">*</span></span>}
                                    name="email"
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    placeholder="Email Address"
                                    value={values.email}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(touched.email && errors.email)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    color="secondary"
                                    label={<span>Username<span className="text-danger">*</span></span>}
                                    name="username"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Username"
                                    disabled={isSubmitting}
                                    value={values.username}
                                    helperText={touched.username && errors.username}
                                    error={Boolean(touched.username && errors.username)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    color="secondary"
                                    label={<span>Firstname<span className="text-danger">*</span></span>}
                                    name="firstname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Firstname"
                                    disabled={isSubmitting}
                                    value={values.firstname}
                                    helperText={touched.firstname && errors.firstname}
                                    error={Boolean(touched.firstname && errors.firstname)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    color="secondary"
                                    label={<span>Lastname<span className="text-danger">*</span></span>}
                                    name="lastname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Lastname"
                                    value={values.lastname}
                                    disabled={isSubmitting}
                                    helperText={touched.lastname && errors.lastname}
                                    error={Boolean(touched.lastname && errors.lastname)}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} className="text-danger">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
                            Submit
                        </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    </>
})
