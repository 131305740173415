import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function create_owner_category(values) {
    return await axios.post(API_URL + "/category/create-owner-category", values);
}

export async function update_owner_category(values) {
    return await axios.post(API_URL + "/category/update-owner-category", values);
}

export async function get_owner_categories_list(values) {
    return await axios.get(API_URL + "/category/get-owner-categories-list");
}