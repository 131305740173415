import React from "react";
import Button from 'react-bootstrap/Button';

import { create_chat_completion } from '../pages/Libraries/_redux/LibrariesCrud';

const OpenAIButton = ({ ...props }) => {
	return (
		<Button
			className="mx-auto d-block mt-3"
			disabled={props.isSubmitting}
			onClick={() => {
				if(props.isOverrideButton) {
					props.function();
				}
				else {
					props.setSubmitting(true);

					let prompt = props.values.ai_prompt;
					
					if(!props?.standalone) {
						prompt = prompt.replace("{{industry}}", props.values.ai_prompt_industry);
						prompt = prompt.replace("{{team}}", props.values.ai_prompt_team);
						prompt = prompt.replace("{{position}}", props.values.ai_prompt_position);
						prompt = prompt.replace("{{product}}", props.values.ai_prompt_product);
						prompt = prompt.replace("{{product_description}}", props.values.ai_prompt_product_description);
					}
					else {
						prompt = prompt.replace("{{industry}}", props.tempData.industry);
						prompt = prompt.replace("{{team}}", props.tempData.team);
						prompt = prompt.replace("{{position}}", props.tempData.position);
						prompt = prompt.replace("{{product}}", props.tempData.product);
						prompt = prompt.replace("{{product_description}}", props.tempData.product_description);
					}

					let params = {
						string: props.values.text_content,
						prompt: prompt,
						settings: { ai_use_default_text: props.values.ai_use_default_text },
						email: props.tempData.email,
						firstname: props.tempData.firstname,
						lastname: props.tempData.lastname,
					}

					create_chat_completion(params).then((response) => {
						if(props.isTest) {
							props.setFieldValue('test_content', response.data.data[0].content);
						}
						else {
							props.setVal(response.data.data[0].content);
						}
	
						props.setSubmitting(false);
					});
				}
			}}
		>
			{ props.buttonName }
		</Button>
	)
}

export default OpenAIButton;