import React, { useState, useEffect } from 'react'
import {
    Typography,
    Tabs, 
	Tab,
} from "@material-ui/core";
import { Link, Redirect } from 'react-router-dom';
import { useSnackbar } from "notistack";

import { useSubheader } from '../../../../../_metronic/layout';

import { get_student_library_details, get_student_library_content } from '../../_redux/LibrariesCrud';
import { Spinner } from 'react-bootstrap';

export function StudentLibraryDetails({ params }) {

	const [recentLibraryTab, setRecentLibraryTab] = useState("Users");
    const [library, setLibrary] = useState({});
    const [libraryContent, setLibraryContent] = useState([]);
	const [init, setInit] = useState(false);
	const [accessDenied, setAccessDenied] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

    useEffect(() => {
        get_student_library_details(params.library_party_id).then(({ data: { library_query } }) => {
            if(library_query) {
                setLibrary(library_query);
            }
            setInit(true);
        }).catch(err => {
            console.log(err);
            setAccessDenied(true);
        })

        get_student_library_content(params.library_party_id).then(({ data: { library_content_query, access_granted } }) => {
            if(library_content_query) {
                setLibraryContent(library_content_query);
            }
            setInit(true);
        }).catch(err => console.log(err))
    }, [params.library_party_id]);
    

    const suhbeader = useSubheader();
	suhbeader.setTitle(library.title || "Library");


    if(accessDenied) {
        snackbarAlert("error", "Access Denied");
        return <Redirect to="/" />
	}

	if(!init || !library.title) {
		return <Spinner animation="grow" />
	}

    return <>
        <div className="row" style={{marginTop: "-40px"}}>
            <div className="col-12">
                <div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
                    <div className="card-header">
                        <div className="card-toolbar">
                            {Boolean(library.progress_report && library.progress_report.started_at) && <span><span className="font-weight-bolder">Progress: </span> {library.progress_report.progress.toFixed(2)}%</span>}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="d-flex justify-content-center">
                            <Tabs
                                className="mb-3"
                                value={recentLibraryTab}
                                onChange={(e, newValue) => {
                                    setRecentLibraryTab(newValue);
                                }}
                                indicatorColor="secondary"
                                textColor="secondary"
                                variant="scrollable"
                                // variant="fullWidth"
                            >
                                <Tab value="Overview" label="Overview" />
                                <Tab value="Training Details" label="Training Details" />
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {(() => {
            switch(recentLibraryTab) {
                case "Overview": 
                    return <div className="row">
                        <div className="col-12">
                            <div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
                                <div className="card-header">
                                    <h3 className="card-title font-weight-bolder">Overview</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3">
                                            <img 
                                                src={library.image_file ? `${process.env.REACT_APP_S3_BUCKET}/library_uploads/images/${library.image_file}` : "https://lernnex.s3.amazonaws.com/library_uploads/images/default_library_image.jpg"}
                                                alt={library.title}
                                                className="w-100 rounded"
                                            />
                                        </div>
                                        <div className="col-lg-9 col-md-3">
                                            {
                                                Boolean(library.description) && (
                                                    <div>
                                                        <Typography variant="h6">
                                                            Description: 
                                                        </Typography>

                                                        <Typography variant="body1" style={{whiteSpace : "pre-line"}}>
                                                            {library.description}
                                                        </Typography>
                                                        <br />
                                                    </div>
                                                )
                                            }

                                            {
                                                Boolean(library.context && library.context.what_will_you_learn) && (
                                                    <div>
                                                        <Typography variant="h6">
                                                            What will you learn?: 
                                                        </Typography>

                                                        <Typography variant="body1" style={{whiteSpace : "pre-line"}}>
                                                            {library.context.what_will_you_learn}
                                                        </Typography>
                                                        <br />
                                                    </div>
                                                )
                                            }

                                            {
                                                Boolean(library.context && library.context.who_should_take_this) && (
                                                    <div>
                                                        <Typography variant="h6">
                                                            Who should take this?: 
                                                        </Typography>

                                                        <Typography variant="body1" style={{whiteSpace : "pre-line"}}>
                                                            {library.context.who_should_take_this}
                                                        </Typography>
                                                        <br />
                                                    </div>
                                                )
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                case "Training Details": 
                    return <div className="row">
                        <div className="col-12">
                            <div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
                                <div className="card-header">
                                    <h3 className="card-title font-weight-bolder">{library.settings.group_content ? "Modules and Lessons" : "Library Lessons"}</h3>
                                </div>

                                <div className="card-body">
                                    {(() => {
                                        // if(library.access_denied) {
                                        //     return <Typography variant="h4" align="center">
                                        //         Please contact your admin to access this library.
                                        //     </Typography>
                                        // } else {
                                            return libraryContent.length ? libraryContent.map((indiv_module, module_index) => {
                                                if(library.settings.group_content) {
                                                    return (
                                                        <div key={module_index} className="form-group">
                                                            <div className="p-3 bg-secondary d-flex rounded mb-2 align-items-center">
                                                                <Typography className="font-weight-bolder">
                                                                    {indiv_module.title}
                                                                </Typography>
                                                            </div>
                                                            {indiv_module.lessons && indiv_module.lessons.length ? indiv_module.lessons.map((indiv_lesson, lesson_index) => {
                                                                return (
                                                                    <div key={lesson_index} className="d-flex align-items-center ml-5 mb-2 border-bottom">
                                                                        <div>
                                                                            {!library.access_expired ? <Link 
                                                                                to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
                                                                                className="text-primary"
                                                                                style={{fontSize: "medium"}}
                                                                            >
                                                                                {indiv_lesson.title}
                                                                            </Link> : <Typography>
                                                                                {indiv_lesson.title}
                                                                            </Typography>}
                                                                            
                                                                            {Boolean(indiv_lesson.progress_report) && <Typography variant="subtitle2" style={{color: "#75767E"}}>
                                                                                Elements completed {indiv_lesson.progress_report.elements_completed}
                                                                            </Typography>}
                                                                        </div>
                                                                        
                                                                        {(!library.access_expired && !library.access_denied) && <Link 
                                                                            to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
                                                                            className="text-primary ml-auto mr-3"
                                                                        >
                                                                            View
                                                                        </Link>}
                                                                    </div>
                                                                );
                                                            }) : (
                                                                <div className="d-flex ml-5 mb-2 border-bottom">
                                                                    No lesson/s yet...
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                } else {
                                                    return <div key={module_index}>
                                                        {indiv_module.lessons && indiv_module.lessons.length ? indiv_module.lessons.map((indiv_lesson, lesson_index) => {
                                                            return (
                                                                <div key={lesson_index} className="d-flex align-items-center mb-2 border-bottom">
                                                                    <div>
                                                                        {!library.access_expired ? <Link 
                                                                            to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
                                                                            className="text-primary"
                                                                            style={{fontSize: "medium"}}
                                                                        >
                                                                            {indiv_lesson.title}
                                                                        </Link> : <Typography>
                                                                            {indiv_lesson.title}
                                                                        </Typography>}
                                                                        
                                                                        {Boolean(indiv_lesson.progress_report) && <Typography variant="subtitle2" style={{color: "#75767E"}}>
                                                                            Elements completed {indiv_lesson.progress_report.elements_completed}
                                                                        </Typography>}
                                                                    </div>
                                                                    
                                                                    {(!library.access_expired && !library.access_denied) && <Link 
                                                                        to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
                                                                        className="text-primary ml-auto mr-3"
                                                                    >
                                                                        View
                                                                    </Link>}
                                                                </div>
                                                            );
                                                        }) : (
                                                            <div className="d-flex ml-5 mb-2 border-bottom">
                                                                No lesson/s yet...
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                            }) : (
                                                <div className="text-center">
                                                    No content yet...
                                                </div>
                                            )
                                        // }
                                    })()}
                                </div>
                            </div>
                        </div>
                    </div>
                default: 
                    return setRecentLibraryTab("Training Details");
            }
        })()}
    </>
}