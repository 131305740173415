import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";

const FaqAnswer = lazy(() =>
  import("./FaqAnswer")
);



export default function FaqPage() {
  return (
    <Suspense>
      <Switch>
        <Route exact path="/lesson/faq/answer-question" component={FaqAnswer} />
      </Switch>
    </Suspense>
  );
}
