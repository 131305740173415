import React, { useState, useEffect, useRef } from 'react'
import { ReactTinyLink } from 'react-tiny-link'
import ReactPlayer from "react-player";

import { Typography, CircularProgress } from '@material-ui/core';

import OpenAIButton from "../../../../../components/OpenAIButton";
import { AIFieldCompletionModal } from "../../../../../components/AIPromptModal";
import { create_chat_completion } from '../../../_redux/LibrariesCrud';
import { isLinkAVideo } from "../../../../../modules/Functions";

// Start of Image Componenent
export const ElementImageView = ({ attachment, submitStudentElementResponse }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}
		<img 
			src={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment.file_name} 
			alt="Element" 
			className="d-block rounded mx-auto w-50"
		/>
	</>
}
// End of Image Component


// Start of Audio Component
export const ElementAudioView = ({ attachment, submitStudentElementResponse, setElements }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}
		<div>
			<audio 
				controls="controls" 
				className="w-75 d-block mx-auto"
				onEnded={async () => {
					try {
						const { status, data: { elements_query } } = await submitStudentElementResponse();

						if(status === 200) {
							setElements(elements_query);
							window.scrollTo(0,document.body.scrollHeight);
							return;
						}

					} catch(err) {
						console.log(err);
					}
				}}
			>
				<source src={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment.file_name} />
				Your browser does not support the audio element.
			</audio>
		</div>
	</>
}
// End of Audio Component

// Start of Document Component
export const ElementDocumentView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Document" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title || "Download for more info"}
			</Typography> 
		</div>

		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 
			</div>
		)}

		<div>
			<div className="text-center">
				<label htmlFor="document_input" className="text-center w-100 m-0 p-0 btn">
					<img
						width="50"
						src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
						alt="document"
						className="d-block mx-auto"
						style={{ maxHeight: "20vh" }}
					/>
				</label>
				<a
					href={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment.file_name}
					download={attachment.file_name}
					className="btn p-0"
				>
					<span className="text-primary">{attachment.file_name}</span>
					<br />
					<span>Click to download!</span>
				</a>
			</div>
		</div>
	</>
}
// End of Document Component

// Start of Video Component
export const ElementVideoView = ({
	attachment,
	submitStudentElementResponse,
	setElements,
	setPlayback,
	setIsPaused,
	savedPlayback,
	params,
	studentElementProgress,
	setDeleteElementPlayback
}) => {
	const [player, setPlayer] = useState(undefined);
	const playerRef = useRef(null);

	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />
			</div>
		)}

		{(() => {
			let start = 0;

			if(savedPlayback) {
				savedPlayback.forEach((item, index) => {
					if(
						item.lesson_resource_id === params.lesson_resource_id &&
						item.library_party_id === params.library_party_id &&
						item.module_resource_id === params.module_resource_id &&
						item.element_id === attachment.element_id
					) {
						start = item.playback;
					}
				});
			}

			return (attachment.context.video_link !== null && isLinkAVideo(attachment.context.video_link)) ? (
				<ReactPlayer
					ref={playerRef}
					url={attachment.context.video_link}
					width="100%"
					height="100%"
					className={"reactPlayer"}
					controls={true}
					onReady={(p) => {
						setPlayer(p);
					}}
					onEnded={async (e) => {
						try {
							setDeleteElementPlayback(attachment.element_id);
							const { status, data: { elements_query } } = await submitStudentElementResponse();

							if(status === 200) {
								setElements(elements_query);
								window.scrollTo(0,document.body.scrollHeight);
								player.setCurrentTime(parseInt(playerRef.current.getCurrentTime()));
								return;
							}
						}
						catch(err) {
							console.log(err);
						}
					}}
					config={{
						hlsOptions: {
							startPosition: studentElementProgress.is_completed ? studentElementProgress.video_duration : start
						}
					}}
				/>
			)
			: (
				<iframe
					src={attachment.context.video_link}
					style={{
						width: '100%',
						minHeight: "50vh",
						height: '100%',
						border: '0',
						overflow: 'hidden'
					}}
				></iframe>
			)
		})()}
	</>
}
// End of Video Component

// Start of Text Component
export const ElementTextView = ({ attachment, user }) => {
	const [aiCustomizedText, setAiCustomizedText] = useState("");
	const [isSubmitting, setSubmitting] = useState(false);
	const [isDataComplete, setIsDataComplete] = useState(true);
	const [isShowAIModal, setIsShowAIModal] = useState(false);
	const [aiPromptValues, setAiPromptValues] = useState({});

	useEffect(() => {
		setAiPromptValues(attachment.context);

		if(
			(!user?.additional_info?.industry && attachment.context?.ai_prompt?.includes("{{industry}}"))
			|| (!user?.additional_info?.team && attachment.context?.ai_prompt?.includes("{{team}}"))
			|| (!user?.additional_info?.position && attachment.context?.ai_prompt?.includes("{{position}}"))
			|| (!user?.additional_info?.product && attachment.context?.ai_prompt?.includes("{{product}}"))
			|| (!user?.additional_info?.product_description && attachment.context?.ai_prompt?.includes("{{product_description}}"))
		) {
			setIsDataComplete(false);
		}
		
		if(attachment.context.ai_auto_replace_text == 1) {
			setSubmitting(true);

			// TODO: set users
			let prompt = attachment.context.ai_prompt;
			prompt = prompt.replace("{{industry}}", user?.additional_info?.industry ?? "");
			prompt = prompt.replace("{{team}}", user?.additional_info?.team ?? "");
			prompt = prompt.replace("{{position}}", user?.additional_info?.position ?? "");
			prompt = prompt.replace("{{product}}", user?.additional_info?.product ?? "");
			prompt = prompt.replace("{{product_description}}", user?.additional_info?.product_description ?? "");

			let params = {
				string: attachment.context.text_content,
				prompt: prompt
			}

			create_chat_completion(params).then((response) => {
				setAiCustomizedText(response.data.data[0].content);
				
				setSubmitting(false);
			});
		}
	}, []);

	return <>
		<AIFieldCompletionModal
			setIsDataComplete={setIsDataComplete}
			isDataComplete={isDataComplete}
			user={user}
			isShowAIModal={isShowAIModal}
			setIsShowAIModal={setIsShowAIModal}
			setAiPromptValues={setAiPromptValues}
			aiPromptValues={aiPromptValues}
		/>
		{
			(attachment?.context?.text_type == "link") ? (
				<>
					<div className="d-flex align-items-center mb-3">
						<img 
							alt="Text" 
							src="https://lernnex.s3.amazonaws.com/element_type_icon/link_icon.png" 
							style={{ maxHeight: 50 }}
						/>
						<Typography variant="h6" className="font-weight-bolder">
							{attachment.title || "Let's take a Survey"}
						</Typography> 
					</div>

					<hr />

					{Boolean(attachment.description) && (
						<div>
							<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="font-weight-bolder">
								Description:
							</Typography>
							<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
								{attachment.description}
							</Typography> 
						</div>
					)}
					<div style={{textAlign: "-webkit-center"}}>
						<ReactTinyLink
							cardSize="small"
							showGraphic={true}
							maxLine={2}
							minLine={1}
							url={attachment.context.text_content}
						/>
					</div>
				</>
			)
			: (
				<>
					{
						aiPromptValues?.ai_auto_replace_text != 1 && (
							<>
								<Typography variant="body1" style={{whiteSpace : "pre-line", fontSize: "1.3rem"}}>
									{aiPromptValues.text_content}
								</Typography>

								{
									aiPromptValues.ai_customized == 1 && (
										<div className="text-center">
											<OpenAIButton
												setVal={setAiCustomizedText}
												values={aiPromptValues}
												isSubmitting={isSubmitting}
												setSubmitting={setSubmitting}
												buttonName="Customize with AI"
												isOverrideButton={isDataComplete ? false : true}
												function={() => {
													setIsShowAIModal(true);
												}}
											/>
											{
												(aiPromptValues.ai_is_custom_label == 1) && (
													<>
														{
															aiCustomizedText != "" && (
																<div className="my-5 text-left">
																	{aiPromptValues.ai_custom_label}
																</div>
															)
														}
													</>
												)
											}
										</div>
									)
								}
							</>
						)
					}
					{
						isSubmitting ? (
							<div className="w-100 text-center">
								<div>Please wait while our AI is customizing this for you.</div>
								<CircularProgress className="mt-5" />
							</div>
						)
						: (
							<div style={{fontSize: "1.3rem"}} dangerouslySetInnerHTML={{ __html: aiCustomizedText }}></div>
						)
					}
				</>
			)
		}
	</>
}
// End of Text Component

// Start of Reflection Component
export const ElementReflectionView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Reflection" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/reflection_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.context.is_shared === "shared" ? "Shared: ": "Private: "} How does this apply to you?
			</Typography> 
		</div>

		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="font-weight-bolder">
					Description:
				</Typography>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
					{attachment.description}
				</Typography> 
			</div>
		)}
	</>
}
// End of Reflection Component

// Start of Signature Component
export const ElementSignatureView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Signature" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/signature_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title}
			</Typography> 
		</div>

		<hr />

		{Boolean(attachment.description) && <>
			<Typography variant="body1" className="font-weight-bolder">
				Description:
			</Typography>
			<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
				{attachment.description}
			</Typography>
		</>}
	</>
}
// End of Signature Component

// Start of Survey Component
export const ElementSurveyView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Reflection" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/survey_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title || "Let's take a Survey"}
			</Typography> 
		</div>

		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="font-weight-bolder">
					Description:
				</Typography>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
					{attachment.description}
				</Typography> 
			</div>
		)}
	</>
}
// End of Survey Component

// Start of Link Component
export const ElementLinkView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Document" 
				src="https://lernnex.s3.amazonaws.com/element_type_icon/link_icon.png" 
				style={{ maxHeight: 50 }}
				className="pr-2"
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title || "Check this out"}
			</Typography> 
		</div>
		
		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 
			</div>
		)}

		<div className="row" style={{maxHeight: "400px", overflowY: "auto"}}>
			{attachment.attachment_additionals.map((attachment_additional, additional_index) => {
				return <div key={additional_index} className="col-6" style={{marginTop: "20px"}}>
					<div style={{marginBottom: "20px"}}>
						<ReactTinyLink
							cardSize="small"
							showGraphic={true}
							maxLine={2}
							minLine={1}
							url={attachment_additional.title}
						/>
					</div>
					<span style={{width: "500px"}}>{attachment_additional.description}</span>
				</div>
			})}
		</div>
	</>
}
// End of Link Component

// Start of Download Component
export const ElementDownloadView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Document" 
				src="https://lernnex.s3.amazonaws.com/element_type_icon/download_icon.png"
				style={{ maxHeight: 50 }}
				className="pr-2"
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title || "Download for more info"}
			</Typography> 
		</div>
		
		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 
			</div>
		)}

		<div className="row" style={{maxHeight: "400px", overflowY: "auto"}}>
			{attachment.attachment_additionals.map((attachment_additional, additional_index) => {
				return <div key={additional_index} className="col-6" style={{paddingTop: "20px"}}>
					<div style={{backgroundColor: "#EEF0F8", boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
						{/* <div style={{display: "flex", placeContent: "center", alignItems: "center"}}> */}
						<div style={{display: "inline-flex"}}>
							<div className="pr-3">
								<label htmlFor="document_input">
									<a
										href={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment_additional.context.file}
										download={attachment_additional.context.file}
										className="btn p-0"
										target="_blank"
									>
										{(() => {
											switch(attachment_additional.context.file.split(".").pop()) {
												case "doc":
												case "docx":
													return <img width="50" src="https://lernnex.s3.amazonaws.com/download_element_type/doc.png" alt="document" className="d-block mx-auto" style={{ maxHeight: "20vh", marginTop: "10px" }}/>
												case "csv":
												case "xls":
												case "xlsx":
													return <img width="50" src="https://lernnex.s3.amazonaws.com/download_element_type/excel.png" alt="document" className="d-block mx-auto" style={{ maxHeight: "20vh", marginTop: "10px" }}/>
												case "ppt":
												case "pptx":
													return <img width="50" src="https://lernnex.s3.amazonaws.com/download_element_type/ppt.png" alt="document" className="d-block mx-auto" style={{ maxHeight: "20vh", marginTop: "10px" }}/>
												case "pdf":
													return <img width="50" src="https://lernnex.s3.amazonaws.com/download_element_type/pdf.png" alt="document" className="d-block mx-auto" style={{ maxHeight: "20vh", marginTop: "10px" }}/>
												case "png":
												case "jpg":
												case "jpeg":
													return <img width="50" src="https://lernnex.s3.amazonaws.com/download_element_type/img.png" alt="document" className="d-block mx-auto" style={{ maxHeight: "20vh", marginTop: "10px" }}/>
												default:
													return <img width="50" src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png" alt="document" className="d-block mx-auto" style={{ maxHeight: "20vh", marginTop: "10px" }}/>;
											}
										})()}
									</a>
								</label>
							</div>
							
							<div style={{alignSelf: "center"}}>
								<span>
									{attachment_additional.context.description}
								</span>
							</div>
						</div>
					</div>
				</div>
			})}
		</div>
	</>
}
// End of Download Component