import React, { useState } from 'react'
import { FieldArray, Formik } from "formik";
import { injectIntl } from "react-intl";
import * as Yup from "yup";

import { 
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
	TextField,
    Typography,
 } from '@material-ui/core';
import { create_owner_category, update_owner_category } from '../../../_redux/CategoriesCrud';

export const OwnerCategoryCreateModal = injectIntl(({ user, categories, setCategories, intl }) => {
    const [open, setOpen] = useState(false);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			name: "",
            sub_categories: [{name: ""}]
		}}
		validationSchema={Yup.object().shape({
			name: Yup.string()
				.min(3, "Minimum 3 symbols")
				.max(50, "Maximum 50 symbols")
				.required(
					intl.formatMessage({
					    id: "AUTH.VALIDATION.REQUIRED_FIELD",
					})
				),

            sub_categories: Yup.array().of(Yup.object().shape(
				{ name: Yup.string().required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"})) }
			))
		})}
		onSubmit={(values, { setSubmitting, resetForm }) => {
            let new_post_values = { ...values };
            new_post_values.sub_categories = JSON.stringify(values.sub_categories);
            create_owner_category(new_post_values).then(({ data: { new_category } }) => {
                if(new_category) {
                    let new_categories = [...categories];
                    new_categories.push(new_category);
                    setCategories(new_categories);
                    handleClose();
                    resetForm();
                }
            }).catch(err => {
                setSubmitting(false);
                console.log(err);
            });
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleClickOpen}
				>
					Create a Category
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Create new Category</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bolder">Owner: </span>{user.firstname} {user.lastname} 
						</DialogContentText>

                        <div className='form-group'>
                            <TextField
                                autoFocus
                                margin="normal"
                                color="secondary"
                                label={<span>Name<span className="text-danger">*</span></span>}
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                helperText={touched.name && errors.name}
                                error={Boolean(touched.name && errors.name)}
                            />
                        </div>

                        <div className="form-group">
                            <Typography className="font-weight-bolder">
                                Sub-categories:
                            </Typography>

                            <FieldArray 
                                name="sub_categories"
                                render={(sub_categories) => {
                                    return <div className="form-group">
                                        {values.sub_categories.map((indiv_sub_category, sub_category_index) => {
                                            return <div key={sub_category_index} className="d-flex align-items-baseline">
                                                <Typography className="mx-3">
                                                    &#9632;
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    color="secondary"
                                                    label={<span>Sub-category {sub_category_index + 1}<span className="text-danger">*</span></span>}
                                                    name={`sub_categories.${sub_category_index}.name`}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.sub_categories[sub_category_index].name}
                                                    helperText={
                                                        touched.sub_categories &&
                                                        touched.sub_categories[sub_category_index] &&
                                                        touched.sub_categories[sub_category_index].name &&
                                                        errors.sub_categories && 
                                                        errors.sub_categories[sub_category_index] && 
                                                        errors.sub_categories[sub_category_index].name
                                                    }
                                                    error={Boolean(
                                                        touched.sub_categories &&
                                                        touched.sub_categories[sub_category_index] &&
                                                        touched.sub_categories[sub_category_index].name &&
                                                        errors.sub_categories && 
                                                        errors.sub_categories[sub_category_index] && 
                                                        errors.sub_categories[sub_category_index].name
                                                    )}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />

                                                <button 
                                                    type="button"
                                                    className="btn text-danger"
                                                    onClick={() => {
                                                        if(values.sub_categories.length > 1) {
                                                            sub_categories.remove(sub_category_index);
                                                        }
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        })}

                                        <button
                                            type="button"
                                            className="btn btn-info d-block ml-auto mt-5"
                                            onClick={() => sub_categories.push({ name: "" })}
                                        >
                                            Add more sub-category
                                        </button>
                                    </div>
                                }}
                            />
                        </div>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const OwnerCategoryEditModal = injectIntl(({ user, categories, setCategories, category, category_index, intl }) => {
    const [open, setOpen] = useState(false);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			category_party_id: category.party_id,
			name: category.name,
            sub_categories: category.sub_categories,
            deleted_sub_categories: [],
		}}
		validationSchema={Yup.object().shape({
			name: Yup.string()
				.min(3, "Minimum 3 symbols")
				.max(50, "Maximum 50 symbols")
				.required(
					intl.formatMessage({
					    id: "AUTH.VALIDATION.REQUIRED_FIELD",
					})
				),

            sub_categories: Yup.array().of(Yup.object().shape(
				{ name: Yup.string().required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"})) }
			))
		})}
		onSubmit={(values, { setSubmitting, resetForm }) => {
            let new_post_values = { ...values };
            new_post_values.sub_categories = JSON.stringify(values.sub_categories);
            new_post_values.deleted_sub_categories = JSON.stringify(values.deleted_sub_categories);
            update_owner_category(new_post_values).then(({ data: { category } }) => {
                if(category) {
                    let newCategories = [...categories];
					newCategories[category_index] = category;
					setCategories([]);
					setCategories(newCategories);
                }
            }).catch(err => {
                setSubmitting(false);
                console.log(err);
            });
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleClickOpen}
				>
					Edit
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Edit Category</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bolder">Owner: </span>{user.firstname} {user.lastname} 
						</DialogContentText>

                        <div className='form-group'>
                            <TextField
                                autoFocus
                                margin="normal"
                                color="secondary"
                                label={<span>Name<span className="text-danger">*</span></span>}
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                helperText={touched.name && errors.name}
                                error={Boolean(touched.name && errors.name)}
                            />
                        </div>

                        <div className="form-group">
                            <Typography className="font-weight-bolder">
                                Sub-categories:
                            </Typography>

                            <FieldArray 
                                name="sub_categories"
                                render={(sub_categories) => {
                                    return <div className="form-group">
                                        {values.sub_categories.map((indiv_sub_category, sub_category_index) => {
                                            return <div key={sub_category_index} className="d-flex align-items-baseline">
                                                <Typography className="mx-3">
                                                    &#9632;
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    color="secondary"
                                                    label={<span>Sub-category {sub_category_index + 1}<span className="text-danger">*</span></span>}
                                                    name={`sub_categories.${sub_category_index}.name`}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.sub_categories[sub_category_index].name}
                                                    helperText={
                                                        touched.sub_categories &&
                                                        touched.sub_categories[sub_category_index] &&
                                                        touched.sub_categories[sub_category_index].name &&
                                                        errors.sub_categories && 
                                                        errors.sub_categories[sub_category_index] && 
                                                        errors.sub_categories[sub_category_index].name
                                                    }
                                                    error={Boolean(
                                                        touched.sub_categories &&
                                                        touched.sub_categories[sub_category_index] &&
                                                        touched.sub_categories[sub_category_index].name &&
                                                        errors.sub_categories && 
                                                        errors.sub_categories[sub_category_index] && 
                                                        errors.sub_categories[sub_category_index].name
                                                    )}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />

                                                <button 
                                                    type="button"
                                                    className="btn text-danger"
                                                    onClick={() => {
                                                        if(values.sub_categories.length > 1) {
                                                            sub_categories.remove(sub_category_index);
                                                        }

                                                        let sc = values.deleted_sub_categories;
                                                        sc.push(indiv_sub_category);

                                                        setFieldValue("deleted_sub_categories", sc);
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        })}

                                        <button
                                            type="button"
                                            className="btn btn-info d-block ml-auto mt-5"
                                            onClick={() => sub_categories.push({ name: "" })}
                                        >
                                            Add more sub-category
                                        </button>
                                    </div>
                                }}
                            />
                        </div>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const OwnerLibraryCategoryCreateModal = injectIntl(({ user, categories, setCategories, createLibraryCategories, setCreateLibraryCategories, intl }) => {
	function handleClose() {
		setCreateLibraryCategories(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			name: "",
            sub_categories: [{name: ""}]
		}}
		validationSchema={Yup.object().shape({
			name: Yup.string()
				.min(3, "Minimum 3 symbols")
				.max(50, "Maximum 50 symbols")
				.required(
					intl.formatMessage({
					    id: "AUTH.VALIDATION.REQUIRED_FIELD",
					})
				),

            sub_categories: Yup.array().of(Yup.object().shape(
				{ name: Yup.string().required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"})) }
			))
		})}
		onSubmit={(values, { setSubmitting, resetForm }) => {
            let new_post_values = { ...values };
            new_post_values.sub_categories = JSON.stringify(values.sub_categories);
            create_owner_category(new_post_values).then(({ data: { new_category } }) => {
                if(new_category) {
                    let new_categories = [...categories];
                    new_categories.push(new_category);
                    setCategories(new_categories);
                    handleClose();
                    resetForm();
                }
            }).catch(err => {
                setSubmitting(false);
                console.log(err);
            });
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<Dialog open={createLibraryCategories} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Create new module</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bolder">Owner: </span>{user.firstname} {user.lastname} 
						</DialogContentText>

                        <div className='form-group'>
                            <TextField
                                autoFocus
                                margin="normal"
                                color="secondary"
                                label={<span>Name<span className="text-danger">*</span></span>}
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                helperText={touched.name && errors.name}
                                error={Boolean(touched.name && errors.name)}
                            />
                        </div>

                        <div className="form-group">
                            <Typography className="font-weight-bolder">
                                Sub-categories:
                            </Typography>

                            <FieldArray 
                                name="sub_categories"
                                render={(sub_categories) => {
                                    return <div className="form-group">
                                        {values.sub_categories.map((indiv_sub_category, sub_category_index) => {
                                            return <div key={sub_category_index} className="d-flex align-items-baseline">
                                                <Typography className="mx-3">
                                                    &#9632;
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    color="secondary"
                                                    label={<span>Sub-category {sub_category_index + 1}<span className="text-danger">*</span></span>}
                                                    name={`sub_categories.${sub_category_index}.name`}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.sub_categories[sub_category_index].name}
                                                    helperText={
                                                        touched.sub_categories &&
                                                        touched.sub_categories[sub_category_index] &&
                                                        touched.sub_categories[sub_category_index].name &&
                                                        errors.sub_categories && 
                                                        errors.sub_categories[sub_category_index] && 
                                                        errors.sub_categories[sub_category_index].name
                                                    }
                                                    error={Boolean(
                                                        touched.sub_categories &&
                                                        touched.sub_categories[sub_category_index] &&
                                                        touched.sub_categories[sub_category_index].name &&
                                                        errors.sub_categories && 
                                                        errors.sub_categories[sub_category_index] && 
                                                        errors.sub_categories[sub_category_index].name
                                                    )}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />

                                                <button 
                                                    type="button"
                                                    className="btn text-danger"
                                                    onClick={() => {
                                                        if(values.sub_categories.length > 1) {
                                                            sub_categories.remove(sub_category_index);
                                                        }
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        })}

                                        <button
                                            type="button"
                                            className="btn btn-info d-block ml-auto mt-5"
                                            onClick={() => sub_categories.push({ name: "" })}
                                        >
                                            Add more sub-category
                                        </button>
                                    </div>
                                }}
                            />
                        </div>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})
