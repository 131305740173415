import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

import { useSubheader } from "../../../../../../_metronic/layout";
import { OwnerLibraryCreate } from './OwnerLibraryCreate'

export const LibraryCreate = ({ currentRole, user }) => {

    const suhbeader = useSubheader();
	suhbeader.setTitle("Create New Library");

    switch(currentRole.id) {
        case 1: 
            return <OwnerLibraryCreate user={user} />
        default: 
            return <Redirect to="/" />
    }
}

const mapStateToProps = ({ currentValues: { currentRole }, auth: { user } }) => ({ currentRole, user })

export default connect(mapStateToProps)(LibraryCreate)