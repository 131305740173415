import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
	SetTemporaryData: "[Set Temporary Data] Action",
	SetQuickAccessPin: "[Set Quick Access Pin] Action",
};

const initialState = {
	data: undefined,
	pin: undefined,
};

export const reducer = persistReducer({
		storage,
		key: "temp",
		whitelist: ["data", "pin"]
	},
	(state = initialState, action) => {
		switch (action.type) {
			case actionTypes.SetTemporaryData: {
				const { data } = action.payload;
				return { ...state, data };
			}
			case actionTypes.SetQuickAccessPin: {
				const { pin } = action.payload;
				return { ...state, pin };
			}

			default:
				return state;
		}
	}
);

export const actions = {
	setTemporaryData: data => ({
		type: actionTypes.SetTemporaryData,
		payload: { data }
	}),
	setQuickAccessPin: pin => ({
		type: actionTypes.SetQuickAccessPin,
		payload: { pin }
	}),
};

export function* saga() {
	yield;
}
