/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";


import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import * as persistCurrentValues from "../../../../../redux/ducks/currentValues.duck";

const HeaderMenu = ({ layoutProps, currentRole, user, setCurrentRole, }) => {
    useEffect(() => {
        if(user && !currentRole) {
          setCurrentRole(user.roles[0]);
        }
    }, [user, setCurrentRole, currentRole]);
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    if(!currentRole) {
        return <></>;
    } else if(currentRole.id === 1) {
        return <div
            id="kt_header_menu"
            className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
            {...layoutProps.headerMenuAttributes}
            style={{display: "none"}}
        >
            {/*begin::Header Nav*/}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="menu-text">Dashboard</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/categories')}`}>
                    <NavLink className="menu-link" to="/categories">
                        <span className="menu-text">Categories</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                    </NavLink>
                </li>
                {/*end::1 Level*/}
                
                {/*begin::1 Level*/}
                <li
                    data-menu-toggle={layoutProps.menuDesktopToggle}
                    aria-haspopup="true"
                    className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/libraries')}`}>
                    <NavLink className="menu-link menu-toggle" to="/libraries">
                        <span className="menu-text">Libraries</span>
                        <i className="menu-arrow"></i>
                    </NavLink>
                    <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                        <ul className="menu-subnav">
                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/libraries/trainings')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/libraries/trainings">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Display1.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Trainings
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}

                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/libraries/manuals')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/libraries/manuals">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Manuals
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}

                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/libraries/policies')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/libraries/policies">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-check.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Policies
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}
                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                    data-menu-toggle={layoutProps.menuDesktopToggle}
                    aria-haspopup="true"
                    className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/teams')}`}>
                    <NavLink className="menu-link menu-toggle" to="/teams">
                        <span className="menu-text">Teams</span>
                        <i className="menu-arrow"></i>
                    </NavLink>
                    <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                        <ul className="menu-subnav">
                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/teams/teams-list')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/teams/teams-list">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Teams List
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}

                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                    data-menu-toggle={layoutProps.menuDesktopToggle}
                    aria-haspopup="true"
                    className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/positions')}`}>
                    <NavLink className="menu-link menu-toggle" to="/positions">
                        <span className="menu-text">Positions</span>
                        <i className="menu-arrow"></i>
                    </NavLink>
                    <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                        <ul className="menu-subnav">
                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/positions/positions-list')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/positions/positions-list">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Position.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Positions List
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}

                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                    data-menu-toggle={layoutProps.menuDesktopToggle}
                    aria-haspopup="true"
                    className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/companies')}`}>
                    <NavLink className="menu-link menu-toggle" to="/companies">
                        <span className="menu-text">Companies</span>
                        <i className="menu-arrow"></i>
                    </NavLink>
                    <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                        <ul className="menu-subnav">
                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/companies/companies-list')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/companies/companies-list">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Companies List
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}

                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}
            </ul>
            {/*end::Header Nav*/}
        </div>;
    } else if(currentRole.id === 2) {
        return <div
            id="kt_header_menu"
            className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
            {...layoutProps.headerMenuAttributes}
            style={{display: "none"}}
        >
            {/*begin::Header Nav*/}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="menu-text">Dashboard</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                    </NavLink>
                </li>
                {/*end::1 Level*/}


                {/*Classic submenu*/}
                {/*begin::1 Level*/}
                <li
                    data-menu-toggle={layoutProps.menuDesktopToggle}
                    aria-haspopup="true"
                    className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/libraries')}`}>
                    <NavLink className="menu-link" to="/libraries">
                        <span className="menu-text">Libraries</span>
                        <i className="menu-arrow"></i>
                    </NavLink>
                    <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                        <ul className="menu-subnav">
                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/libraries')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/libraries">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Display1.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        All Trainings
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}
                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                    data-menu-toggle={layoutProps.menuDesktopToggle}
                    aria-haspopup="true"
                    className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/companies')}`}>
                    <NavLink className="menu-link menu-toggle" to="/companies">
                        <span className="menu-text">Companies</span>
                        <i className="menu-arrow"></i>
                    </NavLink>
                    <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                        <ul className="menu-subnav">
                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/companies/companies-list')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/companies/companies-list">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Companies List
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}

                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}
            </ul>
            {/*end::Header Nav*/}
        </div>;
    }  else if(currentRole.id === 3) {
        return <div
            id="kt_header_menu"
            className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
            {...layoutProps.headerMenuAttributes}
            style={{display: "none"}}
        >
            {/*begin::Header Nav*/}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*Classic submenu*/}
                {/*begin::1 Level*/}
                <li
                    data-menu-toggle={layoutProps.menuDesktopToggle}
                    aria-haspopup="true"
                    className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/libraries')}`}>
                    <NavLink className="menu-link" to="/libraries">
                        <span className="menu-text">Libraries</span>
                        <i className="menu-arrow"></i>
                    </NavLink>
                    <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                        <ul className="menu-subnav">
                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/libraries')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/libraries">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Display1.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Trainings
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}
                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}
            </ul>
            {/*end::Header Nav*/}
        </div>;
    } else {
        return <></>;
    }
}

const mapStateToProps = ({ currentValues: { currentRole }, auth: { user } }) => ({ currentRole, user })
export default connect(mapStateToProps, persistCurrentValues.actions)(HeaderMenu);