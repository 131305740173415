import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
	SetCurrentRole: "[Set Current Role] Action",
	SetCurrentCompany: "[Set Current Company] Action",
	SetPlayback: "[Set Playback] API",
	ClearCurrent: "[ClearCurrent] Action",
};

const initialState = {
	currentRole: undefined,
	currentCompany: undefined,
	playback: undefined,
};

export const reducer = persistReducer({
		storage,
		key: "currentValues",
		whitelist: ["currentRole", "currentCompany", "playback"]
	},
	(state = initialState, action) => {
		switch (action.type) {
			case actionTypes.SetCurrentRole: {
				const { currentRole } = action.payload;
				return { ...state, currentRole };
			}
			
			case actionTypes.SetCurrentCompany: {
				const { currentCompany } = action.payload;
				return { ...state, currentCompany };
			}

			case actionTypes.SetPlayback: {
				const { playback } = action.payload;
				return { ...state, playback };
			}

			case actionTypes.ClearCurrent: {
				// TODO: Change this code. Actions in reducer aren't allowed.
				return initialState;
			}

			default:
				return state;
		}
	}
);

export const actions = {
	setCurrentRole: currentRole => ({
		type: actionTypes.SetCurrentRole,
		payload: { currentRole }
	}),
	setCurrentCompany: currentCompany => ({
		type: actionTypes.SetCurrentCompany,
		payload: { currentCompany }
	}),
	setPlayback: playback => ({
		type: actionTypes.SetPlayback,
		payload: { playback }
	}),
	clearCurrent: () => ({ type: actionTypes.ClearCurrent })
};

export function* saga() {
	yield;
}
