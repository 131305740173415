/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useLayoutEffect, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, connect } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { Avatar, Select, MenuItem } from '@material-ui/core';
import QuickActions from "../../subheader/components/QuickActions";
import { getBreadcrumbsAndTitle, useSubheader } from "../../../_core/MetronicSubheader";
import { useLocation } from "react-router-dom";
import * as persistCurrentValues from "../../../../../redux/ducks/currentValues.duck";
import { get_owner_companies_list } from "../../../../../app/modules/Companies/_redux/CompaniesCrud"

const UserProfileDropdown = ({ currentRole, currentCompany, setCurrentCompany }) => {
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const [companies, setCompanies] = useState([]);
  const [init, setInit] = useState(false);

  const role = useRef();

  role.current = currentRole ? currentRole.id : user.roles[0].id;

  const layoutProps = useMemo(() => {
	return {
	  light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
	  config: uiService.config,
	  subheaderMobileToggle: objectPath.get(
		  uiService.config,
		  "subheader.mobile-toggle"
	  ),
	  subheaderCssClasses: uiService.getClasses("subheader", true),
	  subheaderContainerCssClasses: uiService.getClasses(
		  "subheader_container",
		  true
	  )
	};
  }, [uiService]);

  useLayoutEffect(() => {
	const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
	const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
	const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
	subheader.setBreadcrumbs(breadcrumbs);
	subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title);
	// eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  function useCompare (val) {
	const prevVal = usePrevious(val)
	return prevVal !== val
  }
  
  // Helper hook
  function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
	  ref.current = value;
	});
	return ref.current;
  }

  const userValueChanged = useCompare(user);

  useEffect(() => {}, [subheader, userValueChanged]);

  useEffect(() => {
	get_owner_companies_list().then(({ data: { companies_query } }) => {
		setCompanies(companies_query)
	}).catch(err => {
		console.log(err);
	});

    if(user && typeof currentCompany === "undefined") {
      setCurrentCompany(user.companies[0]);
	  setInit(true);
    }
	else {
		setInit(true);
	}
  }, [user, currentCompany]);

  return <>
	{
		init && 
		<>
			<div style={{position: "absolute", left: "20px", bottom: "19px"}}>
				<h5 className="text-dark font-weight-bold my-2 mr-5">
					{
					((subheader.title == "Libraries Page" || subheader.title == "Company Users List" || subheader.title == "Teams" || subheader.title == "Positions") && role.current === 1 && user.companies.length > 1) ? (
						<Select
							name="company_party_id"
							color="secondary"
							className="w-100"
							value={currentCompany.party_id}
						>
							{companies.map((indiv_company, company_index) => {
								return <MenuItem
									key={company_index}
									value={indiv_company.party_id}
									onClick={() => setCurrentCompany(indiv_company)}
								>
									{indiv_company.name}
								</MenuItem>
							})}
						</Select>
					) : (
						subheader.title
					)
					}
				</h5>
			</div>

			<Dropdown drop="down" alignRight>
			<Dropdown.Toggle
				as={DropdownTopbarItemToggler}
				id="dropdown-toggle-user-profile"
			>
				<div
				className={
					"btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
				}
				>
				<span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
					Hi,
				</span>{" "}
				<span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
					{user.firstname}
				</span>
				<span className="symbol symbol-35 symbol-light-success">
					<span className="symbol-label font-size-h5 font-weight-bold">
					<Avatar
						variant={"square"}
						className="mr-2 rounded"
						alt="Avatar"
						src={user.image_file ? `${process.env.REACT_APP_S3_BUCKET}/user_uploads/images/${user.image_file}` : "https://lernnex.s3.amazonaws.com/user_profile_images/default_user_image.png"} 
					/>
					</span>
				</span>
				</div>
			</Dropdown.Toggle>
			<Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
				<>
				{/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
				{layoutProps.light && (
					<>
					<div className="d-flex align-items-center p-8 rounded-top">
						<div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
						<img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
						</div>
						<div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
						{user.firstname}
						</div>
						{/* <span className="label label-light-success label-lg font-weight-bold label-inline">
						3 messages
						</span> */}
					</div>
					<div className="separator separator-solid"></div>
					</>
				)}

				{!layoutProps.light && (
					<div
					className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
					style={{
						backgroundImage: `url(${toAbsoluteUrl(
						"/media/misc/bg-1.jpg"
						)})`,
					}}
					>
					<div className="symbol bg-white-o-15 mr-3">
						<span className="symbol-label text-success font-weight-bold font-size-h4">
						{user.firstname[0]}
						</span>
						{/*<img alt="Pic" className="hidden" src={user.pic} />*/}
					</div>
					<div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
						{user.firstname}
					</div>
					{/* <span className="label label-success label-lg font-weight-bold label-inline">
						3 messages
					</span> */}
					</div>
				)}
				</>

				<div className="navi navi-spacer-x-0 pt-5">
				<Link to="/user-profile" className="navi-item px-8 cursor-pointer">
					<div className="navi-link">
					<div className="navi-icon mr-2">
						<i className="flaticon2-calendar-3 text-success" />
					</div>
					<div className="navi-text">
						<div className="font-weight-bold cursor-pointer">
						My Profile
						</div>
						<div className="text-muted">
						Account settings and more
						<span className="label label-light-danger label-inline font-weight-bold">
							update
						</span>
						</div>
					</div>
					</div>
				</Link>

				<div className="navi-separator mt-3"></div>

				<div className="navi-footer  px-8 py-5">
					<Link
					to="/logout"
					className="btn btn-light-primary font-weight-bold"
					>
					Sign Out
					</Link>
					<a href="#" className="btn btn-clean font-weight-bold" style={{display: "none"}}>
					Upgrade Plan
					</a>
				</div>
				</div>
			</Dropdown.Menu>
			</Dropdown>

			<div className="d-flex align-items-center ml-5">
				<QuickActions />
			</div>
		</>
	}
  </>
}

const mapStateToProps = ({ currentValues: { currentRole, currentCompany }, auth: { user } }) => ({ currentRole, currentCompany, user })

export default connect(mapStateToProps, persistCurrentValues.actions)(UserProfileDropdown);