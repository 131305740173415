import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function get_owner_positions(company_party_id, library_party_id = "") {
	return await axios.get(API_URL + "/position/get-owner-position?company_party_id=" + company_party_id + "&library_party_id=" + library_party_id);
}

export async function create_new_position(values) {

    let dataPost = new FormData();

	for (const [key, value] of Object.entries(values)) {
		dataPost.append(key, value);
	}

    return await axios.post(API_URL + "/position/create-new-position", dataPost);
}

export async function update_new_position(values) {

    let dataPost = new FormData();

	for (const [key, value] of Object.entries(values)) {
		dataPost.append(key, value);
	}

    return await axios.post(API_URL + "/position/update-new-position", dataPost);
}