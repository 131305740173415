import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { TextField, CircularProgress, Checkbox, FormControlLabel } from '@material-ui/core';

import { isEmpty } from "../modules/Functions";
import OpenAIButton from "./OpenAIButton";
import { update_user } from "../modules/Auth/_redux/authCrud";
import { create_chat_completion, update_user_temporary_data } from '../pages/Libraries/_redux/LibrariesCrud';

const AIPromptFields = ({...props}) => {
	return (
		<>
			{
				props.values?.ai_prompt?.includes("{{industry}}") && (
					<TextField
						className="d-block w-100 p-3"
						name="ai_prompt_industry"
						label="Industy"
						value={props.values.ai_prompt_industry}
						variant="outlined"
						onBlur={props.handleBlur}
						onChange={props.handleChange}
						InputProps={{
							className: "w-100",
						}}
					/>
				)
			}
			{
				props.values?.ai_prompt?.includes("{{team}}") && (
					<TextField
						className="d-block w-100 p-3"
						name="ai_prompt_team"
						label="Team"
						value={props.values.ai_prompt_team}
						variant="outlined"
						onBlur={props.handleBlur}
						onChange={props.handleChange}
						InputProps={{
							className: "w-100",
						}}
					/>
				)
			}
			{
				props.values?.ai_prompt?.includes("{{position}}") && (
					<TextField
						className="d-block w-100 p-3"
						name="ai_prompt_position"
						label="Position"
						value={props.values.ai_prompt_position}
						variant="outlined"
						onBlur={props.handleBlur}
						onChange={props.handleChange}
						InputProps={{
							className: "w-100",
						}}
					/>
				)
			}
			{
				props.values?.ai_prompt?.includes("{{product}}") && (
					<TextField
						className="d-block w-100 p-3"
						name="ai_prompt_product"
						label="Product"
						value={props.values.ai_prompt_product}
						variant="outlined"
						onBlur={props.handleBlur}
						onChange={props.handleChange}
						InputProps={{
							className: "w-100",
						}}
					/>
				)
			}
			{
				props.values?.ai_prompt?.includes("{{product_description}}") && (
					<TextField
						className="d-block w-100 p-3"
						name="ai_prompt_product_description"
						label="Product Description"
						value={props.values.ai_prompt_product_description}
						variant="outlined"
						onBlur={props.handleBlur}
						onChange={props.handleChange}
						InputProps={{
							className: "w-100",
						}}
					/>
				)
			}
		</>
	)
}

export const AIFieldCompletionModal = ({...props}) => {
	const { enqueueSnackbar } = useSnackbar();
	const [additionalInfo, setAdditionalInfo] = useState();
	const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	useEffect(() => {
		if(!props.standalone) {
			setAdditionalInfo(props.user?.additional_info);
		}
		else {
			setAdditionalInfo(props.tempData);
		}
	}, [props]);

	return (
		<Modal
			size="lg"
			show={props.isShowAIModal}
			onHide={() => {
				props.setIsShowAIModal(false);
			}}
			backdrop={ !props?.standalone ? true : 'static' }
			keyboard={ !props?.standalone ? true : false }
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Please Complete Your{ !props?.standalone ? " Additional" : "" } Data</Modal.Title>
			</Modal.Header>
			<Formik
				enableReinitialize={true}
				initialValues={{
					ai_prompt: props.aiPromptValues?.ai_prompt,
					ai_prompt_industry: additionalInfo?.industry ?? "",
					ai_prompt_team: additionalInfo?.team ?? "",
					ai_prompt_position: additionalInfo?.position ?? "",
					ai_prompt_product: additionalInfo?.product ?? "",
					ai_prompt_product_description: additionalInfo?.product_description ?? "",
					is_additional_info: 1,
					firstname: additionalInfo?.firstname,
					lastname: additionalInfo?.lastname,
					email: additionalInfo?.email,
				}}
				validate={values => {
					const errors = {};

					if(props.standalone) {
						if(!values.firstname) {
							errors.firstname = "Please complete required fields.";
						}
						if(!values.lastname) {
							errors.lastname = "Please complete required fields.";
						}
						if(!values.email) {
							errors.email = "Please complete required fields.";
						}
					}

					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(true);

					if(!props?.standalone) {
						let params = {
							is_additional_info: 1,
							industry: values.ai_prompt_industry,
							product: values.ai_prompt_product,
							product_description: values.ai_prompt_product_description,
							position: values.ai_prompt_position,
							team: values.ai_prompt_team,
						};

						params.is_additional_info = 1;

						let promptVal = props.aiPromptValues;
						promptVal.ai_prompt_industry = values.ai_prompt_industry;
						promptVal.ai_prompt_product = values.ai_prompt_product;
						promptVal.ai_prompt_product_description = values.ai_prompt_product_description;
						promptVal.ai_prompt_team = values.ai_prompt_team;
						promptVal.ai_prompt_position = values.ai_prompt_position;
						props.setAiPromptValues(promptVal);
	
						update_user(params).then(({ data: { output } }) => {
							snackbarAlert(output.status, output.message);
	
							props.setIsDataComplete(true);
						})
						.catch((err) => {
							console.log(err);
						})
					}
					else {
						let params = {
							firstname: values.firstname,
							lastname: values.lastname,
							email: values.email
						}

						if(!isEmpty(values.ai_prompt_industry)) {
							params.industry = values.ai_prompt_industry;
						}

						if(!isEmpty(values.ai_prompt_product)) {
							params.product = values.ai_prompt_product;
						}

						if(!isEmpty(values.ai_prompt_product_description)) {
							params.product_description = values.ai_prompt_product_description;
						}

						if(!isEmpty(values.ai_prompt_position)) {
							params.position = values.ai_prompt_position;
						}

						if(!isEmpty(values.ai_prompt_team)) {
							params.team = values.ai_prompt_team;
						}

						update_user_temporary_data(params).then((response) => {
							props.setTemporaryData(params);

							props.setIsShowAIModal(false);
						})
						.catch((err) => {
							console.log(err);
						})
					}

					setSubmitting(false);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					handleReset
				}) => (
					<form
						onSubmit={handleSubmit}
						noValidate
						autoComplete="off"
						className="card card-custom"
					>
						<Modal.Body className="text-center">
							{
								props.standalone && (
									<>
										<PromptTextField
											name="firstname"
											label="First Name"
											value={values.firstname}
											onBlur={handleBlur}
											onChange={handleChange}
											touched={touched}
											errors={errors}
										/>
										<PromptTextField
											name="lastname"
											label="Last Name"
											value={values.lastname}
											onBlur={handleBlur}
											onChange={handleChange}
											touched={touched}
											errors={errors}
										/>
										<PromptTextField
											name="email"
											label="Email"
											value={values.email}
											onBlur={handleBlur}
											onChange={handleChange}
											touched={touched}
											errors={errors}
										/>
									</>
								)
							}
							<AIPromptFields
								values={values}
								handleBlur={handleBlur}
								handleChange={handleChange}
								isForDataCompletion={true}
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={acceptedPrivacyPolicy}
										onChange={() => setAcceptedPrivacyPolicy(!acceptedPrivacyPolicy)}
										inputProps={{"aria-label": "primary checkbox"}}
									/>
								}
								label={<span>By signing up, I agree to <a href='https://www.olern.com/privacy-policy/'>terms & conditions</a> provided by Olern.</span>}
							/>
						</Modal.Body>
						<Modal.Footer>
							<button
								type="submit"
								className="btn btn-success mr-2"
								disabled={isSubmitting || !acceptedPrivacyPolicy}
								style={{float: "right"}}
							>
								Save Changes
							</button>
							{/* <Button variant="primary" onClick={() => {
								props.setIsShowAIModal(false);
							}}>
								Close
							</Button> */}
						</Modal.Footer>
					</form>
				)}
			</Formik>
		</Modal>
	)
}

const AIPromptModal = ({...props}) => {
	const [autoCreateChatCompletion, setAutoCreateChatCompletion] = useState(false); 

	useEffect(() => {
		if(autoCreateChatCompletion) {
			props.setSubmitting(true);
	
			let params = {
				string: props.values.text_content,
				prompt: props.values.ai_prompt
			}
	
			create_chat_completion(params).then((response) => {
				props.setFieldValue('test_content', response.data.data[0].content);
	
				props.setSubmitting(false);
			});
		}
	}, [autoCreateChatCompletion]);

	useEffect(() => {
		if(props.values.show_test_modal) {
			setAutoCreateChatCompletion(props.values?.ai_prompt?.includes("{{") ? false : true);
		}
	}, [props.values.show_test_modal]);

	return (
		<Modal
			size="lg"
			show={props.values.show_test_modal}
			onHide={() => {
				props.setFieldValue('show_test_modal', !props.values.show_test_modal);
			}}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Customize The Text Using The Following Data</Modal.Title>
			</Modal.Header>
			<Modal.Body className="text-center">
				{
					props.values?.ai_prompt?.includes("{{") && (
						<>
							<AIPromptFields
								values={props.values}
								handleBlur={props.handleBlur}
								handleChange={props.handleChange}
								isForDataCompletion={false}
							/>
							<OpenAIButton
								isSubmitting={props.isSubmitting}
								values={props.values}
								setFieldValue={props.setFieldValue}
								setSubmitting={props.setSubmitting}
								buttonName="Customize Text"
								isTest={true}
							/>
						</>
					)
				}
				<div className={"p-3 w-100" + (props.isSubmitting ? "text-center" : "text-left")}>
					{
						props.isSubmitting ? (
							<>
								<div>Please wait while our AI is customizing this for you.</div>
								<CircularProgress className="mt-5" />
							</>
						)
						: (
							<div style={{fontSize: "1.3rem"}} dangerouslySetInnerHTML={{ __html: props.values.test_content }}></div>
						)
					}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={() => {
					props.setFieldValue('show_test_modal', !props.values.show_test_modal);
				}}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

const PromptTextField = ({ ...props }) => {
	return (
		<TextField
			className="d-block w-100 p-3"
			variant="outlined"
			InputProps={{
				className: "w-100",
			}}
			helperText={props.touched[props.name] && props.errors[props.name]}
			error={Boolean(props.touched[props.name] && props.errors[props.name])}
			{...props}
		/>
	)
}

export default AIPromptModal;