import axios from "axios";
import * as constant from "../../../modules/Constants/Constants";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const API_URL = process.env.REACT_APP_API_URL;

export async function get_owner_libraries_list(library_type, company_party_id) {
    let library_type_id = 1;
    if(library_type === "manuals") {
        library_type_id = 2;
    } else if(library_type === "policies") {
        library_type_id = 3;
    }
    return await axios.get(API_URL + "/library/get-owner-libraries-list?library_type_id=" + library_type_id + "&company_party_id=" + company_party_id);
}

export async function get_admin_libraries_list(library_type) {
    let library_type_id = 1;
    if(library_type === "manuals") {
        library_type_id = 2;
    } else if(library_type === "policies") {
        library_type_id = 3;
    }
    return await axios.get(API_URL + "/library/get-admin-libraries-list?library_type_id=" + library_type_id);
}

export async function get_student_libraries_list() {
    return await axios.get(API_URL + "/library/get-student-libraries-list");
}

export async function create_ower_library(values) {

    let dataPost = new FormData();

	for (const [key, value] of Object.entries(values)) {
		dataPost.append(key, value);
	}

    return await axios.post(API_URL + "/library/create-owner-library", dataPost);
}

export async function create_owner_module(values) {
    return await axios.post(API_URL + "/library/create-owner-module", values);
}

export async function get_owner_library_details(library_party_id, is_mentor = "") {
    return await axios.get(API_URL + "/library/get-owner-library-details/" + library_party_id + `?is_mentor=${is_mentor}`);
}

export async function get_owner_library_content(library_party_id, is_mentor = "") {
    return await axios.get(API_URL + "/library/get-owner-library-content/" + library_party_id + `?is_mentor=${is_mentor}`);
}

export async function create_owner_module_lesson(values) {
    return await axios.post(API_URL + "/library/create-owner-module-lesson", values);
}

export async function get_owner_lesson_details(library_party_id, module_resource_id, lesson_resource_id, is_mentor = "") {
    return await axios.get(API_URL + "/library/get-owner-lesson-details/" + lesson_resource_id + `?library_party_id=${library_party_id}&module_resource_id=${module_resource_id}&is_mentor=${is_mentor}`);
}

export async function get_element_types() {
    return await axios.get(API_URL + "/library/get-element-types");
}

export async function create_owner_element(values) {
    return await axios.post(API_URL + "/library/create-owner-element", values);
}

export async function get_owner_lesson_content(library_party_id, module_resource_id, lesson_resource_id, is_mentor = "") {
    return await axios.get(API_URL + "/library/get-owner-lesson-content/" + lesson_resource_id + `?library_party_id=${library_party_id}&module_resource_id=${module_resource_id}&is_mentor=${is_mentor}`);
}

export async function create_owner_element_attachment(values) {
    let dataPost = new FormData();

	for (const [key, value] of Object.entries(values)) {
        if(values.element_type_id == 10 && key == "files") {
           value.forEach((file) => {
            dataPost.append('files[]', file.file);
            dataPost.append('files_descriptions[]', file.description);
           })
        }
        else {
            dataPost.append(key, value);
        }
	}

	return await axios.post(API_URL + "/library/create-owner-element-attachment", dataPost, { cancelToken: source.token });
}

export async function update_owner_element_attachment(values) {
    
    let dataPost = new FormData();

	for (const [key, value] of Object.entries(values)) {
        if(values.element_type_id == 10 && key == "files") {
            value.forEach((file, file_index) => {
                if(typeof file.id !== "undefined") {
                    dataPost.append(`files_id[${file_index}]`, file.id);
                }

                dataPost.append(`files[${file_index}]`, file.file);
                dataPost.append(`files_descriptions[${file_index}]`, file.description);
            })
        }
        else if (values.element_type_id == 10 && key == "del_existing_file") {
            value.forEach((del_id, del_id_index) => {
                dataPost.append(`del_existing_file[]`, del_id);
            })
        }
        else {
            dataPost.append(key, value);
        }
	}

    // for(var pair of dataPost.entries()) {
    //     console.log(pair[0]+', '+pair[1]);
    // }

	return await axios.post(API_URL + "/library/update-owner-element-attachment", dataPost, { cancelToken: source.token });
}

export async function get_owner_enrollees_list(library_party_id) {
    return await axios.get(API_URL + "/library/get-owner-enrollees-list/" + library_party_id);
}

export async function create_owner_enrollee(values) {
    return await axios.post(API_URL + "/library/create-owner-enrollee", values);
}

export async function import_owner_enrollee(values) {
    let dataPost = new FormData();

	for(const [key, value] of Object.entries(values)) {
		dataPost.append(key, value);
	}

    return await axios.post(API_URL + "/library/import-owner-enrollee", dataPost);
}

export async function get_student_library_details(library_party_id) {
    return await axios.get(API_URL + "/library/get-student-library-details/" + library_party_id);
}

export async function get_student_library_content(library_party_id) {
    return await axios.get(API_URL + "/library/get-student-library-content/" + library_party_id);
}


export async function submit_student_element_response(values) {
    return await axios.post(API_URL + "/library/submit-student-element-response", values);
}

export async function get_student_lesson_details(library_party_id, module_resource_id, lesson_resource_id) {
    return await axios.get(API_URL + "/library/get-student-lesson-details/" + lesson_resource_id + `?library_party_id=${library_party_id}&module_resource_id=${module_resource_id}`);
}

export async function get_student_lesson_content(library_party_id, module_resource_id, lesson_resource_id) {
    return await axios.get(API_URL + "/library/get-student-lesson-content/" + lesson_resource_id + `?library_party_id=${library_party_id}&module_resource_id=${module_resource_id}`);
}

export async function get_student_attachment_responses(library_party_id, module_resource_id, lesson_resource_id, element_id, element_attachment_id) {
    return await axios.get(API_URL + "/library/get-student-attachment-responses/" + element_attachment_id + `?library_party_id=${library_party_id}&module_resource_id=${module_resource_id}&lesson_resource_id=${lesson_resource_id}&element_id=${element_id}`);
}

export async function get_owner_attachment_responses(library_party_id, module_resource_id, lesson_resource_id, element_id, element_attachment_id, is_mentor = "") {
    return await axios.get(API_URL + "/library/get-owner-attachment-responses/" + element_attachment_id + `?library_party_id=${library_party_id}&module_resource_id=${module_resource_id}&lesson_resource_id=${lesson_resource_id}&element_id=${element_id}&is_mentor=${is_mentor}`);
}

export async function add_owner_company_library(values) {
    return await axios.post(API_URL + "/library/add-owner-company-library", values);
}

export async function add_owner_company_team_library(values) {
    return await axios.post(API_URL + "/library/add-owner-company-team-library", values);
}

export async function add_owner_company_position_library(values) {
    return await axios.post(API_URL + "/library/add-owner-company-position-library", values);
}

export async function get_owner_categories_list() {
    return await axios.get(API_URL + "/category/get-owner-categories-list");
}

export async function get_mentor_categories_list(library_party_id) {
    return await axios.get(API_URL + "/category/get-mentor-categories-list/" + library_party_id);
}

export async function update_owner_library_settings(libary_party_id, values, is_faq = "") {
    return await axios.put(API_URL + "/library/update-owner-library-settings/" + libary_party_id + "?is_faq=" + is_faq, values);
}

export async function publish_owner_library(libary_party_id, values) {
    return await axios.put(API_URL + "/library/publish-owner-library/" + libary_party_id, values);
}

export async function request_company_student_library_access(post_values) {
    return await axios.post(API_URL + "/library/request-company-student-library-access", post_values);
}

export async function update_owner_student_library_access(post_values) {
    return await axios.post(API_URL + "/library/update-owner-student-library-access", post_values);
}

export async function update_owner_library_details(values, library_party_id) {

    let requestBody = new FormData();

	for (const [key, value] of Object.entries(values)) {
		requestBody.append(key, value);
	}
    
    return await axios.post(API_URL + "/library/update-owner-library-details/" + library_party_id, requestBody);
}

export async function create_owner_library_lesson(values) {
    return await axios.post(API_URL + "/library/create-owner-library-lesson", values);
}

export async function update_owner_module(module_resource_id, values) {
    return await axios.put(API_URL + "/library/update-owner-module/" + module_resource_id, values);
}

export async function sort_owner_module(module_resource_id, values) {
    return await axios.put(API_URL + "/library/sort-owner-module/" + module_resource_id, values);
}

export async function delete_owner_module(module_resource_id, values) {
    return await axios.delete(API_URL + "/library/delete-owner-module/" + module_resource_id, { data: values });
}

export async function delete_owner_lesson(lesson_resource_id, values) {
    return await axios.delete(API_URL + "/library/delete-owner-lesson/" + lesson_resource_id, { data: values });
}

export async function update_owner_lesson(lesson_resource_id, values) {
    return await axios.put(API_URL + "/library/update-owner-lesson/" + lesson_resource_id, values);
}

export async function sort_owner_lesson(lesson_resource_id, values) {
    return await axios.put(API_URL + "/library/sort-owner-lesson/" + lesson_resource_id, values);
}

export async function delete_owner_element(element_id, values) {
    return await axios.delete(API_URL + "/library/delete-owner-element/" + element_id, { data: values });
}

export async function sort_owner_element(element_id, values) {
    return await axios.put(API_URL + "/library/sort-owner-element/" + element_id, values);
}

export async function get_mentor_libraries_list(library_type) {
    let library_type_id = 1;
    if(library_type === "manuals") {
        library_type_id = 2;
    } else if(library_type === "policies") {
        library_type_id = 3;
    }
    return await axios.get(API_URL + "/library/get-mentor-libraries-list?library_type_id=" + library_type_id);
}

export async function add_owner_library_mentor(values) {
    return await axios.post(API_URL + "/library/add-owner-library-mentor", values);
}

export async function get_mentor_library_details(library_party_id) {
    return await axios.get(API_URL + "/library/get-mentor-library-details/" + library_party_id);
}

export async function get_mentor_library_content(library_party_id) {
    return await axios.get(API_URL + "/library/get-mentor-library-content/" + library_party_id);
}

export async function get_mentor_enrollees_list(library_party_id) {
    return await axios.get(API_URL + "/library/get-mentor-enrollees-list/" + library_party_id);
}

export async function create_mentor_enrollee(values) {
    return await axios.post(API_URL + "/library/create-mentor-enrollee", values);
}

// export async function get_mentor_categories_list() {
//     return await axios.get(API_URL + "/category/get-mentor-categories-list");
// }

export async function update_mentor_library_details(values, library_party_id) {

    let requestBody = new FormData();

	for (const [key, value] of Object.entries(values)) {
		requestBody.append(key, value);
	}
    
    return await axios.post(API_URL + "/library/update-mentor-library-details/" + library_party_id, requestBody);
}

export async function update_mentor_library_settings(libary_party_id, values) {
    return await axios.put(API_URL + "/library/update-mentor-library-settings/" + libary_party_id, values);
}

export async function publish_mentor_library(libary_party_id, values) {
    return await axios.put(API_URL + "/library/publish-mentor-library/" + libary_party_id, values);
}

export async function add_mentor_company_library(values) {
    return await axios.post(API_URL + "/library/add-mentor-company-library", values);
}

export async function update_mentor_student_library_access(post_values) {
    return await axios.post(API_URL + "/library/update-mentor-student-library-access", post_values);
}

export async function create_mentor_module(values) {
    return await axios.post(API_URL + "/library/create-mentor-module", values);
}

export async function create_mentor_module_lesson(values) {
    return await axios.post(API_URL + "/library/create-mentor-module-lesson", values);
}

export async function create_mentor_library_lesson(values) {
    return await axios.post(API_URL + "/library/create-mentor-library-lesson", values);
}

export async function update_mentor_module(module_resource_id, values) {
    return await axios.put(API_URL + "/library/update-mentor-module/" + module_resource_id, values);
}

export async function update_mentor_lesson(lesson_resource_id, values) {
    return await axios.put(API_URL + "/library/update-mentor-lesson/" + lesson_resource_id, values);
}

export async function create_mentor_element(values) {
    return await axios.post(API_URL + "/library/create-mentor-element", values);
}

export async function create_mentor_element_attachment(values) {
    
    let dataPost = new FormData();

	for (const [key, value] of Object.entries(values)) {
		dataPost.append(key, value);
	}

	// for(var pair2 of dataPost.entries()) {
	//    console.log(pair2[0]+ ', '+ pair2[1]); 
	// }

	return await axios.post(API_URL + "/library/create-mentor-element-attachment", dataPost, { cancelToken: source.token });
}

export async function sort_mentor_element(element_id, values) {
    return await axios.put(API_URL + "/library/sort-mentor-element/" + element_id, values);
}

export async function sort_mentor_module(module_resource_id, values) {
    return await axios.put(API_URL + "/library/sort-mentor-module/" + module_resource_id, values);
}

export async function sort_mentor_lesson(lesson_resource_id, values) {
    return await axios.put(API_URL + "/library/sort-mentor-lesson/" + lesson_resource_id, values);
}

export async function delete_owner_library_mentor(library_party_id, values) {
    return await axios.delete(API_URL + "/library/delete-owner-library-mentor/" + library_party_id, { data: values });
}

export async function submit_student_question(lesson_resource_id, question, library_party_id) {
    //TEMPORARY SOLUTION, LIBRARY_PARTY SHOULD NOT INCLUDED IN PARAMETERS
    return await axios.post(API_URL + "/library/submit-student-question/" + lesson_resource_id, {
        question: question,
        library_party_id: library_party_id
    })
}

export async function answer_student_question(values) {
    let headers = constant.DEFAULT_AUTH_HEADER;
    headers['cancelToken'] = source.token;

    return await axios.post(API_URL + "/answer-student-question", values, headers)
}

export async function get_owner_faq_details(lesson_resource_id) {
    return await axios.get(API_URL + "/library/get-owner-faq-details/" + lesson_resource_id);
}

export function create_chat_completion(params) {
	return axios.post(API_URL + "/create-chat-completion", params, constant.CHATGPT_AUTH_HEADER);
}

export function get_element_attachment_by_code(code) {
    return axios.get(API_URL + "/get-element-attachment-by-code/" + code, constant.DEFAULT_AUTH_HEADER);
}

export function get_survey_temp_data(id) {
    return axios.get(API_URL + "/get-survey-temp-data/" + id, constant.DEFAULT_AUTH_HEADER);
}

export function get_element_temp_data(id) {
    return axios.get(API_URL + "/get-element-temp-data/" + id, constant.DEFAULT_AUTH_HEADER);
}

export function update_user_temporary_data(params) {
    return axios.post(API_URL + "/update-user-temporary-data", params, constant.DEFAULT_AUTH_HEADER);
}

