import React, {useState, useEffect} from "react";
import { Formik } from 'formik'

import OpenAIButton from "./OpenAIButton";
import { AIFieldCompletionModal } from "./AIPromptModal";
import { isEmpty } from "../modules/Functions";
import { DoughnutChart } from '../widgets/charts';
import { useSnackbar } from "notistack";

import {
	Typography,
	CircularProgress,
	RadioGroup,
	FormControlLabel,
	TextField,
	Radio,
	Avatar,
	Link
}
from '@material-ui/core';

import {
	update_user_temporary_data,
	get_survey_temp_data,
	get_element_temp_data
}
from "../pages/Libraries/_redux/LibrariesCrud";

export const ElementTextView = ({...props}) => {
	const [aiCustomizedText, setAiCustomizedText] = useState(isEmpty(props?.customizedText) ? "" : props?.customizedText);
	const [isSubmitting, setSubmitting] = useState(false);
	const [isDataComplete, setIsDataComplete] = useState(true);
	const [isShowAIModal, setIsShowAIModal] = useState(!props.tempData?.email ? true : false);
	const [aiPromptValues, setAiPromptValues] = useState(props.attachment?.context);

	useEffect(() => {
		setAiPromptValues(props.attachment?.context);

		if(!isEmpty(props.attachment.context)) {
			// todo: 
			if(props.attachment.context.ai_prompt.includes("{{industry}}") && isEmpty(props?.tempData?.industry)) {
				setIsShowAIModal(true);
			}
			else if(props.attachment.context.ai_prompt.includes("{{team}}") && isEmpty(props?.tempData?.team)) {
				setIsShowAIModal(true);
			}
			else if(props.attachment.context.ai_prompt.includes("{{position}}") && isEmpty(props?.tempData?.position)) {
				setIsShowAIModal(true);
			}
			else if(props.attachment.context.ai_prompt.includes("{{product}}") && isEmpty(props?.tempData?.product)) {
				setIsShowAIModal(true);
			}
			else if(props.attachment.context.ai_prompt.includes("{{product_description}}") && isEmpty(props?.tempData?.product_description)) {
				setIsShowAIModal(true);
			}
		}
	}, [props]);

	return (
		<>
			<AIFieldCompletionModal
				setIsDataComplete={setIsDataComplete}
				isDataComplete={isDataComplete}
				isShowAIModal={isShowAIModal}
				setIsShowAIModal={setIsShowAIModal}
				setAiPromptValues={setAiPromptValues}
				aiPromptValues={aiPromptValues}
				standalone={true}
				{...props}
			/>

			{
				aiPromptValues?.ai_auto_replace_text != 1 && (
					<>
						<Typography variant="body1" style={{whiteSpace : "pre-line", fontSize: "1.3rem"}}>
							{aiPromptValues?.text_content}
						</Typography>

						{
							aiPromptValues?.ai_customized == 1 && (
								<div className="text-center my-5">
									<OpenAIButton
										setVal={setAiCustomizedText}
										values={aiPromptValues}
										isSubmitting={isSubmitting}
										setSubmitting={setSubmitting}
										buttonName="Customize with AI"
										isOverrideButton={isDataComplete ? false : true}
										standalone={true}
										tempData={props.tempData}
										function={() => {
											setIsShowAIModal(true);
										}}
									/>
									{
										(aiPromptValues.ai_is_custom_label == 1) && (
											<>
												{
													aiCustomizedText != "" && (
														<div className="my-5 text-left">
															{aiPromptValues.ai_custom_label}
														</div>
													)
												}
											</>
										)
									}
								</div>
							)
						}
					</>
				)
			}

			{
				isSubmitting ? (
					<div className="w-100 text-center">
						<div>Please wait while our AI is customizing this for you.</div>
						<CircularProgress className="mt-5" />
					</div>
				)
				: (
					<div style={{fontSize: "1.3rem"}} dangerouslySetInnerHTML={{ __html: aiCustomizedText }}></div>
				)
			}
		</>
	);
}

export const ElementSurveyView = ({...props}) => {
	const [isSubmitting, setSubmitting] = useState(false);
	const [isDataComplete, setIsDataComplete] = useState(true);
	const [isShowAIModal, setIsShowAIModal] = useState(!props.tempData?.email ? true : false);
	const [aiPromptValues, setAiPromptValues] = useState(props.attachment?.context);
	const [elementData, setElementData] = useState({});
	const [graphData, setGraphData] = useState({});
	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	useEffect(() => {
		if(!isEmpty(props?.tempData?.elements)) {
			let result = props.tempData.elements.find(item => item.attachment_additional_id === props.attachment.id);

			setElementData(result);

			if(result.student_element_progress === "complete") {
				get_survey_temp_data(props.attachment.id).then((response) => {
					if(response.data.init[0].status == 'error') {
						snackbarAlert('error', response.data.init[0].message);
						return;
					}

					setGraphData({
						dataLabel: Object.keys(response.data.data[0].graph_data),
						data: Object.values(response.data.data[0].graph_data)
					})
				});
			}
		}

	}, [props.tempData]);

	return (
		<>
			<AIFieldCompletionModal
				setIsDataComplete={setIsDataComplete}
				isDataComplete={isDataComplete}
				isShowAIModal={isShowAIModal}
				setIsShowAIModal={setIsShowAIModal}
				setAiPromptValues={setAiPromptValues}
				aiPromptValues={aiPromptValues}
				standalone={true}
				{...props}
			/>

			<Formik
				initialValues={{
					attachment_additional_id: props.attachment.id,
					student_response: "",
					student_additional_response: "",
					explanation: "",
					require_explanation: props.attachment.context.require_explanation,
					step: 1
				}}
				validate={values => {
					const errors = {};

					if (!values.student_additional_response) {
						errors.student_additional_response = "Please complete all required fields.";
					}

					if (values.step == 3 && values.required_explantion && !values.explanation) {
						errors.explanation = "Please complete all required fields.";
					}

					return errors;
				}}
				onSubmit={async (values) => {
					if(values.step == 3 || !values.require_explanation) {
						values.firstname = props.tempData.firstname;
						values.lastname = props.tempData.lastname;
						values.email = props.tempData.email;
	
						update_user_temporary_data(values).then((response) => {
							if(response.data.init[0].status == 'error') {
								snackbarAlert('error', response.data.init[0].message);
								return;
							}
	
							snackbarAlert("success", "Thanks for your response!");
	
							let newData = {...props.tempData};
							let data = {
								attachment_additional_id: props.attachment.id,
								student_element_progress: "complete",
								student_response: values.student_response,
								student_additional_response: values.student_additional_response,
								explanation: values.explanation,
								require_explanation: values.require_explanation
							};
	
							if(isEmpty(newData?.elements)) {
								newData.elements = [data];
							}
							else {
								let foundIndex = newData.elements.findIndex(item => item.attachment_additional_id === data.attachment_additional_id);
	
								if (foundIndex === -1) {
									newData.elements.push(data);
								}
								else {
									newData.elements[foundIndex] = Object.assign({}, newData.elements[foundIndex], data);
								}
							}
	
							props.setTemporaryData(newData);
						})
						.catch((err) => {
							console.log(err);
						})
					}
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					handleReset
				}) => (
					<form
						onSubmit={handleSubmit}
						noValidate
						autoComplete="off"
					>
						<div className="d-flex align-items-center mb-3">
							<img 
								alt="Reflection" 
								src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/survey_icon.png" 
								style={{ maxHeight: 50 }}
							/>
							<Typography variant="h6" className="font-weight-bolder">
								{props.attachment.title || "Let's take a Survey"}
							</Typography> 
						</div>
			
						<hr />
			
						{
							!isEmpty(props.attachment.description) && (
								<div>
									<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="font-weight-bolder">
										Description:
									</Typography>
									<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
										{props.attachment.description}
									</Typography> 
								</div>
							)
						}

						{
							elementData.student_element_progress === "complete" ? (
								<>
									<div className="row">
										<div className="col-lg-4 m-auto">
											<Typography>
												<span className="font-weight-bold">You've chosen: </span>
												{elementData.student_response}
											</Typography>
											{props.attachment.context.require_explanation && <>
												<Typography>
													<span className="font-weight-bold">You're explanation: </span>
													{elementData.explanation}
												</Typography>
											</>}
										</div>
										
										<div className="col-lg-8">
											{
												(!isEmpty(graphData?.dataLabel) && !isEmpty(graphData?.data)) && (
													<DoughnutChart 
														chartLabel="Survey Data" 
														dataLabel={graphData?.dataLabel}
														data={graphData?.data}
													/>
												)
											}
										</div>
										
									</div>

									<hr />

									<Typography align='center' className="text-success">
										Completed
									</Typography>
								</>
							)
							: (
								<>
									<Typography variant="body1" className="font-weight-bolder">
										Choices:
									</Typography>
									
									<RadioGroup 
										aria-label="choices" 
										name="student_additional_response" 
										className="ml-5"
										value={values.student_additional_response}
										onChange={async e => {
											await setFieldValue("student_additional_response", e.target.value);
										}}
									>
										{props.attachment?.attachment_additionals?.map((attachment_additional) => {
											return <FormControlLabel 
												key={attachment_additional.id}
												disabled={isSubmitting || values.step == 2} 
												value={attachment_additional.id.toString()} 
												control={<Radio />} 
												label={attachment_additional.description} 
												onClick={() => {
													setFieldValue("student_response", attachment_additional.description);
												}}
											/>
										})}
									</RadioGroup>
									<label style={{color: "#f018a6"}}>{touched.student_additional_response && errors.student_additional_response}</label>
			
									{(values.require_explanation && values.step == 2) && (
										<div className="form-group">
											<Typography variant="body1" className="font-weight-bolder">
												{props.attachment.context.explanation_description}
											</Typography>
			
											<TextField
												color="secondary"
												multiline
												name="explanation"
												placeholder="Input your explanation here"
												margin="normal"
												variant="outlined"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.explanation}
												helperText={touched.explanation && errors.explanation}
												error={Boolean(touched.explanation && errors.explanation)}
											/>
										</div>
									)}
									
									{
										values.step == 1 ?
											<Typography align='center' className="text-info">
												<button
													type="submit"
													onClick={() => {
			
														if(values.student_additional_response) {
															setFieldValue("step", 2)
														}
													}}
													disabled={isSubmitting}
													className="btn btn-primary"
												>
													Submit
												</button>
											</Typography>
										:
											<Typography align='center' className="text-info">
												<button
													type="submit"
													onClick={() => setFieldValue("step", 3)}
													disabled={isSubmitting}
													className="btn btn-primary"
												>
													Submit
												</button>
											</Typography>
									}
								</>
							)
						}
					</form>
				)}
			</Formik>
		</>
	)
}

export const ResultsSurveyView = ({...props}) => {
	const [graphData, setGraphData] = useState({});
	const { enqueueSnackbar } = useSnackbar();
	
	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	useEffect(() => {
		if(!isEmpty(props.attachment?.id)) {
			get_survey_temp_data(props.attachment.id).then((response) => {
				if(response.data.init[0].status == 'error') {
					snackbarAlert('error', response.data.init[0].message);
					return;
				}
	
				setGraphData({
					dataLabel: Object.keys(response.data.data[0].graph_data),
					data: Object.values(response.data.data[0].graph_data)
				})
			});
		}
	}, [props.attachment]);

	return (<>
		{
			!isEmpty(graphData) && (
				<DoughnutChart 
					chartLabel={props.attachment.title}
					dataLabel={graphData?.dataLabel}
					data={graphData?.data}
				/>
			)
		}
	</>)
}

export const ElementReflectionView = ({ ...props }) => {
	const [isSubmitting, setSubmitting] = useState(false);
	const [isDataComplete, setIsDataComplete] = useState(true);
	const [isShowAIModal, setIsShowAIModal] = useState(!props.tempData?.email ? true : false);
	const [aiPromptValues, setAiPromptValues] = useState(props.attachment?.context);
	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	return <>
		<AIFieldCompletionModal
			setIsDataComplete={setIsDataComplete}
			isDataComplete={isDataComplete}
			isShowAIModal={isShowAIModal}
			setIsShowAIModal={setIsShowAIModal}
			setAiPromptValues={setAiPromptValues}
			aiPromptValues={aiPromptValues}
			standalone={true}
			{...props}
		/>

		<div className="d-flex align-items-center mb-3">
			<img
				alt="Reflection" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/reflection_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{props.attachment.context.is_shared === "shared" ? "Shared: ": "Private: "} How does this apply to you?
			</Typography> 
		</div>

		<hr />

		{!isEmpty(props.attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="font-weight-bolder">
					Description:
				</Typography>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
					{props.attachment.description}
				</Typography> 
			</div>
		)}

		<Formik
			initialValues={{
				attachment_additional_id: props.attachment.id,
				student_response: "",
				student_element_progress: "pending"
			}}
			validate={values => {
				const errors = {};

				if (!values.student_response) {
					errors.student_response = "Please complete all required fields.";
				} 

				return errors;
			}}
			onSubmit={async (values, { setFieldValue }) => {
				values.firstname = props.tempData.firstname;
				values.lastname = props.tempData.lastname;
				values.email = props.tempData.email;

				update_user_temporary_data(values).then((response) => {
					if(response.data.init[0].status == 'error') {
						snackbarAlert('error', response.data.init[0].message);
						return;
					}

					snackbarAlert("success", "Thanks for your response!");
				})
				.catch((err) => {
					console.log(err);
				})
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				setFieldValue,
				handleReset
			}) => (
				<form
					onSubmit={handleSubmit}
					noValidate
					autoComplete="off"
				>
					<div className="form-group">
						<TextField
							label="Your Response"
							color="secondary"
							multiline
							name="student_response"
							placeholder="Your response could be multiple lines..."
							margin="normal"
							variant="outlined"
							onBlur={handleBlur}
							onChange={handleChange}
							value={values.student_response}
							helperText={touched.student_response && errors.student_response}
							error={Boolean(touched.student_response && errors.student_response)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>

					<Typography align='center' className="text-info">
						<button
							type="submit"
							style={{backgroundColor: "#0269d4"}}
							disabled={isSubmitting}
							className="btn btn-success"
						>
							Submit
						</button>
					</Typography>
				</form>
			)}
		</Formik>
	</>
}

export const ResultsReflectionView = ({ ...props }) => {
	const [isSubmitting, setSubmitting] = useState(false);
	const [elementData, setElementData] = useState({});
	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	useEffect(() => {
		get_element_temp_data(props.attachment.id).then((response) => {
			if(response.data.init[0].status == 'error') {
				snackbarAlert('error', response.data.init[0].message);
				return;
			}

			setElementData(response.data.data);
		});
	}, []);

	return <>
		<div className="d-flex align-items-center mb-3">
			<img
				alt="Reflection" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/reflection_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{props.attachment.context.is_shared === "shared" ? "Shared: ": "Private: "} How does this apply to you?
			</Typography> 
		</div>

		<hr />

		{!isEmpty(props.attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="font-weight-bolder">
					Description:
				</Typography>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
					{props.attachment.description}
				</Typography> 
			</div>
		)}

		{
			!isEmpty(elementData) && elementData.map((data, index) => {
				return (
					<div key={index}>
						<div className="d-flex">
							<Avatar
								className="mr-2"
								height={60}
								alt="Avatar"
								src="https://lernnex.s3.amazonaws.com/user_profile_images/default_user_image.png" 
							/>
							<div>
								<Typography className="font-weight-bold">
									<Link to="#">{data.firstname}</Link>
								</Typography>
								<Typography variant="subtitle2">
									<small>{(new Date(data.date)).toLocaleString(undefined, { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit' })}</small>
								</Typography>
								<Typography className="font-weight-bolder">
									Response: 
								</Typography>
		
								<Typography>
									{data.student_response}
								</Typography>
							</div>
						</div>
						<hr />
					</div>
				)
			})
		}
	</>
}