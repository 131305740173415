/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import FaqPage from "./pages/Libraries/pages/faq-forum/FaqPage";
import QuickAccessPage from "./pages/QuickAccess/QuickAccessPage";
import QuickAccessResultsPage from "./pages/QuickAccess/QuickAccessResultsPage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

export function Routes() {
	const { isAuthorized, user } = useSelector(
		({ auth }) => ({
			isAuthorized: auth.user != null,
			user: auth
		}),
		shallowEqual
	);
	
	return (
		<Switch>
			<Route path="/lesson/faq" component={FaqPage} />
			<Route path="/me" component={QuickAccessPage} />
			<Route path="/results" component={QuickAccessResultsPage} />
			
			{
				!isAuthorized ? (
					/*Render auth page when user at `/auth` and not authorized.*/
					<Route>
						<AuthPage />
					</Route>
				)
				: (
					/*Otherwise redirect to root page (`/`)*/
					<Redirect from="/auth" to="/" />
				)
			}

			<Route path="/error" component={ErrorsPage} />
			<Route path="/logout" component={Logout} />

			{
				!isAuthorized ? (
					/*Redirect to `/auth` when user is not authorized*/
					<Redirect to="/auth/login" />
				)
				: (
					<Layout>
						<BasePage auth={user} />
					</Layout>
				)
			}

		</Switch>
	);
}
