import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import { Redirect } from 'react-router-dom';
import {
    TextField,
	Select,
    MenuItem,
    Typography,
    Switch,
    Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
    Portal
} from "@material-ui/core";
import { OwnerLibraryCategoryCreateModal } from '../../../../../modules/Categories/pages/categories-list/components/CategoriesListModals';
import { create_ower_library, get_owner_categories_list } from "../../../_redux/LibrariesCrud";
import { get_owner_companies_list } from "../../../../../modules/Companies/_redux/CompaniesCrud";

export const OwnerLibraryCreate = injectIntl(({ user, intl }) => {
    const portalRef = useRef(null);

	const [imageTempURL, setImageTempURL] = useState("");
    const [redirect, setRedirect] = useState("");
    const [categories, setCategories] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [createLibraryCategories, setCreateLibraryCategories] = useState(false);

    useEffect(() => {

        get_owner_categories_list().then(({ data: { categories_query } }) => {
            if(categories_query) {
                setCategories(categories_query);
            }
        }).catch(err => console.log(err));

        get_owner_companies_list().then(({ data: { companies_query } }) => {
            if(companies_query) {
                setCompanies(companies_query);
            }
        }).catch(err => console.log(err));
    }, []);
    
    if(redirect) {
        return <Redirect to={"/libraries/" + redirect} />
    }

    return (
        <Formik
            initialValues={{
                title: "",
                description: "",
                library_type_id: 1,
                company_party_id: 0,
                category_party_id: 0,
                sub_category_party_id: 0,
                who_should_take_this: "",
                what_will_you_learn: "",
                image_file: "",
                group_content: true
            }}
            validate={values => {
                const errors = {};

                if (!values.title) {
                    errors.title = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                }

                if (!values.company_party_id) {
                    errors.company_party_id = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                }

                if (!values.sub_category_party_id) {
                    errors.sub_category_party_id = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                }

                if (!values.sub_category_party_id) {
                    errors.sub_category_party_id = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                create_ower_library(values).then(({ data: { new_library } }) => {
                    if(new_library) {
                        resetForm();
                        let redirect = "trainings";
                        if(values.library_type_id === 2) {
                            redirect = "manuals";
                        } else if(values.library_type_id === 3) {
                            redirect = "policies";
                        }
                        setRedirect(redirect);
                    }
                }).catch(err => console.log(err)).finally(() => setSubmitting(false));
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                handleReset
            }) => (
                <>
                    <form
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                        className="w-75 mx-auto border rounded p-3 bg-white"
                    >
                        <div style={{paddingLeft: "5%", paddingRight: "5%"}}>
                            <div className="form-group">
                                <Typography>
                                    Company <span className="text-danger">*</span>
                                </Typography>
                                <Select
                                    name="company_party_id"
                                    color="secondary"
                                    className="w-100"
                                    value={values.company_party_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    error={Boolean(
                                        touched.company_party_id && errors.company_party_id
                                    )}
                                >
                                    <MenuItem
                                        disabled={true}
                                        value={0}
                                    >
                                        Select a company
                                    </MenuItem>
                                    {companies.map((indiv_company, company_index) => {
                                        return <MenuItem
                                            key={company_index}
                                            value={indiv_company.party_id}
                                        >
                                            {indiv_company.name}
                                        </MenuItem>
                                    })}
                                </Select>
                                <small className="text-danger">
                                    {touched.company_party_id && errors.company_party_id}
                                </small>
                            </div>
                            
                            <div className="form-group">
                                <TextField
                                    className="w-100"
                                    color="secondary"
                                    label={<span>Title<span className="text-danger">*</span></span>}
                                    name="title"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.title}
                                    helperText={touched.title && errors.title}
                                    error={Boolean(touched.title && errors.title)}
                                />
                            </div>
                            
                            <div className="form-group border rounded p-2">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <Typography>
                                            Library Type <span className="text-danger">*</span>
                                        </Typography>
                                        <Select
                                            name="library_type_id"
                                            color="secondary"
                                            className="w-100"
                                            value={values.library_type_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            error={Boolean(
                                                touched.library_type_id && errors.library_type_id
                                            )}
                                        >
                                            <MenuItem
                                                value={1}
                                            >
                                                Trainings
                                            </MenuItem>
                                            <MenuItem
                                                value={2}
                                            >
                                                Manuals
                                            </MenuItem>
                                            <MenuItem
                                                value={3}
                                            >
                                                Policies
                                            </MenuItem>
                                        </Select>
                                        <small className="text-danger">
                                            {touched.library_type_id && errors.library_type_id}
                                        </small>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="d-flex align-items-center h-100">
                                            <Typography>
                                                Group Content
                                            </Typography>
                                            <Switch
                                                name="group_content"
                                                checked={Boolean(values.group_content)}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="form-group border rounded p-2">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Typography>
                                            Category <span className="text-danger">*</span>
                                        </Typography>
                                        <Select
                                            name="category_party_id"
                                            color="secondary"
                                            className="w-100"
                                            value={values.category_party_id}
                                            onChange={(e) => {
                                                if(e.target.value == "add_category") {
                                                    setCreateLibraryCategories(true)
                                                }
                                                else {
                                                    handleChange(e);
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            error={Boolean(
                                                touched.category_party_id && errors.category_party_id
                                            )}
                                        >
                                            <MenuItem
                                                disabled={true}
                                                value={0}
                                            >
                                                Select a category
                                            </MenuItem>
                                            {categories.map((indiv_category, category_index) => {
                                                return <MenuItem
                                                    key={category_index}
                                                    value={indiv_category.party_id}
                                                >
                                                    {indiv_category.name}
                                                </MenuItem>
                                            })}
                                            <MenuItem
                                                    value={"add_category"}
                                                >
                                                    <b>+ Add Category</b>
                                            </MenuItem>
                                        </Select>
                                        <small className="text-danger">
                                            {touched.category_party_id && errors.category_party_id}
                                        </small>
                                    </div>
                                    <div className="col-lg-6">
                                        <Typography>
                                            Sub-Category <span className="text-danger">*</span>
                                        </Typography>
                                        <Select
                                            name="sub_category_party_id"
                                            color="secondary"
                                            className="w-100"
                                            value={values.sub_category_party_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            error={Boolean(
                                                touched.sub_category_party_id && errors.sub_category_party_id
                                            )}
                                        >
                                            <MenuItem
                                                disabled={true}
                                                value={0}
                                            >
                                                Select a sub-category
                                            </MenuItem>
                                            {values.category_party_id && categories.find(indiv_category => (indiv_category.party_id === values.category_party_id)).sub_categories.map((indiv_category, category_index) => {
                                                return <MenuItem
                                                    key={category_index}
                                                    value={indiv_category.party_id}
                                                >
                                                    {indiv_category.name}
                                                </MenuItem>
                                            })}
                                        </Select>
                                        <small className="text-danger">
                                            {touched.sub_category_party_id && errors.sub_category_party_id}
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div ref={portalRef} />

                            <div className="form-group">
                                <TextField
                                    label="Description"
                                    color="secondary"
                                    multiline
                                    name="description"
                                    placeholder="Description could be multiple lines..."
                                    margin="normal"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description}
                                    helperText={touched.description && errors.description}
                                    error={Boolean(touched.description && errors.description)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    label="What will you learn?"
                                    color="secondary"
                                    multiline
                                    name="what_will_you_learn"
                                    placeholder="This field could be multiple lines..."
                                    margin="normal"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.what_will_you_learn}
                                    helperText={touched.what_will_you_learn && errors.what_will_you_learn}
                                    error={Boolean(touched.what_will_you_learn && errors.what_will_you_learn)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    label="Who should take this?"
                                    color="secondary"
                                    multiline
                                    name="who_should_take_this"
                                    placeholder="This field could be multiple lines..."
                                    margin="normal"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.who_should_take_this}
                                    helperText={touched.who_should_take_this && errors.who_should_take_this}
                                    error={Boolean(touched.who_should_take_this && errors.who_should_take_this)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>

                            <div className="form-group w-100 border rounded p-3">
                                Image:
                                <div className="row">
                                    <div className="offset-lg-4 col-lg-4 col-md-4">
                                        <input
                                            id="image_file"
                                            type="file"
                                            name="image_file"
                                            accept="image/*"
                                            className="d-none"
                                            onChange={imageBLOB => {
                                                let reader = new FileReader();

                                                if (imageBLOB.currentTarget.files[0]) {
                                                    setFieldValue(
                                                        "image_file",
                                                        imageBLOB.currentTarget.files[0]
                                                    );
                                                    reader.readAsDataURL(
                                                        imageBLOB.currentTarget.files[0]
                                                    );
                                                    reader.onload = image => {
                                                        setImageTempURL(image.target.result);
                                                    };
                                                }
                                            }}
                                        />

                                        <label htmlFor="image_file" className="btn">
                                            {imageTempURL !== "" ? (
                                                <img
                                                    alt="upload"
                                                    className="w-100 d-block rounded border p-2"
                                                    src={imageTempURL}
                                                />
                                            ) : (
                                                <div>
                                                    <img
                                                        alt="upload"
                                                        className="w-100 d-block rounded border p-2"
                                                        src={process.env.REACT_APP_S3_BUCKET + "/library_uploads/images/default_library_image.jpg"}
                                                    />
                                                    Change
                                                </div>
                                            )}
                                        </label>

                                        {
                                            imageTempURL !== "" ? (
                                                <div>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-danger mx-auto d-block btn-sm" 
                                                        onClick={() => {
                                                            setFieldValue("image_file", null);
                                                            setImageTempURL("");
                                                        }}>
                                                        Reset to Default
                                                    </button>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-success d-block ml-auto"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                    
                    <OwnerLibraryCategoryCreateModal categories={categories} setCategories={setCategories} user={user} createLibraryCategories={createLibraryCategories} setCreateLibraryCategories={setCreateLibraryCategories} />
                </>
            )}
        </Formik>
    )
})
