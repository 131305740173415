import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Formik } from 'formik';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { useSnackbar } from "notistack";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentValues from "../../../../../redux/ducks/currentValues.duck"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import CloseIcon from '@material-ui/icons/Close';

import {
	Tabs, 
	Tab,
	Typography,
	TextField,
	RadioGroup,
	FormControlLabel,
	Radio,
	IconButton,
	Avatar
} from "@material-ui/core";

import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useSubheader } from '../../../../../_metronic/layout';
import { DoughnutChart } from '../../../../widgets/charts';

import moment from 'moment';

import {
	get_student_lesson_details,
	get_student_lesson_content,
	submit_student_element_response,
	get_student_library_content,
	get_student_library_details,
	submit_student_question,
	get_owner_faq_details
} from '../../_redux/LibrariesCrud';

import { 
	ElementAudioView, 
	ElementImageView, 
	ElementDocumentView,
	ElementVideoView,
	ElementTextView,
	ElementReflectionView,
	ElementSignatureView,
	ElementSurveyView,
	ElementLinkView,
	ElementDownloadView
} from './components/StudentLessonElements';
import { Spinner } from 'react-bootstrap';
import { ShowResponses, SubmitLessonQuestion } from './components/LessonContentModals';

const StudentLessonDetails = injectIntl(({ params, intl, user, actions }) => {
	const { savedPlayback } = useSelector(({ currentValues }) => ({
		savedPlayback: currentValues?.playback
	}));

	const history = useHistory();

	const [init, setInit] = useState(false);
	const [lesson, setLesson] = useState({});
	const [elements, setElements] = useState([]);
	const [recentLessonTab, setRecentLessonTab] = useState("Lesson Details");
	const [elementIsSubmitting, setElementIsSubmitting] = useState(false);
	const [accessDenied, setAccessDenied] = useState(false);
	const [libraryContent, setLibraryContent] = useState([]);
	const [library, setLibrary] = useState({});
	const [openLessonQuestion, setOpenLessonQuestion] = useState(false);
	const [faqs, setFaqs] = useState([]);
	const [canProceed, setCanProceed] = useState(false);
	const [canBack, setCanBack] = useState(false);
	const [playback, setPlayback] = useState(0);
	const [isPaused, setIsPaused] = useState(true);
	const [deleteElementPlayback, setDeleteElementPlayback] = useState(0);
	const [showQuestionField, setShowQuestionField] = useState(false);
	const [avatarBgColor, setAvatarBgColor] = useState(["#000000","#FF0000","#00FF00","#0000FF","#FF00FF","#800080","#800000","#808000"]);

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	useEffect(() => {
		setInit(false);

		get_student_lesson_details(params.library_party_id, params.module_resource_id, params.lesson_resource_id).then(({ data: { lesson_query } }) => {
			if(lesson_query) {
				setLesson(lesson_query);
			}
			setInit(true);
		}).catch((err) => {
			console.log(err);
			setAccessDenied(true);
		});

		get_student_lesson_content(params.library_party_id, params.module_resource_id, params.lesson_resource_id).then(({ data: { elements_query } }) => {
			if(elements_query) {
				setElements(elements_query);
			}
			setInit(true);
		}).catch(err => console.log(err));

		get_student_library_content(params.library_party_id).then(({ data: { library_content_query } }) => {
			if(library_content_query) {
				setLibraryContent(library_content_query);

				let next_topic = false;
				let last_topic = false;

				if(params.module_resource_id == "library_lesson") {
					let current_lesson_index = library_content_query[0].lessons.findIndex(y => y.resource_id == params.lesson_resource_id);

					next_topic = library_content_query[0].lessons[current_lesson_index + 1];
					last_topic = library_content_query[0].lessons[current_lesson_index - 1];
				}
				else {
					let module_index = library_content_query.findIndex(x => x.resource_id == params.module_resource_id);
					let current_lesson_index = library_content_query[module_index].lessons.findIndex(y => y.resource_id == params.lesson_resource_id);
					let next_lesson_data = library_content_query[module_index].lessons[current_lesson_index + 1];
					let last_lesson_data = library_content_query[module_index].lessons[current_lesson_index - 1];
					let next_module_data = library_content_query[module_index + 1];
					let last_module_data = library_content_query[module_index - 1];

					if(next_lesson_data || next_module_data) {
						next_topic = true
					}
					else {
						next_topic = false;
					}

					if(last_lesson_data || last_module_data) {
						last_topic = true
					}
					else {
						last_topic = false;
					}
				}

				if(next_topic) { //for proceed button
					setCanProceed(true)
				}
				else {
					setCanProceed(false);
				}

				if(last_topic) { //for proceed button
					setCanBack(true)
				}
				else {
					setCanBack(false);
				}
			}
			setInit(true);
		}).catch(err => console.log(err));

		get_student_library_details(params.library_party_id).then(({ data: { library_query } }) => {
			
			if(library_query) {
				setLibrary(library_query);

				if(library_query.settings.allow_questions.isAllowed) {
					get_owner_faq_details(params.lesson_resource_id).then(({ data: { faqs_query } }) => {
						if(faqs_query) {
							setFaqs(faqs_query);
						}
					}).catch(err => console.log(err))
				}
			}

			setInit(true);
		}).catch(err => {
			console.log(err);
			setAccessDenied(true);
		})

	}, [params.library_party_id, params.module_resource_id, params.lesson_resource_id]);

	useEffect(() => {
		if(isPaused && playback != 0) {
			handleSavePlayback();
		}
	}, [isPaused]);

	useEffect(() => {
		if(deleteElementPlayback != 0) {
			if(typeof savedPlayback !== "undefined") {
				let s = [...savedPlayback];
				let saved = false;
	
				s.forEach((item, index) => {
					if(
						item.lesson_resource_id === params.lesson_resource_id &&
						item.library_party_id === params.library_party_id &&
						item.module_resource_id === params.module_resource_id &&
						item.element_id === deleteElementPlayback
					) {
						s.splice(index, 1);
					}
				});
	
				actions.setPlayback(s);
	
				setDeleteElementPlayback(0);
			}
		}
	}, [deleteElementPlayback]);

	const handleSavePlayback = () => {
		let p = {
			lesson_resource_id: params.lesson_resource_id,
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			element_id: playback.element_id,
			playback: playback.seconds
		};

		if(savedPlayback) {
			let s = [...savedPlayback];
			let saved = false;

			s.forEach((item, index) => {
				if(
					item.lesson_resource_id === params.lesson_resource_id &&
					item.library_party_id === params.library_party_id &&
					item.module_resource_id === params.module_resource_id &&
					item.element_id === playback.element_id
				) {
					item.playback = playback.seconds;
					saved = true;
				}
			});

			if(!saved) {
				s.push(p);
			}

			actions.setPlayback(s);
		}
		else {
			actions.setPlayback([p]);
		}
	}

	const suhbeader = useSubheader();
	suhbeader.setTitle(lesson.title || "Lesson");

	if(accessDenied) {
		snackbarAlert("error", "Access Denied");
		return <Redirect to="/" />
	}

	if(!init || !library.title) {
		return <Spinner animation="grow" />
	}

	return <>
		<SubmitLessonQuestion lesson={lesson} openLessonQuestion={openLessonQuestion} setOpenLessonQuestion={setOpenLessonQuestion}  />

		<div className="row" style={{marginTop: "-40px"}}>
			<div className="col-12">
				<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
					<div className="card-body">
						<div className="d-flex justify-content-center">
							<Tabs
								className="mb-3"
								value={recentLessonTab}
								onChange={(e, newValue) => {
									setRecentLessonTab(newValue);
								}}
								indicatorColor="secondary"
								textColor="secondary"
								variant="scrollable"
								// variant="fullWidth"
							>
								<Tab value="Overview" label="Overview" />
								<Tab value="Training Details" label="Training Details" />
								<Tab value="Lesson Details" label="Lesson Details" />
								{library.settings.allow_questions.isAllowed && <Tab value="Q & A" label="Q & A" />}
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		</div>

		{(() => {
			if(recentLessonTab === "Overview") {
				return <div className="row">
					<div className="col-12">
						<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
							<div className="card-header">
								<h3 className="card-title font-weight-bolder">Overview</h3>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-lg-3 col-md-3">
										<img 
											src={library.image_file ? `${process.env.REACT_APP_S3_BUCKET}/library_uploads/images/${library.image_file}` : "https://lernnex.s3.amazonaws.com/library_uploads/images/default_library_image.jpg"}
											alt={library.title}
											className="w-100 rounded"
										/>
									</div>
									<div className="col-lg-9 col-md-3">
										{
											Boolean(library.description) && (
												<div>
													<Typography variant="h6">
														Description: 
													</Typography>

													<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
														{library.description}
													</Typography>
													<br />
												</div>
											)
										}

										{
											Boolean(library.context && library.context.what_will_you_learn) && (
												<div>
													<Typography variant="h6">
														What will you learn?: 
													</Typography>

													<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
														{library.context.what_will_you_learn}
													</Typography>
													<br />
												</div>
											)
										}

										{
											Boolean(library.context && library.context.who_should_take_this) && (
												<div>
													<Typography variant="h6">
														Who should take this?: 
													</Typography>

													<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
														{library.context.who_should_take_this}
													</Typography>
													<br />
												</div>
											)
										}


									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
			else if(recentLessonTab === "Lesson Details") {
				return <>
					<div className="row">
						<div className="col-12">
							<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
								<div className="card-body">

									{(() => elements.map((indiv_element, element_index) => {
										return <div className="mb-5" key={element_index}>
											<div className="border rounded p-3 bg-white shadow">
												{Boolean(indiv_element.description) && (
													<div>
														<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
															{indiv_element.description}
														</Typography> 

														<hr />

													</div>
												)}

												{indiv_element.attachments.map((indiv_attachment, attachment_index) => {

													const submitStudentElementResponse = async (values) => {
														let request_body = {
															library_party_id: params.library_party_id,
															module_resource_id: params.module_resource_id,
															lesson_resource_id: params.lesson_resource_id,
															element_id: indiv_element.id,
															element_attachment_id: indiv_attachment.id,
															video_duration: parseInt(playback.seconds)
														}

														if(values) {
															request_body.student_response = values.student_response;
														}

														if(indiv_element.student_element_progress.is_completed) {
															return { status: null, data: { elements_query: null } };
														}

														if(indiv_element.element_type_id === 8) {
															request_body.attachment_additional_id = values.attachment_additional_id;
															request_body.student_additional_response = values.student_additional_response;
															request_body.explanation = values.explanation;
														}
														let response = await submit_student_element_response(request_body);
														if(response.status == 200) {
															let last_element = response.data.elements_query[response.data.elements_query.length - 1].last_element;

															if(last_element || last_element == true) {
																let next_topic = false;
																let last_topic = false;

																if(params.module_resource_id == "library_lesson") {
																	let current_lesson_index = libraryContent[0].lessons.findIndex(y => y.resource_id == params.lesson_resource_id);

																	next_topic = libraryContent[0].lessons[current_lesson_index + 1];
																	last_topic = libraryContent[0].lessons[current_lesson_index - 1];
																}
																else {
																	let module_index = libraryContent.findIndex(x => x.resource_id == params.module_resource_id);
																	let current_lesson_index = libraryContent[module_index].lessons.findIndex(y => y.resource_id == params.lesson_resource_id);
																	let next_lesson_data = libraryContent[module_index].lessons[current_lesson_index + 1];
																	let last_lesson_data = libraryContent[module_index].lessons[current_lesson_index - 1];
																	let next_module_data = libraryContent[module_index + 1];
																	let last_module_data = libraryContent[module_index - 1];

																	if(next_lesson_data || next_module_data) {
																		next_topic = true
																	}
																	else {
																		next_topic = false;
																	}

																	if(last_lesson_data || last_module_data) {
																		last_topic = true
																	}
																	else {
																		last_topic = false;
																	}
																}

																if(next_topic) { //for proceed button
																	setCanProceed(true)
																}
																else {
																	setCanProceed(false);
																}

																if(last_topic) { //for proceed button
																	setCanBack(true)
																}
																else {
																	setCanBack(false);
																}
															}
														}
														else {
															console.log(response)
														}
														setElementIsSubmitting(false);
														snackbarAlert("success", "Element Completed");
														return response;
													}

													switch(indiv_attachment.attachment_type_id) {
														case 1: 
															return <div key={attachment_index}>
																<ElementImageView attachment={indiv_attachment} />
																<hr />
																
																{Boolean(indiv_element.student_element_progress.is_completed) ? <Typography align='center' className="text-success">
																	Completed
																</Typography> : <button 
																	disabled={elementIsSubmitting}
																	type="button"
																	style={{backgroundColor: "#0269d4"}}
																	className="btn btn-success btn-pill d-block mx-auto px-5"
																	onClick={async () => {
																		setElementIsSubmitting(true);

																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse();

																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																		} catch(err) {
																			console.log(err);
																		}
																	}}
																>
																	Next
																</button>}
															</div>
														case 2: 
															return <div key={attachment_index}>
																<ElementAudioView 
																	submitStudentElementResponse={submitStudentElementResponse} 
																	attachment={indiv_attachment}
																	setElements={setElements} 
																/>

																<hr />
																
																{Boolean(indiv_element.student_element_progress.is_completed) ? <Typography align='center' className="text-success">
																	Completed
																</Typography> : <Typography align='center' className="text-info">
																	Please play the recordings to complete.
																</Typography>}
															</div>
														case 3: 
															return <div key={attachment_index}>
																<ElementDocumentView attachment={indiv_attachment} />
																<hr />
																
																{Boolean(indiv_element.student_element_progress.is_completed) ? <Typography align='center' className="text-success">
																	Completed
																</Typography> : <button 
																	disabled={elementIsSubmitting}
																	type="button"
																	style={{backgroundColor: "#0269d4"}}
																	className="btn btn-success btn-pill d-block mx-auto px-5"
																	onClick={async () => {
																		setElementIsSubmitting(true);

																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse();
																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																		} catch(err) {
																			console.log(err);
																		}
																	}}
																>
																	Next
																</button>}
															</div>
														case 4:
															return <div key={attachment_index}>
																<ElementVideoView
																	submitStudentElementResponse={submitStudentElementResponse}
																	attachment={indiv_attachment}
																	setElements={setElements}
																	setPlayback={setPlayback}
																	setIsPaused={setIsPaused}
																	savedPlayback={savedPlayback}
																	params={params}
																	studentElementProgress={indiv_element.student_element_progress}
																	setDeleteElementPlayback={setDeleteElementPlayback}
																/>

																<hr />

																{
																	Boolean(indiv_element.student_element_progress.is_completed) ? (
																		<Typography align='center' className="text-success">
																			Completed
																		</Typography>
																	)
																	: (
																		<Typography align='center' className="text-info">
																			Please play the video to complete.
																		</Typography>
																	)
																}
															</div>
														case 5: 
															return <div key={attachment_index}>
																<ElementTextView attachment={indiv_attachment} user={user} />
																<hr />
																
																{Boolean(indiv_element.student_element_progress.is_completed) ? <Typography align='center' className="text-success">
																	Completed
																</Typography> : <button 
																	disabled={elementIsSubmitting}
																	style={{backgroundColor: "#0269d4"}}
																	type="button"
																	className="btn btn-success btn-pill d-block mx-auto px-5"
																	onClick={async () => {
																		setElementIsSubmitting(true);

																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse();

																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																			setElementIsSubmitting(false);
																		} catch(err) {
																			console.log(err);
																			setElementIsSubmitting(false);
																		}
																	}}
																>
																	Next
																</button>}
															</div>
														case 6: 
															return <div key={attachment_index}>
																<ElementReflectionView 
																	submitStudentElementResponse={submitStudentElementResponse} 
																	attachment={indiv_attachment}
																	setElements={setElements} 
																	element={indiv_element}
																/>

																{Boolean(indiv_element.student_element_progress.is_completed) ? <>
																	<Typography>
																		<span className="font-weight-bold">Your Response: </span>
																	</Typography>
																	<Typography>
																		{indiv_attachment.student_attachment_response.content}
																	</Typography>

																	{indiv_attachment.context.is_shared === "shared" && <ShowResponses params={params} element={indiv_element} attachment={indiv_attachment} />}

																	<hr />
																	<Typography align='center' className="text-success">
																		Completed
																	</Typography>
																</> : 
																	
																<Formik
																	initialValues={{
																		student_response: "",
																	}}
																	validate={values => {
																		const errors = {};

																		if (!values.student_response) {
																			errors.student_response = intl.formatMessage({
																				id: "AUTH.VALIDATION.REQUIRED_FIELD"
																			});
																		} 

																		return errors;
																	}}
																	onSubmit={async (values, { setSubmitting, resetForm }) => {
																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse(values);

																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																		} catch(err) {
																			console.log(err);
																		}
																	}}
																>
																	{({
																		values,
																		errors,
																		touched,
																		handleChange,
																		handleBlur,
																		handleSubmit,
																		isSubmitting,
																		setFieldValue,
																		handleReset
																	}) => (
																		<form
																			onSubmit={handleSubmit}
																			noValidate
																			autoComplete="off"
																		>
																			<div className="form-group">
																				<TextField
																					label="Your Response"
																					color="secondary"
																					multiline
																					name="student_response"
																					placeholder="Your response could be multiple lines..."
																					margin="normal"
																					variant="outlined"
																					onBlur={handleBlur}
																					onChange={handleChange}
																					value={values.student_response}
																					helperText={touched.student_response && errors.student_response}
																					error={Boolean(touched.student_response && errors.student_response)}
																					InputLabelProps={{
																						shrink: true,
																					}}
																				/>
																			</div>


																			<Typography align='center' className="text-info">
																				<button
																					type="submit"
																					style={{backgroundColor: "#0269d4"}}
																					disabled={isSubmitting}
																					className="btn btn-success"
																				>
																					Submit
																				</button>
																			</Typography>
																		</form>
																	)}
																</Formik>}
															</div>
														case 7: 
															return <div key={attachment_index}>
																<ElementSignatureView attachment={indiv_attachment} />

																{Boolean(indiv_element.student_element_progress.is_completed) ? <>
																	<Typography align='center'>
																		<span className="font-weight-bold">Signed by: </span>
																		{indiv_attachment.student_attachment_response.content}
																	</Typography>

																	<hr />
																	<Typography align='center' className="text-success">
																		Completed
																	</Typography>
																</> : 
																	
																<Formik
																	initialValues={{
																		student_response: "",
																	}}
																	validate={values => {
																		const errors = {};

																		if (!values.student_response) {
																			errors.student_response = intl.formatMessage({
																				id: "AUTH.VALIDATION.INVALID_FIELD"
																			});
																		} else if(values.student_response.toLowerCase() !== (user.firstname + " " + user.lastname).toLowerCase()) {
																			errors.student_response = intl.formatMessage({
																				id: "AUTH.VALIDATION.INVALID_FIELD"
																			});
																		}

																		return errors;
																	}}
																	onSubmit={async (values, { setSubmitting, resetForm }) => {
																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse(values);

																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																		} catch(err) {
																			console.log(err);
																		}
																	}}
																>
																	{({
																		values,
																		errors,
																		touched,
																		handleChange,
																		handleBlur,
																		handleSubmit,
																		isSubmitting,
																		setFieldValue,
																		handleReset
																	}) => (
																		<form
																			onSubmit={handleSubmit}
																			noValidate
																			autoComplete="off"
																		>
																			<div className="form-group">
																				<TextField
																					label="Your Full Name"
																					color="secondary"
																					name="student_response"
																					placeholder="Ex. Juan Dela Cruz"
																					margin="normal"
																					onBlur={handleBlur}
																					onChange={handleChange}
																					value={values.student_response}
																					helperText={touched.student_response && errors.student_response}
																					error={Boolean(touched.student_response && errors.student_response)}
																					InputLabelProps={{
																						shrink: true,
																					}}
																				/>
																			</div>


																			<Typography align='center' className="text-info">
																				<button
																					type="submit"
																					style={{backgroundColor: "#0269d4"}}
																					disabled={isSubmitting}
																					className="btn btn-success"
																				>
																					Submit
																				</button>
																			</Typography>
																		</form>
																	)}
																</Formik>}
															</div>
														case 8: 
															return <div key={attachment_index}>
																<ElementSurveyView attachment={indiv_attachment} />

																{Boolean(indiv_element.student_element_progress.is_completed) ? <>
																	<div className="row">
																		<div className="col-lg-4 m-auto">
																			<Typography>
																				<span className="font-weight-bold">You've chosen: </span>
																				{indiv_attachment.student_attachment_response.content}
																			</Typography>
																			{indiv_attachment.context.require_explanation && <>
																				<Typography>
																					<span className="font-weight-bold">You're explanation: </span>
																					{indiv_attachment.student_attachment_response.remarks}
																				</Typography>
																			</>}
																		</div>
																		
																		<div className="col-lg-8">
																			<DoughnutChart 
																				chartLabel="Survey Data" 
																				dataLabel={indiv_attachment.attachment_additionals.map(indiv_additional => {
																					return indiv_additional.description;
																				})}
																				data={indiv_attachment.attachment_additionals.map(indiv_additional => {
																					return indiv_additional.responses ?? 0;
																				})}
																			/>
																		</div>
																		
																	</div>

																	<hr />

																	<Typography align='center' className="text-success">
																		Completed
																	</Typography>
																</> : 

																<Formik
																	initialValues={{
																		attachment_additional_id: indiv_attachment.id,
																		student_response: "",
																		student_additional_response: "",
																		explanation: "",
																		require_explanation: indiv_attachment.context.require_explanation,
																		step: 1
																	}}
																	validate={values => {
																		const errors = {};

																		if (!values.student_additional_response) {
																			errors.student_additional_response = intl.formatMessage({
																				id: "AUTH.VALIDATION.REQUIRED_FIELD"
																			});
																		}

																		if (values.step == 3 && values.required_explantion && !values.explanation) {
																			errors.explanation = intl.formatMessage({
																				id: "AUTH.VALIDATION.REQUIRED_FIELD"
																			});
																		}

																		return errors;
																	}}
																	onSubmit={async (values, { setSubmitting, resetForm }) => {
																		if(values.step == 3 || !values.require_explanation) {
																			try {
																				const { status, data: { elements_query } } = await submitStudentElementResponse(values);
	
																				if(status === 200) {
																					setElements(elements_query);
																					window.scrollTo(0,document.body.scrollHeight);
																					return;
																				}
	
																			} catch(err) {
																				console.log(err);
																			}
																		}
																	}}
																>
																	{({
																		values,
																		errors,
																		touched,
																		handleChange,
																		handleBlur,
																		handleSubmit,
																		isSubmitting,
																		setFieldValue,
																		handleReset
																	}) => (
																		<form
																			onSubmit={handleSubmit}
																			noValidate
																			autoComplete="off"
																		>
																			<Typography variant="body1" className="font-weight-bolder">
																				Choices:
																			</Typography>
																			
																			<RadioGroup 
																				aria-label="choices" 
																				name="student_additional_response" 
																				className="ml-5"
																				value={values.student_additional_response}
																				onChange={async e => {
																					await setFieldValue("student_additional_response", e.target.value);
																				}}
																			>
																				{indiv_attachment.attachment_additionals.map((indiv_additional) => {
																					return <FormControlLabel 
																						key={indiv_additional.id}
																						disabled={isSubmitting || values.step == 2} 
																						value={indiv_additional.id.toString()} 
																						control={<Radio />} 
																						label={indiv_additional.description} 
																						onClick={() => {
																							setFieldValue("student_response", indiv_additional.description);
																						}}
																					/>
																				})}
																			</RadioGroup>
																			<label style={{color: "#f018a6"}}>{touched.student_additional_response && errors.student_additional_response}</label>

																			{(values.require_explanation && values.step == 2) && (
																				<div className="form-group">
																					<Typography variant="body1" className="font-weight-bolder">
																						{indiv_attachment.context.explanation_description}
																					</Typography>

																					<TextField
																						color="secondary"
																						multiline
																						name="explanation"
																						placeholder="Input your explanation here"
																						margin="normal"
																						variant="outlined"
																						onBlur={handleBlur}
																						onChange={handleChange}
																						value={values.explanation}
																						helperText={touched.explanation && errors.explanation}
																						error={Boolean(touched.explanation && errors.explanation)}
																					/>
																				</div>
																			)}
																			
																			{
																				values.step == 1 ?
																					<Typography align='center' className="text-info">
																						<button
																							type="submit"
																							onClick={() => {

																								if(values.student_additional_response) {
																									setFieldValue("step", 2)
																								}
																							}}
																							style={{backgroundColor: "#0269d4"}}
																							disabled={isSubmitting}
																							className="btn btn-success"
																						>
																							Submit
																						</button>
																					</Typography>
																				:
																					<Typography align='center' className="text-info">
																						<button
																							type="submit"
																							onClick={() => setFieldValue("step", 3)}
																							style={{backgroundColor: "#0269d4"}}
																							disabled={isSubmitting}
																							className="btn btn-success"
																						>
																							Submit
																						</button>
																					</Typography>
																			}
																			
																		</form>
																	)}
																</Formik>}
															</div>
														case 9:
															return <div key={attachment_index}>
																<ElementLinkView attachment={indiv_attachment} />
																<hr />
																
																{Boolean(indiv_element.student_element_progress.is_completed) ? <Typography align='center' className="text-success">
																	Completed
																</Typography> : <button 
																	disabled={elementIsSubmitting}
																	type="button"
																	style={{backgroundColor: "#0269d4"}}
																	className="btn btn-success btn-pill d-block mx-auto px-5"
																	onClick={async () => {
																		setElementIsSubmitting(true);

																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse();
																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																		} catch(err) {
																			console.log(err);
																		}
																	}}
																>
																	Next
																</button>}
															</div>
														case 10:
															return <div key={attachment_index}>
																<ElementDownloadView attachment={indiv_attachment} />
																<hr />
																
																{Boolean(indiv_element.student_element_progress.is_completed) ? <Typography align='center' className="text-success">
																	Completed
																</Typography> : <button 
																	disabled={elementIsSubmitting}
																	type="button"
																	style={{backgroundColor: "#0269d4"}}
																	className="btn btn-success btn-pill d-block mx-auto px-5"
																	onClick={async () => {
																		setElementIsSubmitting(true);

																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse();
																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																		} catch(err) {
																			console.log(err);
																		}
																	}}
																>
																	Next
																</button>}
															</div>
														default:
															return null;
													}
												})}
											</div>
											{(indiv_element.last_element && Object.keys(indiv_element.student_element_progress).length > 0 && indiv_element.is_allowed_questions) && (
												<Formik
													initialValues={{
														hasQuestion: "no",
														question: ""
													}}
													validate={values => {
														const errors = {};

														if (!values.question) {
															errors.question = intl.formatMessage({
																id: "AUTH.VALIDATION.INVALID_FIELD"
															});
														}

														return errors;
													}}
													onSubmit={(values, { setSubmitting, resetForm }) => {
														try {
															//TEMPORARY SOLUTION, LIBRARY_PARTY SHOULD NOT INCLUDED IN PARAMETERS
															let submit_question = submit_student_question(params.lesson_resource_id, values.question, params.library_party_id); 

															submit_question.then(({ data: { message } }) => {
																snackbarAlert("success", message);
																setSubmitting(false);
																resetForm();
															})
															.catch((err) => {
																console.log(err);
																setSubmitting(false);
															})

														} 
														catch(err) {
															console.log(err);
														}
													}}
												>
													{({
														values,
														errors,
														touched,
														handleChange,
														handleBlur,
														handleSubmit,
														isSubmitting,
														setFieldValue,
														handleReset
													}) => (
														<form
															onSubmit={handleSubmit}
															noValidate
															autoComplete="off"
														>
															<div className="border rounded p-3 bg-white shadow mt-5">
																<Typography variant={"h6"} className="mb-5">
																	Q & A
																</Typography>

																<div className="row mx-auto">
																	<label>Do you want to ask question?</label>
																	<RadioGroup
																		name="has_question"
																		row
																		value={values.hasQuestion}
																		className="ml-3 mt-n3"
																		onChange={async e => {
																			await setFieldValue("hasQuestion", e.target.value);
																		}}
																	>
																		<FormControlLabel value="no" control={<Radio />} label="No" />
																		<FormControlLabel value="yes" control={<Radio />} label="Yes" />
																	</RadioGroup>
																</div>
																
																{
																	values.hasQuestion == "yes" && (
																		<div>
																			<div className="form-group">
																				<TextField
																					label="Question"
																					color="secondary"
																					multiline
																					name="question"
																					placeholder="Input your question here"
																					margin="normal"
																					variant="outlined"
																					onBlur={handleBlur}
																					onChange={handleChange}
																					value={values.question}
																					helperText={touched.question && errors.question}
																					error={Boolean(touched.question && errors.question)}
																					InputLabelProps={{
																						shrink: true,
																					}}
																				/>
																			</div>

																			<button
																				type="submit"
																				disabled={isSubmitting}
																				className="btn btn-success d-block ml-auto"
																			>
																				Submit
																			</button>
																		</div>
																	)
																}
															</div>
														</form>
													)}
												</Formik>
											)}



											{(indiv_element.last_element && Object.keys(indiv_element.student_element_progress).length > 0 && canBack) && (
												<div>
													<button
														type="button"
														className="btn btn-success btn-pill d-block mx-auto px-5 mt-5"
														style={{float: "left", backgroundColor: "#0269d4"}}
														onClick= {
															() => {
																if(params.module_resource_id == "library_lesson") {
																	let current_lesson_index = libraryContent[0].lessons.findIndex(y => y.resource_id == params.lesson_resource_id);
																	let next_lesson_id = libraryContent[0].lessons[current_lesson_index - 1].resource_id;
																	history.push(next_lesson_id.toString())
																}
																else {
																	let misc_lesson_index = libraryContent.findIndex(x => x.resource_id == params.module_resource_id);
																	let current_lesson_index = libraryContent[misc_lesson_index].lessons.findIndex(y => y.resource_id == params.lesson_resource_id);
																	let next_lesson_data = libraryContent[misc_lesson_index].lessons[current_lesson_index - 1];
																	let next_module_id = libraryContent[misc_lesson_index - 1];
																	let next_module_lesson_id = libraryContent[misc_lesson_index - 1];
																	
																	if(next_lesson_data) {
																		history.push(next_lesson_data.resource_id.toString());
																	}
																	else {
																		history.push(`/libraries/trainings/${params.library_party_id}/module/${next_module_id.resource_id}/lesson/${next_module_lesson_id.lessons[0].resource_id}`);
																	}
																}
															}
														}
													>
														Back
													</button>
												</div>
											)}

											{(indiv_element.last_element && Object.keys(indiv_element.student_element_progress).length > 0 && canProceed) && (
												<div>
													<button
														type="button"
														className="btn btn-success btn-pill d-block mx-auto px-5 mt-5"
														style={{float: "right", backgroundColor: "#0269d4"}}
														onClick= {
															() => {
																if(params.module_resource_id == "library_lesson") {
																	let current_lesson_index = libraryContent[0].lessons.findIndex(y => y.resource_id == params.lesson_resource_id);
																	let next_lesson_id = libraryContent[0].lessons[current_lesson_index + 1].resource_id;
																	history.push(next_lesson_id.toString())
																}
																else {
																	let misc_lesson_index = libraryContent.findIndex(x => x.resource_id == params.module_resource_id);
																	let current_lesson_index = libraryContent[misc_lesson_index].lessons.findIndex(y => y.resource_id == params.lesson_resource_id);
																	let next_lesson_data = libraryContent[misc_lesson_index].lessons[current_lesson_index + 1];
																	let next_module_id = libraryContent[misc_lesson_index + 1];
																	let next_module_lesson_id = libraryContent[misc_lesson_index + 1];
																	
																	if(next_lesson_data) {
																		history.push(next_lesson_data.resource_id.toString());
																	}
																	else {
																		history.push(`/libraries/trainings/${params.library_party_id}/module/${next_module_id.resource_id}/lesson/${next_module_lesson_id.lessons[0].resource_id}`);
																	}
																}
															}
														}
													>
														Proceed
													</button>
												</div>
											)}
										</div>
									}))()}
								</div>
							</div>
						</div>
					</div>
				</>
			} else if(recentLessonTab === "Training Details") {
				return <div className="row">
					<div className="col-12">
						<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
							<div className="card-header">
								<h3 className="card-title font-weight-bolder">{library.settings.group_content ? "Modules and Lessons" : "Library Lessons"}</h3>
							</div>

							<div className="card-body">
								{(() => {
									if(library.access_denied) {
										return <Typography variant="h4" align="center">
											Please contact your admin to access this library.
										</Typography>
									} else {
										return libraryContent.length ? libraryContent.map((indiv_module, module_index) => {
											if(library.settings.group_content) {
												return (
													<div key={module_index} className="form-group">
														<div className="p-3 bg-secondary d-flex rounded mb-2 align-items-center">
															<Typography className="font-weight-bolder">
																{indiv_module.title}
															</Typography>
														</div>
														{indiv_module.lessons && indiv_module.lessons.length ? indiv_module.lessons.map((indiv_lesson, lesson_index) => {
															return (
																<div key={lesson_index} className="d-flex align-items-center ml-5 mb-2 border-bottom">
																	<div>
																		{!library.access_expired ? <Link 
																			to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																			className="text-primary"
																			onClick={() => setRecentLessonTab("Lesson Details")}
																			style={{fontSize: "medium"}}
																		>
																			{indiv_lesson.title}
																		</Link> : <Typography>
																			{indiv_lesson.title}
																		</Typography>}
																		
																		{Boolean(indiv_lesson.progress_report) && <Typography variant="subtitle2" style={{color: "#75767E"}}>
																			Elements completed {indiv_lesson.progress_report.elements_completed}
																		</Typography>}
																	</div>
																	
																	{!library.access_expired && <Link 
																		to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																		className="text-primary ml-auto mr-3"
																		onClick={() => setRecentLessonTab("Lesson Details")}
																	>
																		View
																	</Link>}
																</div>
															);
														}) : (
															<div className="d-flex ml-5 mb-2 border-bottom">
																No lesson/s yet...
															</div>
														)}
													</div>
												)
											} else {
												return <div key={module_index}>
													{indiv_module.lessons && indiv_module.lessons.length ? indiv_module.lessons.map((indiv_lesson, lesson_index) => {
														return (
															<div key={lesson_index} className="d-flex align-items-center mb-2 border-bottom">
																<div>
																	{!library.access_expired ? <Link 
																		to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																		className="text-primary"
																		onClick={() => setRecentLessonTab("Lesson Details")}
																		style={{fontSize: "medium"}}
																	>
																		{indiv_lesson.title}
																	</Link> : <Typography>
																		{indiv_lesson.title}
																	</Typography>}
																	
																	{Boolean(indiv_lesson.progress_report) && <Typography variant="subtitle2" style={{color: "#75767E"}}>
																		Elements completed {indiv_lesson.progress_report.elements_completed}
																	</Typography>}
																</div>
																
																{!library.access_expired && <Link 
																	to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																	className="text-primary ml-auto mr-3"
																	onClick={() => setRecentLessonTab("Lesson Details")}
																>
																	View
																</Link>}
															</div>
														);
													}) : (
														<div className="d-flex ml-5 mb-2 border-bottom">
															No lesson/s yet...
														</div>
													)}
												</div>
											}
										}) : (
											<div className="text-center">
												No content yet...
											</div>
										)
									}
								})()}
							</div>
						</div>
					</div>
				</div>
			} else if(recentLessonTab === "Q & A") {
				return <>
					<div className="row">
						<div className="col-12">
							<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
								<div className="card-body">
									{(() => 
										Boolean(faqs.length) ? 
											faqs.map((indiv_faq, faq_index) => {
												return <div className="mb-5" key={faq_index}>
													<Accordion>
														<AccordionSummary
															expandIcon={<ExpandMoreIcon />}
															aria-controls="panel1a-content"
															id="panel1a-header"
														>
															<Avatar 
																alt="Avatar Image" 
																src={indiv_faq.account.persons[0].image_file
																	? `${process.env.REACT_APP_S3_BUCKET}/user_uploads/images/${indiv_faq.account.persons[0].image_file}`
																	: "https://lernnex.s3.amazonaws.com/user_profile_images/default_user_image.png"}  
															/>
															<div style={{paddingLeft: "5px"}}>
																<Typography>{indiv_faq.question}</Typography>
																<small style={{color: "#75767E"}}>Asked by {indiv_faq.account.name} last {moment(indiv_faq.updated_at).format('MMM DD, YYYY')}</small>
															</div>
														</AccordionSummary>

														<AccordionDetails style={{display: "block", paddingLeft: "60px"}}>
															{indiv_faq.faq_answers.map((indiv_answer, answer_index) => {
																return <div key={answer_index} style={{display: "flex"}}>
																	<Avatar style={{backgroundColor: avatarBgColor[Math.floor(Math.random()*avatarBgColor.length)]}}>
																		{indiv_answer.answered_by.first_name.charAt(0)}
																	</Avatar>
																	<div style={{paddingLeft: "5px"}}>
																		<Typography style={{fontWeight: "bold"}}>
																			{indiv_answer.answered_by.first_name + " " + indiv_answer.answered_by.last_name}:
																		</Typography>
																		<Typography>
																			{indiv_answer.answer}
																		</Typography>
																	</div>
																	<hr />
																</div>
															})}
														</AccordionDetails>
													</Accordion>
												</div>
											})
										: <div style={{textAlign: "center"}}>
											{!showQuestionField && <>
													<img style={{width: "20%"}} src={toAbsoluteUrl("/media/misc/no_questions_icon.png")} alt="question mark icon" />
													<Typography variant="body1" className="form-group">
														Be the first person to &nbsp;
															<a tabIndex={0} style={{color: "#3699FF"}} onClick={() => {setShowQuestionField(true)}}>ask a question</a>
														&nbsp; and help your peers.
													</Typography>
												</>}
										  </div>
									)()}

									<Formik
										initialValues={{
											question: ""
										}}
										validate={values => {
											const errors = {};

											if (!values.question) {
												errors.question = intl.formatMessage({
													id: "AUTH.VALIDATION.INVALID_FIELD"
												});
											}

											return errors;
										}}
										onSubmit={(values, { setSubmitting, resetForm }) => {
											try {
												//TEMPORARY SOLUTION, LIBRARY_PARTY SHOULD NOT INCLUDED IN PARAMETERS
												let submit_question = submit_student_question(params.lesson_resource_id, values.question, params.library_party_id); 

												submit_question.then(({ data: { message, faq_query } }) => {
													snackbarAlert("success", message);
													setSubmitting(false);
													setShowQuestionField(false)

													if(faq_query) {
														setFaqs(faq_query);
													}

													resetForm();
												})
												.catch((err) => {
													snackbarAlert("error", err.response.data.message);
													console.log(err);
													setSubmitting(false);
												})

											} 
											catch(err) {
												console.log(err);
											}
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
											handleReset
										}) => (
											<form
												onSubmit={handleSubmit}
												noValidate
												autoComplete="off"
											>
												{showQuestionField && <div className="border rounded p-3 bg-white shadow mt-5" style={{width: "50%", margin: "auto"}}>
													<Typography variant={"h6"} className="mb-5">
														Q & A

														<IconButton aria-label="Close" style={{float: "right", padding: 0, color: "red"}} onClick={() => {setShowQuestionField(false)}}>
															<CloseIcon />
														</IconButton>
													</Typography>

													<div>
														<div className="form-group">
															<TextField
																label="Question"
																color="secondary"
																multiline
																rows={5}
																name="question"
																placeholder="Input your question here"
																margin="normal"
																variant="outlined"
																onBlur={handleBlur}
																onChange={handleChange}
																value={values.question}
																helperText={touched.question && errors.question}
																error={Boolean(touched.question && errors.question)}
																InputLabelProps={{
																	shrink: true,
																}}
															/>
														</div>

														<button
															type="submit"
															disabled={isSubmitting}
															className="btn btn-success d-block ml-auto"
														>
															Submit
														</button>
													</div>
												</div>}
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</>
			} else {
				setRecentLessonTab("Lesson Details");
			}
		})()}
	</>
})

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators(
			Object.assign(currentValues.actions),
			dispatch
		)
	};
};

export default connect(null, mapDispatchToProps)(StudentLessonDetails);