import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import {
	makeStyles, 
	Card,
	CardMedia,
	CardHeader,
	CardContent,
    Typography,
    Tabs, 
	Tab,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from "@material-ui/core";
import { Spinner } from "react-bootstrap";
import SetupAccount from "./component/SetupAccountModal"
import { get_mentor_libraries_list } from "../../_redux/LibrariesCrud";

const useStyles = makeStyles({
	libraryCard: {
        minHeight: "50vh",
    }
});


export function MentorLibrariesList({ params }) {
    const [init, setInit] = useState(false);
    const [librariesList, setLibrariesList] = useState([]);
    const [librariesListTab, setLibrariesListTab] = useState(1);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState(0);
    const [subCategoryFilter, setSubCategoryFilter] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        setInit(false);
        get_mentor_libraries_list(params.library_type).then(({ data: { libraries_query, categories_query } }) => {
            if(libraries_query) {
                setLibrariesList(libraries_query);
            }

            if(categories_query) {
                setCategories(categories_query);
            }
        }).catch(err => console.log(err)).finally(() => setInit(true));

        return () => {
            setCategoryFilter(0);
            setSubCategoryFilter(0);
        };
    }, [params.library_type]);

    if(!init) {
        return (
            <Spinner animation="grow" />
        )
    }

    return (
        <>
            <div className="row">
				<div className="col-12">
					<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
                        <div className="card-header">
							<h3 className="card-title font-weight-bolder">{params.library_type.charAt(0).toUpperCase() + params.library_type.slice(1)}</h3>
						</div>
                        
						<div className="card-body">
                            <SetupAccount />
                            
                            <div className="d-flex justify-content-center">
                                <Tabs
                                    className="mb-5"
                                    value={librariesListTab}
                                    onChange={(e, newValue) => {
                                        setLibrariesListTab(newValue);
                                    }}
                                    indicatorColor="secondary"
                                    textColor="secondary"
                                    variant="scrollable"
                                    // variant="fullWidth"
                                >
                                    <Tab value={0} label="Draft" />
                                    <Tab value={1} label="Published" />
                                </Tabs>
                            </div>

                            <div className="form-group d-flex">
                                <FormControl fullWidth>
                                    <InputLabel color="secondary">Category Filter</InputLabel>
                                    <Select
                                        color="secondary"
                                        className="w-100 mb-3"
                                        value={categoryFilter}
                                        onChange={e => {
                                            setCategoryFilter(e.target.value);
                                            if(!e.target.value) {
                                                setSubCategoryFilter(0);
                                                setSubCategories([]);
                                            } else {
                                                setSubCategories(categories.find(indiv_category => indiv_category.party_id === e.target.value).sub_categories);
                                            }
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            No Filter
                                        </MenuItem>
                                        {categories.map((indiv_category) => {
                                            return <MenuItem key={indiv_category.party_id} value={indiv_category.party_id}>
                                                {indiv_category.name}
                                            </MenuItem>
                                        })}
                                    </Select>
                                </FormControl> 
                                

                                {Boolean(categoryFilter) && <FormControl fullWidth className="ml-5">
                                    <InputLabel color="secondary">Sub-Category Filter</InputLabel>
                                    <Select
                                        color="secondary"
                                        className="w-100 mb-3"
                                        value={subCategoryFilter}
                                        onChange={e => {
                                            setSubCategoryFilter(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            No Filter
                                        </MenuItem>
                                        {subCategories.map((indiv_sub_category) => {
                                            return <MenuItem key={indiv_sub_category.party_id} value={indiv_sub_category.party_id}>
                                                {indiv_sub_category.name}
                                            </MenuItem>
                                        })}
                                    </Select>
                                </FormControl>}
                            </div>
                            
                            <div className="row">
                                {librariesList.length ? librariesList.filter((indiv_library) => Boolean(indiv_library.isPublished) === Boolean(librariesListTab)).map((indiv_library, library_index) => {
                                    if(categoryFilter && categoryFilter !== indiv_library.categories[0].party_id) return null;

                                    if(subCategoryFilter && subCategoryFilter !== indiv_library.sub_categories[0].party_id) return null;

                                    return (
                                        <div key={library_index} className="col-lg-4 col-sm-12">
                                            <Card className={`${classes.libraryCard} gutter-b`}>
                                                <CardMedia
                                                    style={{ minHeight: "35vh" }}
                                                    component="img"
                                                    className="p-2 border rounded"
                                                    image={indiv_library.image_file ? `${process.env.REACT_APP_S3_BUCKET}/library_uploads/images/${indiv_library.image_file}` : "https://lernnex.s3.amazonaws.com/library_uploads/images/default_library_image.jpg"}
                                                    title={indiv_library.title}
                                                />
                                                <Link
                                                    to={`/libraries/${params.library_type}/${indiv_library.party_id}`}
                                                >
                                                    <CardHeader style={{ minHeight: "8vh" }} title={indiv_library.title} className="text-center mb-0" />
                                                </Link>

                                                
                                                {Boolean(indiv_library.settings.publish_in_olern.publish_in_olern_cost) && <CardHeader 
                                                    className="pt-2 pb-0"
                                                    title={
                                                        <div className="w-100 mx-auto px-4">
                                                            <Typography
                                                                style={{ backgroundColor: "#ffff6b" }}
                                                                variant="body1" 
                                                                className="text-center rounded py-2"
                                                            >
                                                                <strong>
                                                                    {
                                                                        parseInt(indiv_library.settings.publish_in_olern.publish_in_olern_discount) ? (
                                                                            <span>
                                                                                Php 
                                                                                &nbsp;
                                                                                <del>
                                                                                    {parseFloat(indiv_library.settings.publish_in_olern.publish_in_olern_cost).toFixed(2)}
                                                                                </del>
                                                                                &nbsp;
                                                                                <span className="text-danger">
                                                                                    {parseFloat(indiv_library.settings.publish_in_olern.publish_in_olern_discount).toFixed(2)}
                                                                                </span>
                                                                            </span>
                                                                        ) : (
                                                                            <span>
                                                                                Php {parseFloat(indiv_library.settings.publish_in_olern.publish_in_olern_cost).toFixed(2)}
                                                                            </span>
                                                                        )
                                                                    }
                                                                </strong>
                                                            </Typography>
                                                        </div>
                                                    }
                                                />}

                                                <CardContent>
                                                    {Boolean((new Date(indiv_library.settings.availability_date)) > (new Date())) && <Typography>
                                                        <b>Release Date: </b>
                                                        {
                                                            (new Date(indiv_library.settings.availability_date)).toLocaleDateString(undefined, { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' })
                                                        }
                                                    </Typography>}
                                                    <Typography>
                                                        <strong>Category: </strong> {indiv_library.categories[0] && indiv_library.categories[0].name}
                                                    </Typography>

                                                    <Typography>
                                                        <strong>Sub-Category: </strong> {indiv_library.sub_categories[0] && indiv_library.sub_categories[0].name}
                                                    </Typography>
                                                    
                                                    {Boolean(indiv_library.settings.course_author.fullname) && <Typography>
                                                        <strong>Author: </strong> {indiv_library.settings.course_author.fullname}
                                                    </Typography>}

                                                    <Typography>
                                                        <strong>Status: </strong> {indiv_library.isPublished ? "Published" : "Draft"}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    )
                                }) : <p className="text-center mt-5 w-100">No {params.library_type} yet...</p>}
                            </div>
						</div>
					</div>
				</div>
			</div>
        </>
    )
}
