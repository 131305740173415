import React from "react";
import { Doughnut } from "react-chartjs-2";

export function DoughnutChart ({ chartLabel, dataLabel, data, ...props }) {

	let labelContainer = [];

	dataLabel.forEach((indiv_label, index) => {
		let percentage = (data[index] / data.reduce((a, b) => a + b, 0)) * 100;
		indiv_label = indiv_label + ` (${data[index]}) - ` + percentage.toFixed(2) + " %";
		labelContainer.push(indiv_label);
	})


	const doughnutData = {
		labels: labelContainer,
		datasets: [
			{
				label: chartLabel,
				backgroundColor: [
					"#46BFBD",
					"#FDB45C",
					"#F7464A",
					"#949FB1",
					"#4D5360"
				],
				hoverBackgroundColor: [
					"#46BFBD",
					"#FDB45C",
					"#F7464A",
					"#949FB1",
					"#4D5360"
				],
				data: data
			}
		]
	};

	return (
		<div>
			{
				data.reduce((a, b) => a + b, 0) ? (
					<Doughnut
						data={doughnutData}
						options={{
							title: {
								display: true,
								text: chartLabel,
								fontSize: 20,
								responsive: true
							},
							legend: {
								text: "hello",
								display: true,
								position: "left",
								fullWidth: true
							},

							animation: {
								animateScale: true,
								animateRotate: true
							},

							tooltips: {
								callbacks: {
									label: function(tooltipItem, data) {
										//get the concerned dataset
										var dataset = data.datasets[tooltipItem.datasetIndex];
										//calculate the total of this data set
										var total = dataset.data.reduce(function(
											previousValue,
											currentValue,
											currentIndex,
											array
										) {
											return previousValue + currentValue;
										});
										//get the current items value
										var currentValue = dataset.data[tooltipItem.index];
										//calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
										var percentage = Math.floor((currentValue / total) * 100 + 0.5);

										return percentage + "%";
									}
								}
							}
						}}
					/>
				) : (
					<p className="text-center my-3">[No {chartLabel} Assigned Yet]</p>
				)
			}
			
		</div>
	);
};
