import axios from "axios";
import { get } from "object-path";

export function login(username, password) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { username, password });
}
export function emailVerification(email) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/email-verification`, { email });
}

export function register(values) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/sign-up`, values);
}

export function requestPassword(email) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(`${process.env.REACT_APP_API_URL}/auth/user`);
}

export function update_user(values) {
  let dataPost = new FormData();

	for (const [key, value] of Object.entries(values)) {
		dataPost.append(key, value);
	}

  return axios.post(`${process.env.REACT_APP_API_URL}/auth/update_user`, dataPost);
}

export function update_user_password(values) {
  let dataPost = new FormData();

	for (const [key, value] of Object.entries(values)) {
		dataPost.append(key, value);
	}

  return axios.post(`${process.env.REACT_APP_API_URL}/auth/update_user_password`, dataPost);
}

export function update_user_image(image_file) {
  let dataPost = new FormData();

	dataPost.append("image_file", image_file);

  return axios.post(`${process.env.REACT_APP_API_URL}/auth/update_user_image`, dataPost);
}

export function update_user_name(firstname, lastname) {
  let dataPost = new FormData();

	dataPost.append("firstname", firstname);
	dataPost.append("lastname", lastname);

  return axios.post(`${process.env.REACT_APP_API_URL}/auth/update_user_name`, dataPost);
}