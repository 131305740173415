import React, { useState, useEffect } from "react";
import { CircularProgress } from '@material-ui/core';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useSnackbar } from "notistack";

import { get_element_attachment_by_code } from '../Libraries/_redux/LibrariesCrud';
import { isEmpty } from "../../modules/Functions";
import * as temp from "../../../redux/ducks/temp.duck";
import { EnterPin } from "../../components/QuickAccess";
import { ResultsSurveyView, ResultsReflectionView } from "../../components/Elements";

const QuickAccessResultsPage = ({ quickAccessPin, actions, ...props }) => {
	const [attachment, setAttachment] = useState({});
	const [isSubmitting, setSubmitting] = useState(false);
	const [isValidPin, setIsValidPin] = useState(isEmpty(quickAccessPin) ? false : true);
	const { enqueueSnackbar } = useSnackbar();
	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	useEffect(() => {
		if(isValidPin) {
			setSubmitting(true);

			get_element_attachment_by_code(quickAccessPin).then((response) => {
				if(response.data.init[0].status == "error") {
					snackbarAlert('error', response.data.init[0].message);
				}
				else {
					setAttachment(response.data.data[0]);
				}

				setSubmitting(false);
			});
		}
	}, []);

	const Element = () => {
		switch(attachment?.attachment_type_id) {
			case 5: // text
				return (
					<></>
				)
				break;
			case 6: // reflection
				return (
					<ResultsReflectionView attachment={attachment} />
				)
				break;
			case 8: // survey
				return (
					<ResultsSurveyView attachment={attachment} />
				)
				break;

			default:
				return (
					<></>
				)
		}
	}

	return (<>
		{
			isValidPin ? (
				<div className="quick-access-container">
					<EnterPin
						snackbarAlert={snackbarAlert}
						setAttachment={setAttachment}
						setIsValidPin={setIsValidPin}
						actions={actions}
						setSubmitting={setSubmitting}
						setCustomizedText={() => {}}
						isRow={true}
					/>

					<div className="quick-access-content">
						{
							isSubmitting ? (
								<div className="quick-access-loading-container">
									<CircularProgress color="secondary" />
								</div>
							)
							: (
								<Element />
							)
						}
					</div>
				</div>
			)
			: (
				<center>
					<EnterPin
						snackbarAlert={snackbarAlert}
						setAttachment={setAttachment}
						setIsValidPin={setIsValidPin}
						actions={actions}
						setSubmitting={setSubmitting}
						setCustomizedText={() => {}}
					/>
				</center>
			)
		}
	</>);
}

const mapStateToProps = (state, ownProps = {}) => {
	let data = {
		quickAccessPin: state?.tempData?.pin,
	}

	return data;
}

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators(
			Object.assign(temp.actions),
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickAccessResultsPage);