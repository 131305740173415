import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';


import { StudentLibraryDetails } from "./StudentLibraryDetails";
import { MentorLibraryDetails } from "./MentorLibraryDetails";
import { OwnerLibraryDetails } from "./OwnerLibraryDetails";

export const LibraryDetails = ({ currentRole, match: { params } }) => {

    switch(currentRole.id) {
        case 1:
            return <OwnerLibraryDetails params={params} />
        case 2:
            return <StudentLibraryDetails params={params} />
        case 3:
            return <MentorLibraryDetails params={params} />

        default: 
            return <Redirect to="/" />
    }
}

const mapStateToProps = ({ currentValues: { currentRole } }) => ({ currentRole })

export default connect(mapStateToProps)(LibraryDetails)
