import React, { useEffect, useState } from 'react'
import { Link, Redirect, Prompt } from 'react-router-dom';
import SVG from "react-inlinesvg";
import {
	Tabs, 
	Tab,
	Typography,
	FormControlLabel,
	Switch,
	Avatar
} from "@material-ui/core";

import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import moment from 'moment';

import ChipInput from 'material-ui-chip-input';
import { useSnackbar } from "notistack";

import { Formik } from "formik";

import { useSubheader } from '../../../../../_metronic/layout';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import {
	get_owner_lesson_details, 
	get_owner_lesson_content, 
	get_owner_library_content, 
	get_owner_library_details,
	get_owner_faq_details,
	sort_owner_element,
	sort_owner_module,
	sort_owner_lesson,
	update_owner_library_settings
} from '../../_redux/LibrariesCrud';

import { CreateOwnerElement, DeleteOwnerElement, ImportOwnnerElement } from './components/LessonContentModals';
import { 
	ElementDocumentCreate, 
	ElementAudioCreate, 
	ElementAudioView, 
	ElementImageCreate, 
	ElementImageView, 
	ElementDocumentView,
	ElementVideoCreate,
	ElementVideoView,
	ElementTextCreate,
	ElementTextView,
	ElementReflectionCreate,
	ElementReflectionView,
	ElementSignatureCreate,
	ElementSignatureView,
	ElementSurveyCreate,
	ElementSurveyView,
	ElementImageEdit,
	ElementAudioEdit,
	ElementDocumentEdit,
	ElementVideoEdit,
	ElementTextEdit,
	ElementReflectionEdit,
	ElementSignatureEdit,
	ElementSurveyEdit,
	ElementLinkCreate,
	ElementLinkEdit,
	ElementLinkView,
	ElementDownloadCreate,
	ElementDownloadEdit,
	ElementDownloadView
} from './components/OwnerLessonElements';

import { 
	CreateOwnerModule, 
	CreateOwnerLibraryLesson, 
	CreateOwnerModuleLesson, 
	EditOwnerModule, 
	EditOwnerLesson,
	DeleteOwnerModule,
	DeleteOwnerLesson,
} from '../library-details/components/LibraryDetailsModals';

import { Spinner } from 'react-bootstrap';

import axios from "axios";

export const OwnerLessonDetails = ({ params }) => {
	const [init, setInit] = useState(false);
	const [lesson, setLesson] = useState({});
	const [elements, setElements] = useState([]);
	const [recentLessonTab, setRecentLessonTab] = useState("Lesson Details");
	const [accessDenied, setAccessDenied] = useState(false);
	const [library, setLibrary] = useState({});
	const [libraryContent, setLibraryContent] = useState([]);
	const [selectedElement, setSelectedElement] = useState(null);
	const [editElementId, setEditElementId] = useState(0);
	const [disableSort, setDisableSort] = useState(false);
	const [faqs, setFaqs] = useState([]);
	const [videoUploadProgress, setVideoUploadProgress] = useState({});
	const [videoTranscodingDetails, setVideoTranscodingDetails] = useState([]);
	const [videoTranscodingState, setVideoTranscodingState] = useState({});
	const [avatarBgColor, setAvatarBgColor] = useState(["#000000","#FF0000","#00FF00","#0000FF","#FF00FF","#800080","#800000","#808000"]);
	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	const transcodingApi = axios.create({
		baseURL: "https://api.vimeo.com/videos/"
	})
	
	const transcodingFunc = (end_point, element_index) => {
		const transcodingProgressTimer = setInterval(() => {
			let data = {...videoTranscodingState}

			if(videoTranscodingState["transcoding_state_" + element_index].progress < 100) {
				transcodingApi.get(end_point + "/status", { headers: { "Authorization": `Bearer ${process.env.REACT_APP_VIMEO_ACCESS}` } })
					.then((response) => {
						if(data["transcoding_state_" + element_index].progress != 0 && response.data.progress != 100) {
							//Interval status changed to starting only if progress starts from 0 to 100 instead of jumping from 0 to 100
							data["transcoding_state_" + element_index].interval_status = "starting"
						}

						data["transcoding_state_" + element_index].progress = response.data.progress
						setVideoTranscodingState(data)
					})
					.catch(err => {
						console.log(err)
						clearInterval(transcodingProgressTimer)
					});
			}
			else {
				if(data["transcoding_state_" + element_index].interval_status == "starting") {
					snackbarAlert("success", "While we are uploading your video, you can proceed with the rest of your activities.");
				}

				data["transcoding_state_" + element_index].interval_status = "finished"
				data["transcoding_state_" + element_index].embedded = true
				setVideoTranscodingState(data)

				// let tempElements = [...elements];
				// let origElements = [...elements];

				// if(tempElements.length > 0 && origElements.length > 0) {
				// 	tempElements.splice(element_index, 1);
				// 	setElements(tempElements);
				// 	setElements(origElements);
				// }

				clearInterval(transcodingProgressTimer)

			}
		}, 5000)
	}

	useEffect(() => {
		const unloadCallback = (event) => {
			if(Object.keys(videoUploadProgress).length > 0) {
				event.preventDefault(); // for Firefox
				event.returnValue = ''; // for Chrome
				return '';
			}
		};
	  
		window.addEventListener("beforeunload", unloadCallback);
		return () => {
			window.removeEventListener("beforeunload", unloadCallback)
		};
	}, [videoUploadProgress]);

	useEffect(() => {
		videoTranscodingDetails.map((value, index) => {
			if(videoTranscodingState["transcoding_state_" + value.transcoding_details.element_index].interval_status == "pending") {
				transcodingFunc(value.transcoding_details.end_point, value.transcoding_details.element_index)
			}
		})
	}, [videoTranscodingDetails])

	useEffect(() => {
		setInit(false);
		get_owner_lesson_details(params.library_party_id, params.module_resource_id, params.lesson_resource_id).then(({ data: { lesson_query } }) => {
			if(lesson_query) {
				setLesson(lesson_query);
			}
		}).catch((err) => {
			console.log(err);
			setAccessDenied(true);
		});

		get_owner_lesson_content(params.library_party_id, params.module_resource_id, params.lesson_resource_id).then(({ data: { elements_query } }) => {
			if(elements_query) {
				elements_query.map((value, index) => {
					if(value.element_type_id == 4 && value.attachments.length > 0) {
						const uri = value.attachments[0]?.context?.video_link?.split("/");

						setVideoTranscodingDetails(prevState => ([
							...prevState,
							{["transcoding_details"]: {
								end_point: uri[3],
								element_index: index
							}}
						]))

						const data = videoTranscodingState;
						data["transcoding_state_" + index] = {
							interval_status: "pending",
							progress: 0
						}

						setVideoTranscodingState(data)
					}
				})

				setElements(elements_query);
			}
			setInit(true);
		}).catch(err => console.log(err));

		get_owner_library_details(params.library_party_id).then(({ data: { library_query } }) => {
			if(library_query) {
				setLibrary(library_query);
			}
		}).catch(err => {
			console.log(err);
			setAccessDenied(true);
		});

		get_owner_library_content(params.library_party_id).then(({ data: { library_content_query } }) => {
			if(library_content_query) {
				setLibraryContent(library_content_query);
			}
		}).catch(err => console.log(err))

		get_owner_faq_details(params.lesson_resource_id).then(({ data: { faqs_query } }) => {
			if(faqs_query) {
				setFaqs(faqs_query);
			}
		}).catch(err => console.log(err))

	}, [params.library_party_id, params.module_resource_id, params.lesson_resource_id]);

	const suhbeader = useSubheader();
	suhbeader.setTitle(lesson.title || "Lesson");

	if(accessDenied) {
        snackbarAlert("error", "Access Denied");
		return <Redirect to="/" />
	}

	if(!init) {
		return <Spinner animation="grow" />
	}

	return <>
		<Prompt
			when={Object.keys(videoUploadProgress).length > 0 ? true : false}
			message={location => "Changes that you made may not be saved"}
		/>

		<div className="row">
			<div className="col-12">
				<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
					<div className="card-body">
						<div className="d-flex justify-content-center">
							<Tabs
								className="mb-3"
								value={recentLessonTab}
								onChange={(e, newValue) => {
									setRecentLessonTab(newValue);
								}}
								indicatorColor="secondary"
								textColor="secondary"
								variant="scrollable"
								// variant="fullWidth"
							>
								<Tab value="Overview" label="Overview" />
								<Tab value="Training Details" label="Training Details" />
								<Tab value="Lesson Details" label="Lesson Details" />
								<Tab value="Q & A" label="Q & A" />
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		</div>

		{(() => {
			switch(recentLessonTab) {
				case "Lesson Details":
					return <>
						<div className="row">
							<div className="col-12">
								<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
									<div className="card-body">
										{(() => elements.map((indiv_element, element_index) => {
											return <div className="mb-5" key={element_index}>
												<div className="border rounded p-3 shadow" style={selectedElement === element_index ? { backgroundColor: "gainsboro" } : { backgroundColor: "white" }}>
													{Boolean(indiv_element.description) && (
														<div>
															<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
																{indiv_element.description}
															</Typography> 

															<hr />

														</div>
													)}

													{indiv_element.attachments.map((indiv_attachment, attachment_index) => {
														if(editElementId == indiv_element.id) {
															switch(indiv_attachment.attachment_type_id) {
																case 1:
																	return <ElementImageEdit params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setEditElementId={setEditElementId}/>
																case 2:
																	return <ElementAudioEdit params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setEditElementId={setEditElementId}/>
																case 3:
																	return <ElementDocumentEdit params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setEditElementId={setEditElementId}/>
																case 4:
																	return (
																	<ElementVideoEdit
																		params={params}
																		element={indiv_element}
																		element_index={element_index}
																		elements={elements}
																		setElements={setElements}
																		setEditElementId={setEditElementId}
																		library={library}
																		lesson={lesson}
																		setSelectedElement={setSelectedElement}
																		videoUploadProgress={videoUploadProgress}
																		setVideoUploadProgress={setVideoUploadProgress}
																		transcodingFunc={transcodingFunc}
																		videoTranscodingDetails={videoTranscodingDetails}
																		setVideoTranscodingDetails={setVideoTranscodingDetails}
																		videoTranscodingState={videoTranscodingState}
																		setVideoTranscodingState={setVideoTranscodingState}
																	/>
																	);
																case 5:
																	return(
																		<ElementTextEdit
																			key={indiv_element.id}
																			params={params}
																			element={indiv_element}
																			element_index={element_index}
																			elements={elements}
																			setElements={setElements}
																			setEditElementId={setEditElementId}
																			isCreate={false}
																		/>
																	)
																case 6:
																	return <ElementReflectionEdit params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setEditElementId={setEditElementId}/>
																case 7:
																	return <ElementSignatureEdit params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setEditElementId={setEditElementId}/>
																case 8:
																	return <ElementSurveyEdit params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setEditElementId={setEditElementId}/>
																case 9:
																	return <ElementLinkEdit params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setEditElementId={setEditElementId}/>
																case 10:
																	return <ElementDownloadEdit params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setEditElementId={setEditElementId}/>
																default:
																	return null;
															}
														}
														else {
															switch(indiv_attachment.attachment_type_id) {
																case 1: 
																	return <ElementImageView key={attachment_index} attachment={indiv_attachment} />
																case 2: 
																	return <ElementAudioView key={attachment_index} attachment={indiv_attachment} />
																case 3: 
																	return <ElementDocumentView key={attachment_index} attachment={indiv_attachment} />
																case 4: 
																	return <ElementVideoView key={attachment_index} attachment={indiv_attachment} videoTranscodingState={videoTranscodingState} setVideoTranscodingState={setVideoTranscodingState} elements={elements} setElements={setElements} element_index={element_index} />
																case 5: 
																	return <ElementTextView
																		key={attachment_index}
																		attachment={indiv_attachment}
																	/>
																case 6: 
																	return <ElementReflectionView params={params} element={indiv_element} key={attachment_index} attachment={indiv_attachment} />
																case 7: 
																	return <ElementSignatureView key={attachment_index} attachment={indiv_attachment} />
																case 8: 
																	return <ElementSurveyView key={attachment_index} attachment={indiv_attachment} />
																case 9: 
																	return <ElementLinkView key={attachment_index} attachment={indiv_attachment} />
																case 10: 
																	return <ElementDownloadView key={attachment_index} attachment={indiv_attachment} />
																default:
																	return null;
															}
														}
													})}

													{!indiv_element.attachments.length ? (() => {
														switch(indiv_element.element_type_id) {
															case 1: 
																return <ElementImageCreate params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setSelectedElement={setSelectedElement} />
															case 2: 
																return <ElementAudioCreate params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setSelectedElement={setSelectedElement} />
															case 3: 
																return <ElementDocumentCreate params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setSelectedElement={setSelectedElement} />
															case 4: 
																return <ElementVideoCreate params={params} library={library} lesson={lesson} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setSelectedElement={setSelectedElement} videoUploadProgress={videoUploadProgress} setVideoUploadProgress={setVideoUploadProgress} transcodingFunc={transcodingFunc} videoTranscodingDetails={videoTranscodingDetails} setVideoTranscodingDetails={setVideoTranscodingDetails} videoTranscodingState={videoTranscodingState} setVideoTranscodingState={setVideoTranscodingState} />
															case 5: 
																return <ElementTextEdit
																	params={params}
																	element={indiv_element}
																	element_index={element_index}
																	elements={elements}
																	setElements={setElements}
																	setSelectedElement={setSelectedElement}
																	isCreate={true}
																/>
															case 6: 
																return <ElementReflectionCreate params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setSelectedElement={setSelectedElement} />
															case 7: 
																return <ElementSignatureCreate params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setSelectedElement={setSelectedElement} />
															case 8: 
																return <ElementSurveyCreate params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setSelectedElement={setSelectedElement} />
															case 9:
																return <ElementLinkCreate params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setSelectedElement={setSelectedElement} />
															case 10:
																return <ElementDownloadCreate params={params} element={indiv_element} element_index={element_index} elements={elements} setElements={setElements} setSelectedElement={setSelectedElement} />
															default: 
																return null;
														}
													})() : <div className="mt-4 pt-4 border-top d-flex align-items-center">
														<button 
															disabled={disableSort || !element_index}
															className="btn p-0 mx-3 text-center"
															onClick={async () => {
																setDisableSort(true);
																await sort_owner_element(indiv_element.id, { 
																	library_party_id: params.library_party_id,
																	module_resource_id: params.module_resource_id,
																	lesson_resource_id: params.lesson_resource_id,
																	element_index: element_index,
																	sort: element_index - 1
																}).then(({ data: { elements_query } }) => {
																	if(elements_query) {
																		setElements(elements_query);
																		snackbarAlert("success", "Element Moved Up");
																	}
																	setDisableSort(false);
																}).catch(err => {
																	console.log(err)
																	setDisableSort(false);
																	snackbarAlert("error", "Something went wrong!");
																});
															}}
														>
															<span className={`btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || !element_index ? "muted" : "primary"}`}>
																<SVG
																	src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-up.svg")}
																/>
															</span>
															<br />
															<span>Move Up</span>
														</button>

														<button 
															disabled={disableSort || element_index === elements.length - 1}
															className="btn p-0 mx-3 text-center"
															onClick={async () => {
																setDisableSort(true);
																await sort_owner_element(indiv_element.id, { 
																	library_party_id: params.library_party_id,
																	module_resource_id: params.module_resource_id,
																	lesson_resource_id: params.lesson_resource_id,
																	element_index: element_index,
																	sort: element_index + 1
																}).then(({ data: { elements_query } }) => {
																	if(elements_query) {
																		setElements(elements_query);
																		snackbarAlert("success", "Element Moved Down");
																	}
																	setDisableSort(false);
																}).catch(err => {
																	console.log(err)
																	setDisableSort(false);
																	snackbarAlert("error", "Something went wrong!");
																});
															}}
														>
															<span className={`btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || element_index === elements.length - 1 ? "muted" : "primary"}`}>
																<SVG
																	src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-down.svg")}
																/>
															</span>
															<br />
															<span>Move Down</span>
														</button>

														<div className="mx-auto">
															<Typography className="font-weight-bolder">Element {element_index + 1} ({(() => {
																switch(indiv_element.element_type_id) {
																	case 1:
																		return "Image";
																	case 2:
																		return "Audio";
																	case 3:
																		return "Document";
																	case 4:
																		return "Video";
																	case 5:
																		return "Text";
																	case 6:
																		return "Reflection";
																	case 7:
																		return "Signature";
																	case 8:
																		return "Survey";
																	case 9:
																		return "Link";
																	case 10:
																			return "Download";
																	default:
																		return null;
																}
															})()})</Typography>
														</div>

														<button 
															type="button"
															className="btn btn-primary my-auto mr-1"
															onClick = {() => setEditElementId(indiv_element.id)}
														>
															Edit
														</button>

														<DeleteOwnerElement
															params={params}
															element={indiv_element}
															elementIndex={element_index}
															setSelectedElement={setSelectedElement}
															elements={elements}
															setElements={setElements}
														/>
													</div>}

												</div>
												{(() => {
													if([1, 2, 3].includes(indiv_element.element_type_id)) {

														if(indiv_element.attachments.length) return null;

														return <>
															<small className="text-danger">Please comply with the requirements or this content will not be shown to the students</small>
															<ul className='text-danger'>
																<li>
																	<small className="text-danger">This element requires an attachment.</small>
																</li>
															</ul>
														</>
													} else if(indiv_element.element_type_id === 4) {
														if(indiv_element.attachments.length) return null;

														return <>
															<small className="text-danger">Please comply with the requirements or this content will not be shown to the students</small>
															<ul className='text-danger'>
																<li>
																	<small className="text-danger">This element requires a link.</small>
																</li>
															</ul>
														</>
													} else if(indiv_element.element_type_id === 5) {
														if(indiv_element.attachments.length) return null;

														return <>
															<small className="text-danger">Please comply with the requirements or this content will not be shown to the students</small>
															<ul className='text-danger'>
																<li>
																	<small className="text-danger">This element required a description.</small>
																</li>
															</ul>
														</>
													} else if(indiv_element.element_type_id === 6) {
														if(indiv_element.attachments.length) return null;

														return <>
															<small className="text-danger">Please comply with the requirements or this content will not be shown to the students</small>
															<ul className='text-danger'>
																<li>
																	<small className="text-danger">Please confirm if your content is shared or private.</small>
																</li>
															</ul>
														</>
													} else if(indiv_element.element_type_id === 7) {
														if(indiv_element.attachments.length) return null;

														return <>
															<small className="text-danger">Please comply with the requirements or this content will not be shown to the students</small>
															<ul className='text-danger'>
																<li>
																	<small className="text-danger">Please confirm if the default field values are correct.</small>
																</li>
															</ul>
														</>
													}
												})()}
											</div>
										}))()}

										<hr />

										<div className="d-flex justify-content-around w-50 mx-auto">
											
											<CreateOwnerElement lesson={lesson} elements={elements} setElements={setElements} params={params} />
											{/* <ImportOwnnerElement lesson={lesson} elements={elements} params={params} /> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</>

				case "Training Details":
					return <div className="row">
						<div className="col-12">
							<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
								<div className="card-header">
									<h3 className="card-title font-weight-bolder">{library.settings.group_content ? "Modules and Lessons" : "Library Lessons"}</h3>
									<div className="card-toolbar">
										{library.settings.group_content ? 
											<CreateOwnerModule 
												library={library} 
												libraryContent={libraryContent} 
												setLibraryContent={setLibraryContent} 
											/> : <CreateOwnerLibraryLesson 
												headerButton={true}
												library={library} 
												libraryContent={libraryContent} 
												setLibraryContent={setLibraryContent} 
											/>}
										
									</div>
								</div>

								<div className="card-body">
									
									{(() => {

										if(!libraryContent.length) {
											return <div className="d-flex ml-5 mb-2 border-bottom">
												No content yet...
											</div>
										}
										
										return libraryContent.sort((a,b) => (a.sort-b.sort)).map((indiv_module, module_index) => {
											if(library.settings.group_content) {
												return (
													<div key={module_index} className="form-group">
														<div className="p-3 bg-secondary d-flex rounded mb-2 align-items-center">
															<Typography className="font-weight-bolder">
																{indiv_module.title}
															</Typography>
															

															{ indiv_module.resource_id !== "library_lesson" && <>

																<button 
																	disabled={disableSort || !module_index}
																	type="button"
																	style={{ transform: "rotate(180deg)" }}
																	className={`ml-auto btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || !module_index ? "muted" : "primary"}`}
																	onClick={async () => {
																		setDisableSort(true);
																		await sort_owner_module(indiv_module.resource_id, { 
																			library_party_id: library.party_id,
																			module_index: module_index,
																			sort: module_index - 1
																		}).then(({ data: { library_content_query } }) => {
																			if(library_content_query) {
																				setLibraryContent(library_content_query);
																				snackbarAlert("success", "Module Moved Up");
																			}
																			setDisableSort(false);
																		}).catch(err => {
																			console.log(err)
																			setDisableSort(false);
																			snackbarAlert("error", "Something went wrong!");
																		});
																	}}
																>
																	<SVG
																		src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
																	/>
																</button>

																<button 
																	disabled={libraryContent.length - 1 === module_index}
																	type="button"
																	className={`ml-3 btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || libraryContent.length - 1 === module_index ? "muted" : "primary"}`}
																	onClick={async () => {
																		setDisableSort(true);
																		await sort_owner_module(indiv_module.resource_id, { 
																			library_party_id: library.party_id,
																			module_index: module_index,
																			sort: module_index + 1
																		}).then(({ data: { library_content_query } }) => {
																			if(library_content_query) {
																				setLibraryContent(library_content_query);
																				snackbarAlert("success", "Module Moved Down");
																			}
																			setDisableSort(false);
																		}).catch(err => {
																			console.log(err)
																			setDisableSort(false);
																			snackbarAlert("error", "Something went wrong!");
																		});
																	}}
																>
																	<SVG
																		src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
																	/>
																</button>

																<span className="mx-3 text-muted"> | </span>

																<EditOwnerModule 
																	library={library} 
																	module={indiv_module}
																	moduleIndex={module_index} 
																	libraryContent={libraryContent} 
																	setLibraryContent={setLibraryContent} 
																/>
																
																<span className="mx-3 text-muted"> | </span>

																<DeleteOwnerModule 
																	library={library} 
																	module={indiv_module}
																	moduleIndex={module_index} 
																	libraryContent={libraryContent} 
																	setLibraryContent={setLibraryContent} 
																/>

																<span className="mx-3 text-muted"> | </span>
															</> }

															<CreateOwnerModuleLesson 
																library={library} 
																module={indiv_module}
																moduleIndex={module_index} 
																libraryContent={libraryContent} 
																setLibraryContent={setLibraryContent} 
															/>
														</div>
														{indiv_module.lessons && indiv_module.lessons.length ? indiv_module.lessons.map((indiv_lesson, lesson_index) => {
															return (
																<div key={lesson_index} className="d-flex ml-5 mb-2 border-bottom">
																	<Link 
																		to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																		className="text-primary"
																		onClick={() => setRecentLessonTab("Lesson Details")}
																		style={{fontSize: "medium"}}
																	>
																		{indiv_lesson.title}
																	</Link>

																	<button 
																		disabled={disableSort || !lesson_index}
																		type="button"
																		style={{ transform: "rotate(180deg)" }}
																		className={`ml-auto btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || !lesson_index ? "muted" : "primary"}`}
																		onClick={async () => {
																			setDisableSort(true);
																			await sort_owner_lesson(indiv_lesson.resource_id, { 
																				library_party_id: library.party_id,
																				lesson_index: lesson_index,
																				module_resource_id: indiv_module.resource_id,
																				sort: lesson_index - 1
																			}).then(({ data: { module_query } }) => {
																				if(module_query) {
																					let newLibraryContent = [...libraryContent];
																					newLibraryContent.splice(module_index, 1, module_query)
																					setLibraryContent(newLibraryContent);
																					snackbarAlert("success", "Lesson Moved Up");
																				}
																				setDisableSort(false);
																			}).catch(err => {
																				console.log(err)
																				setDisableSort(false);
																				snackbarAlert("error", "Something went wrong!");
																			});
																		}}
																	>
																		<SVG
																			src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
																		/>
																	</button>

																	<button 
																		disabled={disableSort || indiv_module.lessons.length - 1 === lesson_index}
																		type="button"
																		className={`ml-3 btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || indiv_module.lessons.length - 1 === lesson_index ? "muted" : "primary"}`}
																		onClick={async () => {
																			setDisableSort(true);
																			await sort_owner_lesson(indiv_lesson.resource_id, { 
																				library_party_id: library.party_id,
																				lesson_index: lesson_index,
																				module_resource_id: indiv_module.resource_id,
																				sort: lesson_index + 1
																			}).then(({ data: { module_query } }) => {
																				if(module_query) {
																					let newLibraryContent = [...libraryContent];
																					newLibraryContent.splice(module_index, 1, module_query)
																					setLibraryContent(newLibraryContent);
																					snackbarAlert("success", "Lesson Moved Up");
																				}
																				setDisableSort(false);
																			}).catch(err => {
																				console.log(err)
																				setDisableSort(false);
																				snackbarAlert("error", "Something went wrong!");
																			});
																		}}
																	>
																		<SVG
																			src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
																		/>
																	</button>

																	<span className="mx-3 text-muted"> | </span>
																
																	<EditOwnerLesson
																		library={library} 
																		module={indiv_module}
																		moduleIndex={module_index} 
																		libraryContent={libraryContent} 
																		setLibraryContent={setLibraryContent}
																		lesson={indiv_lesson}
																		lessonIndex={lesson_index}
																	/>
																	
																	<span className="mx-3 text-muted"> | </span>

																	<DeleteOwnerLesson
																		library={library} 
																		module={indiv_module}
																		moduleIndex={module_index} 
																		libraryContent={libraryContent} 
																		setLibraryContent={setLibraryContent}
																		lesson={indiv_lesson}
																		lessonIndex={lesson_index}
																	/>

																	<span className="mx-3 text-muted"> | </span>

																	<Link 
																		to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																		className="text-primary mr-3"
																		onClick={() => setRecentLessonTab("Lesson Details")}
																	>
																		View
																	</Link>
																	
																</div>
															);
														}) : (
															<div className="text-center">
																Please add a topic...
															</div>
														)}
													</div>
												)
											} else {
												return <div key={module_index}>
													{indiv_module.lessons && indiv_module.lessons.length ? indiv_module.lessons.map((indiv_lesson, lesson_index) => {
														return (
															<div key={lesson_index} className="d-flex ml-5 mb-2 border-bottom">
																<Link 
																	to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																	className="text-primary"
																	style={{fontSize: "medium"}}
																>
																	{indiv_lesson.title}
																</Link>

																<div className="ml-auto">
																	<EditOwnerLesson
																		library={library} 
																		module={indiv_module}
																		moduleIndex={module_index} 
																		libraryContent={libraryContent} 
																		setLibraryContent={setLibraryContent}
																		lesson={indiv_lesson}
																		lessonIndex={lesson_index}
																	/>
																</div>
																
																<span className="mx-3 text-muted"> | </span>

																<DeleteOwnerLesson
																	library={library} 
																	module={indiv_module}
																	moduleIndex={module_index} 
																	libraryContent={libraryContent} 
																	setLibraryContent={setLibraryContent}
																	lesson={indiv_lesson}
																	lessonIndex={lesson_index}
																/>

																<span className="mx-3 text-muted"> | </span>

																<Link 
																	to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																	className="text-primary mr-3"
																	onClick={() => setRecentLessonTab("Lesson Details")}
																>
																	View
																</Link>
															</div>
														);
													}) : (
														<div className="text-center">
															Please add a topic...
														</div>
													)}
												</div>
											}
										})
									})()}
								</div>
							</div>
						</div>
					</div>

				case "Overview":
					return <div className="row">
						<div className="col-12">
							<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
								<div className="card-header">
									<h3 className="card-title font-weight-bolder">Overview</h3>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-lg-3 col-md-3">
											<img 
												src={library.image_file ? `${process.env.REACT_APP_S3_BUCKET}/library_uploads/images/${library.image_file}` : "https://lernnex.s3.amazonaws.com/library_uploads/images/default_library_image.jpg"}
												alt={library.title}
												className="w-100 rounded"
											/>
										</div>
										<div className="col-lg-9 col-md-3">
											{
												Boolean(library.description) && (
													<div>
														<Typography variant="h6">
															Description: 
														</Typography>

														<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
															{library.description}
														</Typography>
														<br />
													</div>
												)
											}

											{
												Boolean(library.context && library.context.what_will_you_learn) && (
													<div>
														<Typography variant="h6">
															What will you learn?: 
														</Typography>

														<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
															{library.context.what_will_you_learn}
														</Typography>
														<br />
													</div>
												)
											}

											{
												Boolean(library.context && library.context.who_should_take_this) && (
													<div>
														<Typography variant="h6">
															Who should take this?: 
														</Typography>

														<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
															{library.context.who_should_take_this}
														</Typography>
														<br />
													</div>
												)
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				case "Q & A":
					return <>
						<div className="row">
							<div className="col-12">
								<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
									<div className="card-body">
										{(() => 
											Boolean(faqs.length) ? 
												faqs.map((indiv_faq, faq_index) => {
													return <div className="mb-5" key={faq_index}>
														<Accordion>
															<AccordionSummary
																expandIcon={<ExpandMoreIcon />}
																aria-controls="panel1a-content"
																id="panel1a-header"
															>
																<Avatar 
																	alt="Avatar Image" 
																	src={indiv_faq.account.persons[0].image_file
																		? `${process.env.REACT_APP_S3_BUCKET}/user_uploads/images/${indiv_faq.account.persons[0].image_file}`
																		: "https://lernnex.s3.amazonaws.com/user_profile_images/default_user_image.png"}  
																/>
																<div style={{paddingLeft: "5px"}}>
																	<Typography>{indiv_faq.question}</Typography>
																	<small style={{color: "#75767E"}}>Asked by {indiv_faq.account.name} last {moment(indiv_faq.updated_at).format('MMM DD, YYYY')}</small>
																</div>
															</AccordionSummary>

															<AccordionDetails style={{display: "block", paddingLeft: "60px"}}>
																{indiv_faq.faq_answers.map((indiv_answer, answer_index) => {
																	return <div key={answer_index} style={{display: "flex"}}>
																		<Avatar style={{backgroundColor: avatarBgColor[Math.floor(Math.random()*avatarBgColor.length)]}}>
																			{indiv_answer.answered_by.first_name.charAt(0)}
																		</Avatar>
																		<div style={{paddingLeft: "5px"}}>
																			<Typography style={{fontWeight: "bold"}}>
																				{indiv_answer.answered_by.first_name + " " + indiv_answer.answered_by.last_name}:
																			</Typography>
																			<Typography>
																				{indiv_answer.answer}
																			</Typography>
																		</div>
																		<hr />
																	</div>
																})}
															</AccordionDetails>
														</Accordion>
													</div>
												})
											: <div style={{textAlign: "center"}}>
												<img style={{width: "20%"}} src={toAbsoluteUrl("/media/misc/no_questions_icon.png")} alt="question mark icon"/>

												<Typography variant="body1" className="form-group">
													Asking questions is turned off in the settings. We encourage you to turn it on to help the students.
												</Typography>

												<div className="row" style={{justifyContent: "center"}}>
													<div className="col-8">
														<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
															<div className="card-body">
																<Formik
																	enableReinitialize={true}
																	initialValues={{
																		allow_questions: Boolean(library.settings.allow_questions.isAllowed),
																		email_question_to: (library.settings.allow_questions.email_question_to) ?? [],
																	}}
																	validate={values => {
																		const errors = {};

																		return errors;
																	}}
																	onSubmit={async (values, {}) => {
																		let post_values = {
																			"allow_questions": {
																				"isAllowed": values.allow_questions,
																				"email_question_to": values.email_question_to
																			}
																		};
																		
																		try {
																			const { status, data: { library_query } } = await update_owner_library_settings(params.library_party_id, post_values, "true");
																			
																			if(status === 200) {
																				snackbarAlert("success", "Settings updated");
																				setLibrary(library_query);
																			}
																		} catch(err) {
																			console.log(err);
																			snackbarAlert("error", "Something went wrong");
																		}
																	}}
																>
																	{({
																		values,
																		errors,
																		touched,
																		handleChange,
																		handleBlur,
																		handleSubmit,
																		isSubmitting,
																		setFieldValue
																	}) => (
																		<form
																			onSubmit={handleSubmit}
																			noValidate
																			autoComplete="off"
																			className="w-100"
																		>
																			<div className="form-group">
																				<div className="row">
																					<div className="col-3">
																						<div className="d-flex h-100">
																							<div className="align-self-center">
																								<Typography variant="body1">
																									Allow Questions:
																								</Typography>
																							</div>
																						</div>
																					</div>
																					<div className="col-2">
																						<div className="d-flex h-100">
																							<div className="align-self-center">
																								<FormControlLabel
																									control={
																										<Switch
																											onChange={handleChange}
																											checked={Boolean(values.allow_questions)}
																											value={values.allow_questions}
																											name="allow_questions"
																										/>
																									}
																									label={values.allow_questions && "Yes"}
																								/>
																							</div>
																						</div>
																					</div>
																					{
																						values.allow_questions && <div className="col-lg-7">
																							<ChipInput
																								className="w-100"
																								InputProps={{ color: "secondary", name: "email_question_to", placeholder: "Email questions (Input email and press enter)" }}
																								value={values.email_question_to}
																								blurBehavior='add'
																								onAdd={(chip) => {
																									values.email_question_to.push(chip);
																									setFieldValue("email_question_to", values.email_question_to);
																								}}
																								onDelete={(chip, index) => {
																									values.email_question_to.splice(index, 1);
																									setFieldValue("email_question_to", values.email_question_to);
																								}}
																							/>
																						</div>
																					}
																				</div>
																			</div>

																			<div className="text-right">
																				<button
																					type="submit"
																					disabled={isSubmitting}
																					className="btn btn-success btn-elevate kt-login__btn-primary"
																				>
																					Save
																				</button>
																			</div>
																		</form>
																	)}
																</Formik>
															</div>
														</div>      
													</div>
												</div>
											</div>
										)()}
									</div>
								</div>
							</div>
						</div>
					</>
				default: 
					setRecentLessonTab("Lesson Details");
			}
		})()}
	</>
}
