// define arrays of character options
const vowels = ["a", "e", "i", "o", "u"];
const consonants = ["b", "c", "d", "f", "g", "h", "j", "k", "l", "m", "n", "p", "q", "r", "s", "t", "v", "w", "x", "y", "z"];
const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const specialChars = ["!", "@", "#", "$", "%", "&"];

// function to generate a random character from an array
function getRandomChar(arr) {
	const index = Math.floor(Math.random() * arr.length);
	return arr[index];
}

// generate a random character
function generateRandomChar() {
	const charType = Math.floor(Math.random() * 4);
	switch (charType) {
		case 0:
			return getRandomChar(vowels);
		case 1:
			return getRandomChar(consonants);
		case 2:
			return getRandomChar(numbers);
		case 3:
			return getRandomChar(specialChars);
		default:
			return "";
	}
}

// generate a random string of specified length
export function generateRandomString(length) {
	let result = "";
	for (let i = 0; i < length; i++) {
		result += generateRandomChar();
	}
	return result;
}

export function isEmpty(obj) {
	if (obj === null || obj === undefined) {
		return true;
	}

	if(typeof obj === "undefined") {
		return true;
	}

	if(typeof obj === "object" && Object.keys(obj).length === 0) {
		return true;
	}

	if(typeof obj === "string" && obj.length === 0) {
		return true;
	}

	if(typeof obj === "string" && obj === "null") {
		return true;
	}

	if(typeof obj === "string" && obj === "undefined") {
		return true;
	}

	return false
}

export function isLinkAVideo(link) {
	const fileExtensions = [
		".mp4",
		".mov",
		".avi",
		".wmv",
		".mkv",
		".flv",
		".webm"
	];

	for(const fileExtension of fileExtensions) {
		if(link.includes(fileExtension)) {
			return true;
		}
	}

	return false;
}