import React, { useState, useEffect, useRef } from 'react'
import { Formik, FieldArray } from 'formik'
import { injectIntl } from 'react-intl'
// import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from "react-player";
import * as yup from "yup";

import { useSnackbar } from "notistack";
import { ReactTinyLink } from 'react-tiny-link'
import DeleteIcon from '@material-ui/icons/Delete';
import { DoughnutChart } from '../../../../../widgets/charts';
import { ShowResponses, DeleteOwnerElement } from './LessonContentModals';
import AIPromptModal from "../../../../../components/AIPromptModal";
import { QuickAccessSettings } from "../../../../../components/QuickAccess";
import { generateRandomString, isEmpty, isLinkAVideo } from "../../../../../modules/Functions";

import { 
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	Switch,
	IconButton,
	Checkbox
}
from '@material-ui/core';

import {
	create_owner_element_attachment,
	update_owner_element_attachment,
	create_chat_completion
}
from '../../../_redux/LibrariesCrud';

// Start of Image Componenent
export const ElementImageCreate = injectIntl(({ params, element, intl, element_index, elements, setElements, setSelectedElement }) => {
	const [imageTempURL, setImageTempURL] = useState("");

  return <Formik
	  initialValues={{
		library_party_id: params.library_party_id,
		module_resource_id: params.module_resource_id,
		lesson_resource_id: params.lesson_resource_id,
		element_type_id: element.element_type_id,
		element_id: element.id,
		file: null
	  }}
	  validate={values => {
		  const errors = {};

		  if (!values.file) {
			  errors.file = intl.formatMessage({
				  id: "AUTH.VALIDATION.REQUIRED_FIELD"
			  });
		  }

		  return errors;
	  }}
	  onSubmit={(values, { setSubmitting, resetForm }) => {
		create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
			if(new_element_attachment) {
				let newElements = [...elements];
				newElements[element_index].attachments.push(new_element_attachment);
				setElements([]);
				setElements(newElements);
				// window.scrollTo(0,document.body.scrollHeight);
			}
			setSubmitting(false);
		}).catch(err => {
			console.log(err);
			setSubmitting(false);
		});
	  }}
  >
	  {({
		  values,
		  errors,
		  touched,
		  handleChange,
		  handleBlur,
		  handleSubmit,
		  isSubmitting,
		  setFieldValue,
		  handleReset
	  }) => (
		  <form
			  onSubmit={handleSubmit}
			  noValidate
			  autoComplete="off"
			  className="text-center"
		  >
			<input
				id="image_input"
				type="file"
				name="image_input"
				accept="image/*"
				className="d-none"
				onChange={imageBLOB => {
					let reader = new FileReader();

					if (imageBLOB.currentTarget.files[0]) {
						setFieldValue(
							"file",
							imageBLOB.currentTarget.files[0]
						);
						reader.readAsDataURL(
							imageBLOB.currentTarget.files[0]
						);
						reader.onload = image => {
							setImageTempURL(image.target.result);
						};
					}
				}}
			/>

			<div className="form-group">
				<TextField
					label="Description"
					color="secondary"
					multiline
					name="description"
					placeholder="Description is not required and could be multiple lines..."
					margin="normal"
					variant="outlined"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.description}
					helperText={touched.description && errors.description}
					error={Boolean(touched.description && errors.description)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>
			
			<div style={{display: "inline-grid"}}>
				<label htmlFor="image_input" className="btn form-group">
					{imageTempURL !== "" ? (
						<img
							alt="upload"
							className="w-100 d-block mx-auto rounded border p-2"
							src={imageTempURL}
							onBlur={handleBlur}
						/>
					) : (
						<div>
							<img
								alt="upload"
								className="w-100 d-block rounded border p-2"
								src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/image_icon.png"
								style={{ maxHeight: "20vh" }}
							/>
							<Typography>
							Attach
							</Typography>
							{Boolean(touched.file && errors.file) && <Typography align="center" className="text-danger">
							Image upload is required
							</Typography>}
						</div>
					)}
				</label>

				<div style={{display: "inline-flex"}}>
					<button
						type="submit"
						disabled={isSubmitting}
						className="btn btn-success my-auto mr-1"
					>
						Submit
					</button>
					
					<DeleteOwnerElement
						params={params}
						element={element}
						elementIndex={element_index}
						setSelectedElement={setSelectedElement}
						elements={elements}
						setElements={setElements}
					/>
				</div>
			</div>
		</form>
	  )}
  </Formik>
})

export const ElementImageEdit = injectIntl(({ params, element, intl, element_index, elements, setElements, setEditElementId }) => {
	const [imageTempURL, setImageTempURL] = useState(process.env.REACT_APP_S3_BUCKET + element.attachments[0].directory + element.attachments[0].file_name);

	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			file: null,
			description: element.attachments[0].description
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			update_owner_element_attachment(values).then(({ data: { element_query } }) => {
				if(element_query) {
					let newElements = [...elements];
					newElements[element_index] = element_query;
					setElements([]);
					setElements(newElements);
					setEditElementId(0)
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
			<input
				id="image_input"
				type="file"
				name="image_input"
				accept="image/*"
				className="d-none"
				onChange={imageBLOB => {
					let reader = new FileReader();

					if (imageBLOB.currentTarget.files[0]) {
						setFieldValue(
							"file",
							imageBLOB.currentTarget.files[0]
						);
						reader.readAsDataURL(
							imageBLOB.currentTarget.files[0]
						);
						reader.onload = image => {
							setImageTempURL(image.target.result);
						};
					}
				}}
			/>

			<div className="form-group">
				<TextField
					label="Description"
					color="secondary"
					multiline
					name="description"
					placeholder="Description is not required and could be multiple lines..."
					margin="normal"
					variant="outlined"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.description}
					helperText={touched.description && errors.description}
					error={Boolean(touched.description && errors.description)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>

			<label htmlFor="image_input" className="btn">
				{imageTempURL !== "" ? (
					<img
						alt="upload"
						className="w-100 d-block mx-auto rounded border p-2"
						src={imageTempURL}
						onBlur={handleBlur}
					/>
				) : (
					<div>
						<img
							alt="upload"
							className="w-100 d-block rounded border p-2"
							src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/image_icon.png"
							style={{ maxHeight: "20vh" }}
						/>
						<Typography>
							Attach
						</Typography>
						{Boolean(touched.file && errors.file) && <Typography align="center" className="text-danger">
							Image upload is required
						</Typography>}
					</div>
				)}
			</label>

			<button
				type="submit"
				disabled={isSubmitting}
				className="btn btn-success d-block mx-auto"
			>
				Submit
			</button>
		</form>
		)}
	</Formik>
})

export const ElementImageView = ({ attachment }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}
		<img 
			src={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment.file_name} 
			alt="Element" 
			className="d-block rounded mx-auto w-50"
		/>
	</>
}
// End of Image Component

// Start of Audio Component
export const ElementAudioCreate = injectIntl(({ params, element, intl, element_index, elements, setElements, setSelectedElement }) => {
	const [audioTempURL, setAudioTempURL] = useState("");

  return <Formik
	  initialValues={{
		library_party_id: params.library_party_id,
		module_resource_id: params.module_resource_id,
		lesson_resource_id: params.lesson_resource_id,
		element_type_id: element.element_type_id,
		element_id: element.id,
		file: null
	  }}
	  validate={values => {
		  const errors = {};

		  if (!values.file) {
			  errors.file = intl.formatMessage({
				  id: "AUTH.VALIDATION.REQUIRED_FIELD"
			  });
		  }

		  return errors;
	  }}
	  onSubmit={(values, { setSubmitting, resetForm }) => {
		create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
	  }}
  >
	  {({
		  values,
		  errors,
		  touched,
		  handleChange,
		  handleBlur,
		  handleSubmit,
		  isSubmitting,
		  setFieldValue,
		  handleReset
	  }) => (
		  <form
			  onSubmit={handleSubmit}
			  noValidate
			  autoComplete="off"
			  className="text-center"
		  >
			<input
				id="audio_input"
				type="file"
				name="audio_input"
				accept="audio/*"
				className="d-none"
				onChange={audioBLOB => {
					let reader = new FileReader();

					if (audioBLOB.currentTarget.files[0]) {
						setFieldValue(
							"file",
							audioBLOB.currentTarget.files[0]
						);
						reader.readAsDataURL(
							audioBLOB.currentTarget.files[0]
						);
						reader.onload = image => {
							setAudioTempURL(image.target.result);
						};
					}
				}}
			/>

			<div className="form-group">
				<TextField
					label="Description"
					color="secondary"
					multiline
					name="description"
					placeholder="Description is not required and could be multiple lines..."
					margin="normal"
					variant="outlined"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.description}
					helperText={touched.description && errors.description}
					error={Boolean(touched.description && errors.description)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>

			<div style={{display: "inline-grid"}}>
				<label htmlFor="audio_input" className="btn">
					{audioTempURL !== "" ? (
						<div className="text-center">
							<a
								href={window.URL.createObjectURL(values.file)}
								download={values.file.name}
								className="btn p-0"
							>
								<span>{values.file.name}</span>
								<br />
								<span>Check!</span>
								
							</a>

							<label htmlFor="audio_input" className="text-center w-100 m-0 p-0 btn">
								<img
									width="50"
									src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/audio_icon.png"
									alt="audio"
									className="d-block mx-auto"
									style={{ maxHeight: "20vh" }}
								/>
								<span>Change!</span>
							</label>
						</div>
					) : (
						<div>
							<img
								alt="upload"
								className="w-100 d-block rounded border p-2"
								src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/audio_icon.png"
								style={{ maxHeight: "20vh" }}
							/>
							<Typography>
							Attach
							</Typography>
							{Boolean(touched.file && errors.file) && <Typography align="center" className="text-danger">
							Audio upload is required
							</Typography>}
						</div>
					)}
				</label>

				<div style={{display: "inline-flex"}}>
					<button
						type="submit"
						disabled={isSubmitting}
						className="btn btn-success my-auto mr-1"
					>
						Submit
					</button>
					
					<DeleteOwnerElement
						params={params}
						element={element}
						elementIndex={element_index}
						setSelectedElement={setSelectedElement}
						elements={elements}
						setElements={setElements}
					/>
				</div>
			</div>
		</form>
	  )}
  </Formik>
})

export const ElementAudioEdit = injectIntl(({ params, element, intl, element_index, elements, setElements, setEditElementId }) => {
	const [audioTempURL, setAudioTempURL] = useState("");

  return <Formik
	  initialValues={{
		library_party_id: params.library_party_id,
		module_resource_id: params.module_resource_id,
		lesson_resource_id: params.lesson_resource_id,
		element_type_id: element.element_type_id,
		element_id: element.id,
		file: process.env.REACT_APP_S3_BUCKET + element.attachments[0].directory + element.attachments[0].file_name,
		description: element.attachments[0].description
	  }}
	  onSubmit={(values, { setSubmitting, resetForm }) => {
		update_owner_element_attachment(values).then(({ data: { element_query } }) => {
				if(element_query) {
					let newElements = [...elements];
					newElements[element_index] = element_query;
					setElements([]);
					setElements(newElements);
					setEditElementId(0)
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
	  }}
  >
	  {({
		  values,
		  errors,
		  touched,
		  handleChange,
		  handleBlur,
		  handleSubmit,
		  isSubmitting,
		  setFieldValue,
		  handleReset
	  }) => (
		  <form
			  onSubmit={handleSubmit}
			  noValidate
			  autoComplete="off"
			  className="text-center"
		  >
			<input
				id="audio_input"
				type="file"
				name="audio_input"
				accept="audio/*"
				className="d-none"
				onChange={audioBLOB => {
					let reader = new FileReader();

					if (audioBLOB.currentTarget.files[0]) {
						setFieldValue(
							"file",
							audioBLOB.currentTarget.files[0]
						);
						reader.readAsDataURL(
							audioBLOB.currentTarget.files[0]
						);
						reader.onload = image => {
							setAudioTempURL(image.target.result);
						};
					}
				}}
			/>

			<div className="form-group">
				<TextField
					label="Description"
					color="secondary"
					multiline
					name="description"
					placeholder="Description is not required and could be multiple lines..."
					margin="normal"
					variant="outlined"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.description}
					helperText={touched.description && errors.description}
					error={Boolean(touched.description && errors.description)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>
			
			{audioTempURL !== "" ? (
				<label htmlFor="audio_input" className="btn">
						<div className="text-center">
							<a
								href={window.URL.createObjectURL(values.file)}
								download={values.file.name}
								className="btn p-0"
							>
								<span>{values.file.name}</span>
								<br />
								<span>Check!</span>
								
							</a>
						</div>
				</label>
			) : (
				<div>
					<audio controls="controls" className="w-75 d-block mx-auto">
						<source src={process.env.REACT_APP_S3_BUCKET + element.attachments[0].directory + element.attachments[0].file_name} />
						Your browser does not support the audio element.
					</audio>
				</div>
			)}

			<label htmlFor="audio_input" className="text-center w-100 m-0 p-0 btn">
				<img
					width="50"
					src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/audio_icon.png"
					alt="audio"
					className="d-block mx-auto"
					style={{ maxHeight: "20vh" }}
				/>
				<span>Change!</span>
			</label>

			<button
				type="submit"
				disabled={isSubmitting}
				className="btn btn-success d-block mx-auto"
			>
				Submit
			</button>
		</form>
	  )}
  </Formik>
})

export const ElementAudioView = ({ attachment }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}
		<div>
			<audio controls="controls" className="w-75 d-block mx-auto">
				<source src={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment.file_name} />
				Your browser does not support the audio element.
			</audio>
		</div>
	</>
}
// End of Audio Component

// Start of Document Component
export const ElementDocumentCreate = injectIntl(({ params, element, intl, element_index, elements, setElements, setSelectedElement }) => {
	const [documentTempURL, setDocumentTempURL] = useState({["document_" + element_index] : ""});
	
	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			["file_" + element_index]: null,
			element_index: element_index,
			description: "",
		}}
		validate={values => {
			const errors = {};

			if (!values["file_" + element_index]) {
				errors["file_" + element_index] = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
					if(new_element_attachment) {
						let newElements = [...elements];
						newElements[element_index].attachments.push(new_element_attachment);
						setElements([]);
						setElements(newElements);
						// window.scrollTo(0,document.body.scrollHeight);
					}
					setSubmitting(false);
				}).catch(err => {
					console.log(err);
					setSubmitting(false);
				});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
				<input
					id={"document_input_" + element_index}
					type="file"
					name={"document_input_" + element_index}
					accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
					className="d-none"
					onChange={documentBLOB => {
						let reader = new FileReader();

						if (documentBLOB.currentTarget.files[0]) {
							setFieldValue(
								["file_" + element_index],
								documentBLOB.currentTarget.files[0]
							);
							reader.readAsDataURL(
								documentBLOB.currentTarget.files[0]
							);
							reader.onload = image => {
								setDocumentTempURL({["document_" + element_index]: image.target.result});
							};
						}
					}}
				/>

				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<div style={{display: "inline-grid"}}>
					<label htmlFor={"document_input_" + element_index} className="btn">
						{documentTempURL["document_" + element_index] !== "" ? (
							<div className="text-center">
								<a
									href={window.URL.createObjectURL(values["file_" + element_index])}
									download={values["file_" + element_index].name}
									className="btn p-0"
								>
									<span>{values["file_" + element_index].name}</span>
									<br />
									<span>Check!</span>
									
								</a>

								<label htmlFor={"document_input_" + element_index} className="text-center w-100 m-0 p-0 btn">
									<img
										width="50"
										src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
										alt="document"
										className="d-block mx-auto"
										style={{ maxHeight: "20vh" }}
									/>
									<span>Change!</span>
								</label>
							</div>
						) : (
							<div>
								<img
									alt="upload"
									className="w-100 d-block rounded border p-2"
									src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
									style={{ maxHeight: "20vh" }}
								/>
								<Typography>
								Attach
								</Typography>
								{Boolean(touched["file_" + element_index] && errors["file_" + element_index]) && <Typography align="center" className="text-danger">
								Document upload is required
								</Typography>}
							</div>
						)}
					</label>

					<div style={{display: "inline-flex"}}>
						<button
							type="submit"
							disabled={isSubmitting}
							className="btn btn-success my-auto mr-1"
						>
							Submit
						</button>
						
						<DeleteOwnerElement
							params={params}
							element={element}
							elementIndex={element_index}
							setSelectedElement={setSelectedElement}
							elements={elements}
							setElements={setElements}
						/>
					</div>
				</div>
			</form>
		)}
	</Formik>
})

export const ElementDocumentEdit = injectIntl(({ params, element, intl, element_index, elements, setElements, setEditElementId }) => {
	const [documentTempURL, setDocumentTempURL] = useState("");

  return <Formik
	  initialValues={{
		library_party_id: params.library_party_id,
		module_resource_id: params.module_resource_id,
		lesson_resource_id: params.lesson_resource_id,
		element_type_id: element.element_type_id,
		element_id: element.id,
		file: null,
		description: element.attachments[0].description,
		title: element.attachments[0].title
	  }}
	  onSubmit={(values, { setSubmitting, resetForm }) => {
		update_owner_element_attachment(values).then(({ data: { element_query } }) => {
			if(element_query) {
				let newElements = [...elements];
				newElements[element_index] = element_query;
				setElements([]);
				setElements(newElements);
				setEditElementId(0)
				// window.scrollTo(0,document.body.scrollHeight);
			}
			setSubmitting(false);
		}).catch(err => {
			console.log(err);
			setSubmitting(false);
		});
	  }}
  >
	  {({
		  values,
		  errors,
		  touched,
		  handleChange,
		  handleBlur,
		  handleSubmit,
		  isSubmitting,
		  setFieldValue,
		  handleReset
	  }) => (
		  <form
			  onSubmit={handleSubmit}
			  noValidate
			  autoComplete="off"
			  className="text-center"
		  >
			<input
				id="document_input"
				type="file"
				name="document_input"
				accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
				className="d-none"
				onChange={documentBLOB => {
					let reader = new FileReader();

					if (documentBLOB.currentTarget.files[0]) {
						setFieldValue(
							"file",
							documentBLOB.currentTarget.files[0]
						);
						reader.readAsDataURL(
							documentBLOB.currentTarget.files[0]
						);
						reader.onload = image => {
							setDocumentTempURL(image.target.result);
						};
					}
				}}
			/>

			<div className="form-group">
				<TextField
					label="Title"
					color="secondary"
					multiline
					name="title"
					placeholder="Download for more info"
					margin="normal"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.title}
					helperText={touched.title && errors.title}
					error={Boolean(touched.title && errors.title)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>

			<div className="form-group">
				<TextField
					label="Description"
					color="secondary"
					multiline
					name="description"
					placeholder="Description is not required and could be multiple lines..."
					margin="normal"
					variant="outlined"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.description}
					helperText={touched.description && errors.description}
					error={Boolean(touched.description && errors.description)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>
			
			{documentTempURL !== "" ? (
				<label htmlFor="document_input" className="btn">
						<div className="text-center">
							<a
								href={window.URL.createObjectURL(values.file)}
								download={values.file.name}
								className="btn p-0"
							>
								<span>{values.file.name}</span>
								<br />
								<span>Check!</span>
								
							</a>
						</div>
				</label>
			) : (
				<div>
					<div className="text-center">
						<a
							href={process.env.REACT_APP_S3_BUCKET + element.attachments[0].directory + element.attachments[0].file_name}
							download={element.attachments[0].file_name}
							className="btn p-0"
						>
							<span className="text-primary">{element.attachments[0].file_name}</span>
						</a>
					</div>
				</div>
			)}

			<label htmlFor="document_input" className="text-center w-100 m-0 p-0 btn">
				<img
					width="50"
					src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
					alt="document"
					className="d-block mx-auto"
					style={{ maxHeight: "20vh" }}
				/>
				<span>Change!</span>
			</label>

			<button
				type="submit"
				disabled={isSubmitting}
				className="btn btn-success d-block mx-auto"
			>
				Submit
			</button>
		</form>
	  )}
  </Formik>
})

export const ElementDocumentView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Document" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title || "Download for more info"}
			</Typography> 
		</div>
		
		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 
			</div>
		)}

		<div>
			<div className="text-center">
				<label htmlFor="document_input" className="text-center w-100 m-0 p-0 btn">
					<img
						width="50"
						src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
						alt="document"
						className="d-block mx-auto"
						style={{ maxHeight: "20vh" }}
					/>
				</label>
				<a
					href={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment.file_name}
					download={attachment.file_name}
					className="btn p-0"
				>
					<span className="text-primary">{attachment.file_name}</span>
					<br />
					<span>Click to download!</span>
				</a>
			</div>
		</div>
	</>
}
// End of Document Component

// Start of Video Component
export const ElementVideoCreate = injectIntl(({ params, library, lesson, element, intl, element_index, elements, setElements, setSelectedElement, videoUploadProgress, setVideoUploadProgress, transcodingFunc, videoTranscodingDetails, setVideoTranscodingDetails, videoTranscodingState, setVideoTranscodingState }) => {
	const [videoTempURL, setVideoTempURL] = useState({["video_" + element_index] : ""});
	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	let Vimeo = require('vimeo').Vimeo;
	let client = new Vimeo(process.env.REACT_APP_VIMEO_CLIENT, process.env.REACT_APP_VIMEO_SECRET, process.env.REACT_APP_VIMEO_ACCESS);

	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			element_index: element_index,
			link: "",
			["file_" + element_index]: null,
			["video_link_" + element_index]: ""
		}}
		validate={values => {
			const errors = {};

			if (!values["file_" + element_index] && !values.link) {
				errors["file_" + element_index] = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
				
				errors.link = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
			if(!values.link) {
				client.upload(
					values["file_" + element_index],
					{
						'name': library.party_id + "-" + library.title + "-" + lesson.resource_id + "-" + element.id,
					},
					function (uri) {
						const vidURI = uri.split("/")
						values["file_" + element_index] = vidURI[2];
						values["video_link_" + element_index] = process.env.REACT_APP_VIMEO_URL + vidURI[2];
						create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
							if(new_element_attachment) {
								let newElements = [...elements];
								newElements[element_index].attachments.push(new_element_attachment);
								setElements([]);
								setElements(newElements);
								// window.scrollTo(0,document.body.scrollHeight);
	
								setVideoTranscodingDetails(prevState => ([
									...prevState,
									{["transcoding_details"]: {
										end_point: vidURI[2],
										element_index: element_index
									}}
								]))
	
								const data = videoTranscodingState;
								data["transcoding_state_" + element_index] = {
									interval_status: "pending",
									progress: 0
								}
	
								setVideoTranscodingState(data)
	
								snackbarAlert("success", "Upload successful!  We are now optimizing your video for more efficient data usage.  While waiting, you can proceed with the rest of your activities.");
							}
							setSubmitting(false);
						}).catch(err => {
							console.log(err);
							setSubmitting(false);
						});
					},
					function (bytes_uploaded, bytes_total) {
						var percentage = (bytes_uploaded / bytes_total * 100).toFixed(2)
						setVideoUploadProgress(prevState => ({
							...prevState,
							["progress_" + element_index]: percentage
						}));
					},
					function (error) {
						console.log('Failed because: ' + error)
					}
				)
			}
			else {
				create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
					if(new_element_attachment) {
						let newElements = [...elements];
						newElements[element_index].attachments.push(new_element_attachment);
						setElements([]);
						setElements(newElements);
						// window.scrollTo(0,document.body.scrollHeight);

						snackbarAlert("success", "Element Created");
					}
					setSubmitting(false);
				}).catch(err => {
					console.log(err);
					setSubmitting(false);
				});
			}
			
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
				<input
					id={"video_input_" + element_index}
					type="file"
					name={"video_input_" + element_index}
					accept=".mp4"
					className="d-none"
					onChange={videoBLOB => {
						let reader = new FileReader();

						if (videoBLOB.currentTarget.files[0]) {
							setFieldValue(
								["file_" + element_index],
								videoBLOB.currentTarget.files[0]
							);
							reader.readAsDataURL(
								videoBLOB.currentTarget.files[0]
							);
							reader.onload = video => {
								setVideoTempURL({["video_" + element_index]: video.target.result});
							};
						}
					}}
				/>

				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>
				
				<div style={{display: "inline-grid"}}>
					{videoUploadProgress["progress_" + element_index] > 0 ? 
						(
							<>
								<Typography>
									Uploading: &ensp; <span style={{fontWeight: "bold"}}>{videoUploadProgress["progress_" + element_index]}%</span>

								</Typography>
								<div className="rounded-pill mr-2 mb-5" style={{ width: `${videoUploadProgress["progress_" + element_index]}%`, height: 10, backgroundColor: "blue" }} />
							</>
						) : (
							<div style={{display: "flex"}}>
								{!values.link &&
									<div>
										<label htmlFor={"video_input_" + element_index} className="btn">
											{videoTempURL["video_" + element_index] !== "" ? (
												<div className="text-center">
													<a
														href={window.URL.createObjectURL(values["file_" + element_index])}
														download={values["file_" + element_index].name}
														className="btn p-0"
													>
														<span>{values["file_" + element_index].name}</span>
														<br />
														<span>Check!</span>
														
													</a>

													<label htmlFor={"video_input_" + element_index} className="text-center w-100 m-0 p-0 btn">
														<img
															width="50"
															src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/video_icon.png"
															alt="video"
															className="d-block mx-auto"
															style={{ maxHeight: "20vh" }}
														/>
														<span>Change!</span>
													</label>
												</div>
											) : (
												<div>
													<img
														alt="upload"
														className="w-100 d-block rounded border p-2"
														src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/video_icon.png"
														style={{ maxHeight: "20vh" }}
													/>
													<Typography>
														Attach
													</Typography>
													{Boolean(touched["file_" + element_index] && errors["file_" + element_index]) && <Typography align="center" className="text-danger">
														Video upload is required
													</Typography>}
												</div>
											)}
										</label>
									</div>
								}
								
								{!values["file_" + element_index] &&
									<div style={{display: "flex", alignItems: "center"}}>
										Or, paste the link here: &nbsp;
										<div className="form-group" style={{width: "400px"}}>
											<TextField
												label="Vimeo Link"
												color="secondary"
												multiline
												name="link"
												placeholder="Paste link here"
												margin="normal"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.link}
												helperText={touched.link && errors.link}
												error={Boolean(touched.link && errors.link)}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										</div>
									</div>
								}
							</div>
						)
					}
				</div>

				<div style={{display: "flex", justifyContent: "center"}}>
					<button
						type="submit"
						disabled={isSubmitting}
						className="btn btn-success my-auto mr-1"
					>
						Submit
					</button>
					
					<DeleteOwnerElement
						params={params}
						element={element}
						elementIndex={element_index}
						setSelectedElement={setSelectedElement}
						elements={elements}
						setElements={setElements}
					/>
				</div>
			</form>
		)}
	</Formik>
})


export const ElementVideoEdit = injectIntl(({
	params,
	library,
	lesson,
	element,
	intl,
	element_index,
	elements,
	setElements,
	setSelectedElement,
	videoUploadProgress,
	setVideoUploadProgress,
	transcodingFunc,
	videoTranscodingDetails,
	setVideoTranscodingDetails,
	videoTranscodingState,
	setVideoTranscodingState,
	...props
}) => {
	const [videoTempURL, setVideoTempURL] = useState({["video_" + element_index] : ""});
	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	// let Vimeo = require('vimeo').Vimeo;
	// let client = new Vimeo(process.env.REACT_APP_VIMEO_CLIENT, process.env.REACT_APP_VIMEO_SECRET, process.env.REACT_APP_VIMEO_ACCESS);

	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			element_index: element_index,
			description: element.attachments[0].description,
			link: element.attachments[0]?.context?.video_link,
			["file_" + element_index]: null,
			["video_link_" + element_index]: ""
		}}
		validate={values => {
			const errors = {};

			if (!values["file_" + element_index] && !values.link) {
				errors["file_" + element_index] = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
				
				errors.link = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
			if(isEmpty(values.link)) {
				// client.upload(
				// 	values["file_" + element_index],
				// 	{
				// 		'name': library.party_id + "-" + library.title + "-" + lesson.resource_id + "-" + element.id,
				// 	},
				// 	function (uri) {
				// 		const vidURI = uri.split("/")
				// 		values["file_" + element_index] = vidURI[2];
				// 		values["video_link_" + element_index] = process.env.REACT_APP_VIMEO_URL + vidURI[2];
				// 		create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
				// 			if(new_element_attachment) {
				// 				let newElements = [...elements];
				// 				newElements[element_index].attachments.push(new_element_attachment);
				// 				setElements([]);
				// 				setElements(newElements);
				// 				// window.scrollTo(0,document.body.scrollHeight);
	
				// 				setVideoTranscodingDetails(prevState => ([
				// 					...prevState,
				// 					{["transcoding_details"]: {
				// 						end_point: vidURI[2],
				// 						element_index: element_index
				// 					}}
				// 				]))
	
				// 				const data = videoTranscodingState;
				// 				data["transcoding_state_" + element_index] = {
				// 					interval_status: "pending",
				// 					progress: 0
				// 				}
	
				// 				setVideoTranscodingState(data)
	
				// 				snackbarAlert("success", "Upload successful!  We are now optimizing your video for more efficient data usage.  While waiting, you can proceed with the rest of your activities.");
				// 			}
				// 			setSubmitting(false);
				// 		}).catch(err => {
				// 			console.log(err);
				// 			setSubmitting(false);
				// 		});
				// 	},
				// 	function (bytes_uploaded, bytes_total) {
				// 		var percentage = (bytes_uploaded / bytes_total * 100).toFixed(2)
				// 		setVideoUploadProgress(prevState => ({
				// 			...prevState,
				// 			["progress_" + element_index]: percentage
				// 		}));
				// 	},
				// 	function (error) {
				// 		console.log('Failed because: ' + error)
				// 	}
				// )
			}
			else {
				update_owner_element_attachment(values).then(({ data: { element_query } }) => {
					snackbarAlert("success", "Changes saved!");
					element.attachments[0].context.video_link = values.link;
					element.attachments[0].description = values.description;
					setSubmitting(false);
					props.setEditElementId(0);
				}).catch(err => {
					console.log(err);
					setSubmitting(false);
				});
			}
			
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
				{/* <input
					id={"video_input_" + element_index}
					type="file"
					name={"video_input_" + element_index}
					accept=".mp4"
					className="d-none"
					onChange={videoBLOB => {
						let reader = new FileReader();

						if (videoBLOB.currentTarget.files[0]) {
							setFieldValue(
								["file_" + element_index],
								videoBLOB.currentTarget.files[0]
							);
							reader.readAsDataURL(
								videoBLOB.currentTarget.files[0]
							);
							reader.onload = video => {
								setVideoTempURL({["video_" + element_index]: video.target.result});
							};
						}
					}}
				/> */}

				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>
				
				<div style={{display: "inline-grid"}}>
					{
						videoUploadProgress["progress_" + element_index] > 0 ? (
							<>
								<Typography>
									Uploading: &ensp; <span style={{fontWeight: "bold"}}>{videoUploadProgress["progress_" + element_index]}%</span>
								</Typography>
								<div
									className="rounded-pill mr-2 mb-5"
									style={{
										width: `${videoUploadProgress["progress_" + element_index]}%`,
										height: 10, backgroundColor: "blue"
									}}
								/>
							</>
						)
						: (
							<div style={{display: "flex"}}>
								{
									// isEmpty(values.link) && (
									// 	<label htmlFor={"video_input_" + element_index} className="btn">
									// 		{
									// 			!isEmpty(values.link) ? (
									// 				<div className="text-center">
									// 					<a
									// 						href={window.URL.createObjectURL(values["file_" + element_index])}
									// 						download={values["file_" + element_index].name}
									// 						className="btn p-0"
									// 					>
									// 						<span>{values["file_" + element_index].name}</span>
									// 						<br />
									// 						<span>Check!</span>
									// 					</a>
	
									// 					<label htmlFor={"video_input_" + element_index} className="text-center w-100 m-0 p-0 btn">
									// 						<img
									// 							width="50"
									// 							src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/video_icon.png"
									// 							alt="video"
									// 							className="d-block mx-auto"
									// 							style={{ maxHeight: "20vh" }}
									// 						/>
									// 						<span>Change!</span>
									// 					</label>
									// 				</div>
									// 			)
									// 			: (
									// 				<>
									// 					<img
									// 						alt="upload"
									// 						className="w-100 d-block rounded border p-2"
									// 						src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/video_icon.png"
									// 						style={{ maxHeight: "20vh" }}
									// 					/>
									// 					<Typography>
									// 						Attach
									// 					</Typography>
									// 					{Boolean(touched["file_" + element_index] && errors["file_" + element_index]) && <Typography align="center" className="text-danger">
									// 						Video upload is required
									// 					</Typography>}
									// 				</>
									// 			)
									// 		}
									// 	</label>
									// )
								}

								<div style={{display: "flex", alignItems: "center"}}>
									Or, paste the link here: &nbsp;
									<div className="form-group" style={{width: "400px"}}>
										<TextField
											label="Vimeo Link"
											color="secondary"
											multiline
											name="link"
											placeholder="Paste link here"
											margin="normal"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.link}
											helperText={touched.link && errors.link}
											error={Boolean(touched.link && errors.link)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</div>
								</div>
							</div>
						)
					}
				</div>

				<div style={{display: "flex", justifyContent: "center"}}>
					<button
						type="submit"
						disabled={isSubmitting}
						className="btn btn-success my-auto mr-1"
					>
						Submit
					</button>
					
					<DeleteOwnerElement
						params={params}
						element={element}
						elementIndex={element_index}
						setSelectedElement={setSelectedElement}
						elements={elements}
						setElements={setElements}
					/>
				</div>
			</form>
		)}
	</Formik>
})

export const ElementVideoView = injectIntl(({ attachment, videoTranscodingState, setVideoTranscodingState, elements, setElements, element_index }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}

		{(() => {
			return (attachment.context.video_link !== null && isLinkAVideo(attachment.context.video_link)) ? (
				<ReactPlayer
					style={{ borderRadius: 20, overflow: "hidden" }}
					width="100%"
					height="100%"
					className={"reactPlayer"}
					url={attachment.context.video_link}
					controls={true}
				/>
			)
			: (
				<iframe
					src={attachment.context.video_link}
					style={{
						width: '100%',
						minHeight: "50vh",
						height: '100%',
						border: '0',
						overflow: 'hidden'
					}}
				></iframe>
			)
			// if((attachment.context.video_link ?? "").includes(process.env.REACT_APP_VIMEO_URL))
			// {
			// 	return <>
			// 		<Vimeo
			// 			style={{ borderRadius: 20, overflow: "hidden" }}
			// 			className="rounded w-75 mx-auto"
			// 			video={attachment.context.video_link}
			// 			showTitle
			// 			responsive={true}
			// 		/>
			// 	</>
			// }
			// else {
			// 	if(typeof videoTranscodingState["transcoding_state_" + element_index] === "undefined") {
			// 		return <>
			// 			<Typography className="text-center">
			// 				<span style={{fontWeight: "bold"}}>Loading Please Wait...</span>
			// 			</Typography>
			// 		</>
			// 	}
			// 	else if(videoTranscodingState["transcoding_state_" + element_index].progress == 0) {
			// 		return <>
			// 			<Typography className="text-center">
			// 				<span style={{fontWeight: "bold"}}>Loading Please Wait...</span>
			// 			</Typography>
			// 		</>
			// 	}
			// 	else if(videoTranscodingState["transcoding_state_" + element_index].progress < 100) {
			// 		return <>
			// 			<Typography className="text-center">
			// 				Transcoding: &ensp; <span style={{fontWeight: "bold"}}>{videoTranscodingState["transcoding_state_" + element_index] ? videoTranscodingState["transcoding_state_" + element_index].progress : 0}%</span>
	
			// 			</Typography>
			// 			<div className="rounded-pill mr-2 mb-5" style={{ width: `${videoTranscodingState["transcoding_state_" + element_index] ? videoTranscodingState["transcoding_state_" + element_index].progress : 0}%`, height: 10, backgroundColor: "blue" }} />
			// 		</>
			// 	}
			// 	else {
			// 		return <>
			// 			{(() => {
			// 				if(Boolean((attachment.context.video_link ?? "").includes("https://player.vimeo.com/video/") ||
			// 					(attachment.context.video_link ?? "").includes(process.env.REACT_APP_VIMEO_URL))) {
			// 					return (
			// 						<div>
			// 							{(!videoTranscodingState["transcoding_state_" + element_index].embedded) && (
			// 								<Typography className="text-center">
			// 									Embedding Video: &ensp; <span style={{fontWeight: "bold"}}>Please Wait!</span>
						
			// 								</Typography>
			// 							)}
										
			// 							<Vimeo
			// 								style={{ borderRadius: 20, overflow: "hidden" }}
			// 								className="rounded w-75 mx-auto"
			// 								video={attachment.context.video_link}
			// 								showTitle
			// 								responsive={true}
			// 								onError={(err) => {
			// 									const timer = setTimeout(() => {
			// 										let data = {...videoTranscodingState};
			// 										data["transcoding_state_" + element_index].embedded = false
			// 										setVideoTranscodingState(data);
													
			// 										// let tempElement = [...elements];
			// 										// let origElement = [...elements];
			// 										// tempElement.splice(element_index, 1);
			// 										// setElements(tempElement);
			// 										// setElements(origElement);
			// 									}, 20000)
			// 								}}
			// 								onReady={(res) => {
			// 									let data = {...videoTranscodingState};
			// 									data["transcoding_state_" + element_index].embedded = true
			// 									setVideoTranscodingState(data);
			// 								}}
			// 							/>
			// 						</div>
			// 					);
				
			// 				} else if(Boolean((attachment.context.video_link ?? "").includes("https://www.youtube.com")) || 
			// 					Boolean((attachment.context.video_link ?? "").includes("https://youtu.be/"))) {
			// 					return (
			// 						<div className="w-75 mx-auto">
			// 							{/* <Youtube
			// 								className="rounded w-100 mx-auto"
			// 								video={
			// 								(
			// 									attachment.context.video_link
			// 									.replace("https://www.youtube.com/embed/", "")
			// 									.replace("https://youtu.be/", "")
			// 									.replace("https://www.youtube.com/watch?v=", "")
			// 									).slice(0, 11)
			// 								}
			// 								showInfo
			// 								height={400}
			// 								responsive={true}
			// 							/> */}
				
			// 							Youtube is coming soon...
			// 						</div>
			// 					);
							
			// 				}
			// 			})()}
			// 		</>
			// 	}
			// }
		})()}
	</>
})
// End of Video Component

// Start of Text Component
export const ElementTextCreate = injectIntl(({ params, element, intl, element_index, elements, setElements, setSelectedElement }) => {
	return <Formik
		initialValues={{
		  library_party_id: params.library_party_id,
		  module_resource_id: params.module_resource_id,
		  lesson_resource_id: params.lesson_resource_id,
		  element_type_id: element.element_type_id,
		  element_id: element.id,
		  file: null,
		  text_content: ""
		}}
		validate={values => {
			const errors = {};
	
			if (!values.text_content) {
				errors.text_content = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}
			
			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>

			<div className="form-group">
				<TextField
					label="Plain Multiline Text"
					color="secondary"
					multiline
					InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
					name="text_content"
					placeholder="Insert text content here"
					margin="normal"
					variant="outlined"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.text_content}
					helperText={touched.text_content && errors.text_content}
					error={Boolean(touched.text_content && errors.text_content)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>
  
			<div style={{display: "inline-flex"}}>
				<button
					type="submit"
					disabled={isSubmitting}
					className="btn btn-success my-auto mr-1"
				>
					Submit
				</button>
				
				<DeleteOwnerElement
					params={params}
					element={element}
					elementIndex={element_index}
					setSelectedElement={setSelectedElement}
					elements={elements}
					setElements={setElements}
				/>
			</div>
		  </form>
		)}
	</Formik>
})

export const ElementTextEdit = injectIntl(({
	params,
	element,
	intl,
	element_index,
	elements,
	setElements,
	setEditElementId,
	isCreate
}) => {
	return <>
		<Formik
			enableReinitialize={true}
			initialValues={{
				library_party_id: params.library_party_id,
				module_resource_id: params.module_resource_id,
				lesson_resource_id: params.lesson_resource_id,
				element_type_id: element.element_type_id,
				element_id: element.id,
				file: null,
				text_content: element.attachments[0]?.context.text_content,
				ai_customized: element.attachments[0]?.context.ai_customized,
				ai_prompt: element.attachments[0]?.context.ai_prompt,
				ai_auto_replace_text: element.attachments[0]?.context.ai_auto_replace_text,
				ai_use_default_text: isEmpty(element.attachments[0]?.context.ai_use_default_text) ? 1 : 0,
				ai_is_custom_label: isEmpty(element.attachments[0]?.context.ai_is_custom_label) ? 0 : element.attachments[0]?.context.ai_is_custom_label,
				ai_custom_label: isEmpty(element.attachments[0]?.context.ai_custom_label) ? "" : element.attachments[0]?.context.ai_custom_label,
				show_test_modal: false,
				test_content: "",
				ai_prompt_industy: "",
				ai_prompt_team: "",
				ai_prompt_position: "",
				ai_prompt_product: "",
				ai_prompt_product_description: "",
				has_quick_access_link: isEmpty(element.attachments[0]?.context.has_quick_access_link) ? 0 : element.attachments[0]?.context.has_quick_access_link,
				quick_access_link: isEmpty(element.attachments[0]?.context.quick_access_link) ? generateRandomString(8) : element.attachments[0]?.context.quick_access_link,
			}}
			validate={values => {
				const errors = {};
		
				if (!values.text_content) {
					errors.text_content = intl.formatMessage({
						id: "AUTH.VALIDATION.REQUIRED_FIELD"
					});
				} 
	
				return errors;
			}}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				if(isCreate) {
					create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
						if(new_element_attachment) {
							let newElements = [...elements];
							newElements[element_index].attachments.push(new_element_attachment);
							setElements([]);
							setElements(newElements);
							// window.scrollTo(0,document.body.scrollHeight);
						}
						setSubmitting(false);
					}).catch(err => {
						console.log(err);
						setSubmitting(false);
					});
				}
				else {
					update_owner_element_attachment(values).then(({ data: { element_query } }) => {
						if(element_query) {
							let newElements = [...elements];
							newElements[element_index] = element_query;
							setElements([]);
							setElements(newElements);
							setEditElementId(0)
							// window.scrollTo(0,document.body.scrollHeight);
						}
						setSubmitting(false);
					}).catch(err => {
						console.log(err);
						setSubmitting(false);
					});
				}
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				setSubmitting,
				setFieldValue,
				handleReset
			}) => (
				<form
					onSubmit={handleSubmit}
					noValidate
					autoComplete="off"
				>
					<AIPromptModal
						values={values}
						handleBlur={handleBlur}
						handleChange={handleChange}
						isSubmitting={isSubmitting}
						setSubmitting={setSubmitting}
						setFieldValue={setFieldValue}
					/>
					<div className="form-group">
						<TextField
							label="Plain Multiline Text"
							color="secondary"
							multiline
							InputProps={{
								style: {
									minHeight: 100,
									alignItems: "flex-start",
									fontSize: "1.3rem"
								}
							}}
							name="text_content"
							placeholder="Insert text content here"
							margin="normal"
							variant="outlined"
							onBlur={handleBlur}
							onChange={handleChange}
							value={values.text_content}
							helperText={touched.text_content && errors.text_content}
							error={Boolean(touched.text_content && errors.text_content)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>
					<div className="form-group">
						<FormControlLabel
							style={{
								'display': 'block',
								'textAlign': 'start'
							}}
							control={
								<Checkbox
									checked={
										(values.ai_customized == 0 || typeof values.ai_customized === "undefined")
										? false
										: true
									}
									name="ai_customized"
									color="primary"
									onChange={() => {
										let newVal = values.ai_customized == 0 || typeof values.ai_customized === "undefined"
										? 1
										: 0

										setFieldValue('ai_customized', newVal);
									}}
								/>
							}
							label="Customize with AI"
						/>
					</div>

					{
						values.ai_customized == 1 && (
							<>
								<div className="form-group">
									<TextField
										label="Customize Prompt (Ex. {{industry}} {{team}} {{position}} {{product}} {{product_description}})"
										color="secondary"
										multiline
										InputProps={{
											style: {
												minHeight: 100,
												alignItems: "flex-start",
												fontSize: "1.3rem"
											}
										}}
										name="ai_prompt"
										placeholder="Customize prompt"
										margin="normal"
										variant="outlined"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.ai_prompt}
										helperText={touched.ai_prompt && errors.ai_prompt}
										error={Boolean(touched.ai_prompt && errors.ai_prompt)}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</div>

								<div className="form-group">
									<FormControlLabel
										style={{
											'display': 'block',
										}}
										control={
											<Checkbox
												checked={
													!values.ai_use_default_text
													? false
													: true
												}
												name="ai_use_default_text"
												color="primary"
												onChange={() => {
													let newVal = values.ai_use_default_text == 0 || typeof values.ai_use_default_text === "undefined"
													? 1
													: 0
				
													setFieldValue('ai_use_default_text', newVal);
												}}
											/>
										}
										label="Use default text for AI customization"
									/>
									<FormControlLabel
										style={{
											'display': 'block',
										}}
										control={
											<Checkbox
												checked={
													!values.ai_auto_replace_text
													? false
													: true
												}
												name="ai_auto_replace_text"
												color="primary"
												onChange={() => {
													let newVal = values.ai_auto_replace_text == 0 || typeof values.ai_auto_replace_text === "undefined"
													? 1
													: 0
				
													setFieldValue('ai_auto_replace_text', newVal);
												}}
											/>
										}
										label="Automatically replace the default text"
									/>
									<FormControlLabel
										style={{
											'display': 'block',
										}}
										control={
											<Checkbox
												checked={
													(values.ai_is_custom_label == 0 || values.ai_is_custom_label === "undefined")
													? false
													: true
												}
												name="ai_is_custom_label"
												color="primary"
												onChange={() => {
													let newVal =  values.ai_is_custom_label == 0 || values.ai_is_custom_label === "undefined"
													? 1
													: 0;

													setFieldValue('ai_is_custom_label', newVal);
												}}
											/>
										}
										label="Customize message before the AI output"
									/>

									{
										values.ai_is_custom_label == 1 && (
											<TextField
												label="Customize Label"
												color="secondary"
												multiline
												InputProps={{
													style: {
														minHeight: 100,
														alignItems: "flex-start",
														fontSize: "1.3rem"
													}
												}}
												name="ai_custom_label"
												placeholder="Customize prompt"
												margin="normal"
												variant="outlined"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.ai_custom_label}
												helperText={touched.ai_custom_label && errors.ai_custom_label}
												error={Boolean(touched.ai_custom_label && errors.ai_custom_label)}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										)
									}
								</div>
							</>
						)
					}

					<QuickAccessSettings
						values={values}
						handleBlur={handleBlur}
						handleChange={handleChange}
						isSubmitting={isSubmitting}
						setSubmitting={setSubmitting}
						setFieldValue={setFieldValue}
					/>
				
					<div className="form-group text-center">
						<button
							type="submit"
							disabled={isSubmitting}
							className="btn btn-success d-inline-block m-3"
						>
							Save
						</button>

						{
							values.ai_customized == 1 && (
								<button
									type="button"
									className="btn btn-warning d-inline-block m-3"
									onClick={() => {
										setFieldValue('show_test_modal', !values.show_test_modal);
									}}
								>
									Test
								</button>
							)
						}
					</div>
				</form>
			)}
		</Formik>
	</>
})

export const ElementTextView = ({ attachment }) => {
	return <>
		<div>
			<Typography variant="body1" style={{whiteSpace : "pre-line", fontSize: "1.3rem"}}>
				{attachment.context.text_content}
			</Typography> 
		</div>
	</>
}
// End of Text Component

// Start of Reflection Component
export const ElementReflectionCreate = injectIntl(({ params, element, intl, element_index, elements, setElements, setSelectedElement }) => {
	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			file: null,
			description: "",
			is_shared: "shared",
		}}
		validate={values => {
			const errors = {};

			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
				<RadioGroup
					aria-label="module"
					onChange={e => setFieldValue("is_shared", e.target.value, false)}
					value={values.is_shared ?? "shared"}
					row
				>
					<label htmlFor={"is_shared"} className="btn pl-0 mb-0">Response Content:</label>
					<FormControlLabel
						id={"is_shared"}
						name={"is_shared"}
						value="shared"
						control={<Radio />}
						label="Shared"
						className="p-0 m-0"
					/>
					<FormControlLabel
						id={"is_shared"}
						name={"is_shared"}
						value="private"
						control={<Radio />}
						label="Private"
						className="p-0 m-0"
					/>

				</RadioGroup>
					
				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<div style={{display: "inline-flex"}}>
					<div>
						<button
							type="submit"
							disabled={isSubmitting}
							// className="btn btn-success d-block mx-auto"
							className="btn btn-success my-auto mr-1"
						>
							Submit
						</button>
					</div>
					
					<DeleteOwnerElement
						params={params}
						element={element}
						elementIndex={element_index}
						setSelectedElement={setSelectedElement}
						elements={elements}
						setElements={setElements}
					/>
				</div>
			</form>
		)}
	</Formik>
})

export const ElementReflectionEdit = injectIntl(({ params, element, intl, element_index, elements, setElements, setEditElementId }) => {
	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			file: null,
			description: element.attachments[0].description,
			is_shared: element.attachments[0].context.is_shared,
			has_quick_access_link: isEmpty(element.attachments[0]?.context.has_quick_access_link) ? 0 : element.attachments[0]?.context.has_quick_access_link,
			quick_access_link: isEmpty(element.attachments[0]?.context.quick_access_link) ? generateRandomString(8) : element.attachments[0]?.context.quick_access_link,
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			update_owner_element_attachment(values).then(({ data: { element_query } }) => {
				if(element_query) {
					let newElements = [...elements];
					newElements[element_index] = element_query;
					setElements([]);
					setElements(newElements);
					setEditElementId(0)
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
				<RadioGroup
					aria-label="module"
					onChange={e => setFieldValue("is_shared", e.target.value, false)}
					value={values.is_shared ?? "shared"}
					row
				>
					<label htmlFor={"is_shared"} className="btn pl-0 mb-0">Response Content:</label>
					<FormControlLabel
						id={"is_shared"}
						name={"is_shared"}
						value="shared"
						control={<Radio />}
						label="Shared"
						className="p-0 m-0"
					/>
					<FormControlLabel
						id={"is_shared"}
						name={"is_shared"}
						value="private"
						control={<Radio />}
						label="Private"
						className="p-0 m-0"
					/>

				</RadioGroup>

				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<QuickAccessSettings
					values={values}
					handleBlur={handleBlur}
					handleChange={handleChange}
					isSubmitting={isSubmitting}
					setSubmitting={setSubmitting}
					setFieldValue={setFieldValue}
				/>

				<button
					type="submit"
					disabled={isSubmitting}
					className="btn btn-success d-block mx-auto"
				>
					Submit
				</button>
			</form>
		)}
	</Formik>
})

export const ElementReflectionView = ({ attachment, element, params }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Reflection" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/reflection_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.context.is_shared === "shared" ? "Shared: ": "Private: "} How does this apply to you?
			</Typography> 
		</div>

		<hr />

		{Boolean(attachment.description) ? <>
			<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="font-weight-bolder">
				Description:
			</Typography>

			<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
				{attachment.description}
			</Typography>

			<ShowResponses params={params} element={element} attachment={attachment} owner={true} />
		</> : <>
			<ShowResponses params={params} element={element} attachment={attachment} owner={true} />
		</>}
	</>
}
// End of Reflection Component

// Start of Signature Component
export const ElementSignatureCreate = injectIntl(({ params, element, intl, element_index, elements, setElements, setSelectedElement }) => {
	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			file: null,
			title: ""
		}}
		validate={values => {
			const errors = {};

			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
  
			  <div className="form-group">
				  <TextField
					  label="Signature Header"
					  color="secondary"
					  name="title"
					  placeholder="Confirmation needed"
					  margin="normal"
					  onBlur={handleBlur}
					  onChange={handleChange}
					  value={values.title}
					  helperText={touched.title && errors.title}
					  error={Boolean(touched.title && errors.title)}
					  InputLabelProps={{
						  shrink: true,
					  }}
				  />
			  </div>
  
			  <div className="form-group">
				  <TextField
					  label="Description"
					  color="secondary"
					  multiline
					  name="description"
					  placeholder="Please input your COMPLETE NAME or SIGNATURE below to signify that you have read and agree with the terms above."
					  margin="normal"
					  variant="outlined"
					  onBlur={handleBlur}
					  onChange={handleChange}
					  value={values.description}
					  helperText={touched.description && errors.description}
					  error={Boolean(touched.description && errors.description)}
					  InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
					  InputLabelProps={{
						  shrink: true,
					  }}
				  />
			  </div>
  
			<div style={{display: "inline-flex"}}>
				<button
					type="submit"
					disabled={isSubmitting}
					className="btn btn-success my-auto mr-1"
				>
					Submit
				</button>
				
				<DeleteOwnerElement
					params={params}
					element={element}
					elementIndex={element_index}
					setSelectedElement={setSelectedElement}
					elements={elements}
					setElements={setElements}
				/>
			</div>
		  </form>
		)}
	</Formik>
})

export const ElementSignatureEdit = injectIntl(({ params, element, intl, element_index, elements, setElements, setEditElementId }) => {
	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			file: null,
			title: element.attachments[0].title,
			description: element.attachments[0].description,
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			update_owner_element_attachment(values).then(({ data: { element_query } }) => {
				if(element_query) {
					let newElements = [...elements];
					newElements[element_index] = element_query;
					setElements([]);
					setElements(newElements);
					setEditElementId(0)
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
  
			  <div className="form-group">
				  <TextField
					  label="Signature Header"
					  color="secondary"
					  name="title"
					  placeholder="Confirmation needed"
					  margin="normal"
					  onBlur={handleBlur}
					  onChange={handleChange}
					  value={values.title}
					  helperText={touched.title && errors.title}
					  error={Boolean(touched.title && errors.title)}
					  InputLabelProps={{
						  shrink: true,
					  }}
				  />
			  </div>
  
			  <div className="form-group">
				  <TextField
					  label="Description"
					  color="secondary"
					  multiline
					  name="description"
					  placeholder="Please input your COMPLETE NAME or SIGNATURE below to signify that you have read and agree with the terms above."
					  margin="normal"
					  variant="outlined"
					  onBlur={handleBlur}
					  onChange={handleChange}
					  value={values.description}
					  helperText={touched.description && errors.description}
					  error={Boolean(touched.description && errors.description)}
					  InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
					  InputLabelProps={{
						  shrink: true,
					  }}
				  />
			  </div>
  
			  <button
				  type="submit"
				  disabled={isSubmitting}
				  className="btn btn-success d-block mx-auto"
			  >
				  Submit
			  </button>
		  </form>
		)}
	</Formik>
})
  
export const ElementSignatureView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Signature" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/signature_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title}
			</Typography> 
		</div>

		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 
			</div>
		)}
	</>
}
  // End of Signature Component

// Start of Survey Component
export const ElementSurveyCreate = injectIntl(({ params, element, intl, element_index, elements, setElements, setSelectedElement }) => {
	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			file: null,
			title: "",
			description: "",
			choices: [{ description: "" }, { description: "" }],
			explanation_description: "",
			require_explanation: false
		}}
		validationSchema={yup.object().shape({
			choices: yup.array().of(yup.object().shape(
				{ description: yup.string().required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"})) }
			))
		})}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			let new_values = { ...values };
			new_values.choices = JSON.stringify(values.choices);
			create_owner_element_attachment(new_values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
				<div className="form-group">
					<TextField
						label="Title"
						color="secondary"
						multiline
						name="title"
						placeholder="Let's take a short survey..."
						margin="normal"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.title}
						helperText={touched.title && errors.title}
						error={Boolean(touched.title && errors.title)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<FieldArray 
					name="choices"
					render={(choices) => {
						return <div>
							{values.choices.map((indiv_choice, choice_index) => {
								return <div key={choice_index}>
									<div className="form-group mb-2 d-flex align-items-center">
										<TextField
											label={`Choice ${choice_index + 1}`}
											color="secondary"
											multiline
											name={`choices.${choice_index}.description`}
											placeholder={`Input choice ${choice_index + 1}`}
											margin="normal"
											variant="outlined"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.choices[choice_index].description}
											helperText={
												touched.choices &&
												touched.choices[choice_index] &&
												touched.choices[choice_index].description &&
												errors.choices && 
												errors.choices[choice_index] && 
												errors.choices[choice_index].description
											}
											error={Boolean(
												touched.choices &&
												touched.choices[choice_index] &&
												touched.choices[choice_index].description &&
												errors.choices && 
												errors.choices[choice_index] && 
												errors.choices[choice_index].description
											)}
											InputLabelProps={{
												shrink: true,
											}}
										/>

										<button 
											type="button"
											className="btn text-danger"
											onClick={() => {
												if(values.choices.length > 2) {
													choices.remove(choice_index);
												}
											}}
										>
											Delete
										</button>
									</div>
								</div>
							})}
							
							<button
								type="button"
								className="btn btn-info d-block ml-auto"
								onClick={() => choices.push({ description: "" })}
							>
								Add More Choices
							</button>
						</div>
					}}
				/>

				<div className="d-flex align-items-center mt-3">
					<Typography>
						Require Explanation?:
					</Typography>
					<FormControlLabel
						className="m-0"
						control={
							<Switch
								onClick={e => setFieldValue("require_explanation", !values.require_explanation)}
								checked={values.require_explanation}
							/>
						}
						label={values.require_explanation && "Yes"}
					/>
				</div>


				{values.require_explanation && <div className="form-group">
					<TextField
						label="Survey Explanation"
						color="secondary"
						multiline
						name="explanation_description"
						placeholder="Please explain your answer..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.explanation_description}
						helperText={touched.explanation_description && errors.explanation_description}
						error={Boolean(touched.explanation_description && errors.explanation_description)}
						InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>}

				<div style={{display: "inline-flex"}}>
					<button
						type="submit"
						disabled={isSubmitting}
						className="btn btn-success my-auto mr-1"
					>
						Submit
					</button>
					
					<DeleteOwnerElement
						params={params}
						element={element}
						elementIndex={element_index}
						setSelectedElement={setSelectedElement}
						elements={elements}
						setElements={setElements}
					/>
				</div>
			</form>
		)}
	</Formik>
})

export const ElementSurveyEdit = injectIntl(({ params, element, intl, element_index, elements, setElements, setEditElementId }) => {
	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			file: null,
			title: element.attachments[0].title,
			description: element.attachments[0].description,
			choices: element.attachments[0].attachment_additionals,
			explanation_description: element.attachments[0].context.explanation_description,
			require_explanation: element.attachments[0].context.require_explanation,
			has_quick_access_link: isEmpty(element.attachments[0]?.context.has_quick_access_link) ? 0 : element.attachments[0]?.context.has_quick_access_link,
			quick_access_link: isEmpty(element.attachments[0]?.context.quick_access_link) ? generateRandomString(8) : element.attachments[0]?.context.quick_access_link,
		}}
		validationSchema={yup.object().shape({
			choices: yup.array().of(yup.object().shape(
				{ description: yup.string().required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"})) }
			))
		})}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			let new_values = { ...values };
			new_values.choices = JSON.stringify(values.choices);
			update_owner_element_attachment(new_values).then(({ data: { element_query } }) => {
				if(element_query) {
					let newElements = [...elements];
					newElements[element_index] = element_query;
					setElements([]);
					setElements(newElements);
					setEditElementId(0)
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<div className="form-group">
					<TextField
						label="Title"
						color="secondary"
						multiline
						name="title"
						placeholder="Let's take a short survey..."
						margin="normal"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.title}
						helperText={touched.title && errors.title}
						error={Boolean(touched.title && errors.title)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<FieldArray 
					name="choices"
					render={(choices) => {
						return <div>
							{values.choices.map((indiv_choice, choice_index) => {
								return <div key={choice_index}>
									<div className="form-group mb-2 d-flex align-items-center">
										<TextField
											label={`Choice ${choice_index + 1}`}
											color="secondary"
											multiline
											name={`choices.${choice_index}.description`}
											placeholder={`Input choice ${choice_index + 1}`}
											margin="normal"
											variant="outlined"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.choices[choice_index].description}
											helperText={
												touched.choices &&
												touched.choices[choice_index] &&
												touched.choices[choice_index].description &&
												errors.choices && 
												errors.choices[choice_index] && 
												errors.choices[choice_index].description
											}
											error={Boolean(
												touched.choices &&
												touched.choices[choice_index] &&
												touched.choices[choice_index].description &&
												errors.choices && 
												errors.choices[choice_index] && 
												errors.choices[choice_index].description
											)}
											InputLabelProps={{
												shrink: true,
											}}
										/>

										<button 
											type="button"
											className="btn text-danger"
											onClick={() => {
												if(values.choices.length > 2) {
													choices.remove(choice_index);
												}
											}}
										>
											Delete
										</button>
									</div>
								</div>
							})}
							
							<button
								type="button"
								className="btn btn-info d-block ml-auto"
								onClick={() => choices.push({ description: "" })}
							>
								Add More Choices
							</button>
						</div>
					}}
				/>

				<div className="d-flex align-items-center mt-3">
					<Typography>
						Require Explanation?:
					</Typography>
					<FormControlLabel
						className="m-0"
						control={
							<Switch
								onClick={e => setFieldValue("require_explanation", !values.require_explanation)}
								checked={values.require_explanation}
							/>
						}
						label={values.require_explanation && "Yes"}
					/>
				</div>

				<QuickAccessSettings
					values={values}
					handleBlur={handleBlur}
					handleChange={handleChange}
					isSubmitting={isSubmitting}
					setSubmitting={setSubmitting}
					setFieldValue={setFieldValue}
				/>


				{values.require_explanation && <div className="form-group">
					<TextField
						label="Survey Explanation"
						color="secondary"
						multiline
						name="explanation_description"
						placeholder="Please explain your answer..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.explanation_description}
						helperText={touched.explanation_description && errors.explanation_description}
						error={Boolean(touched.explanation_description && errors.explanation_description)}
						InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>}

				<button
					type="submit"
					disabled={isSubmitting}
					className="btn btn-success d-block mx-auto"
				>
					Submit
				</button>
			</form>
		)}
	</Formik>
})

export const ElementSurveyView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Reflection" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/survey_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title || "Let's take a Survey"}
			</Typography> 
		</div>

		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="font-weight-bolder">
					Description:
				</Typography>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
					{attachment.description}
				</Typography> 
			</div>
		)}

		<div className="row">
			<div className="col-lg-4 d-flex align-items-center justify-content-center">
				<div>
					<Typography variant="h6" className="font-weight-bold mb-3">
						Choices:
					</Typography>
					<ol style={{ listStyleType: "square" }}>
						{attachment.attachment_additionals.map((indiv_additional, additinal_index) => {
							return <li key={additinal_index} className="mb-3">
								<Typography variant="h6">
									{indiv_additional.description} ({indiv_additional.responses && indiv_additional.responses.length ? indiv_additional.responses.length : 0})
								</Typography>
							</li>
						})}
					</ol>
				</div>
			</div>
			<div className="col-lg-8 d-flex align-items-center justify-content-center">
				<div className="w-100">
					<DoughnutChart 
						chartLabel="Survey Data" 
						dataLabel={attachment.attachment_additionals.map(indiv_additional => {
							return indiv_additional.description;
						})}
						data={attachment.attachment_additionals.map(indiv_additional => {
							return indiv_additional.responses ? indiv_additional.responses.length : 0;
						})}
					/>
				</div>
			</div>
		</div>

		{Boolean(attachment.context.require_explanation) && <>
			<hr />
			<Typography className="mb-3">
				<span className="font-weight-bold">Explanation Required: </span>{attachment.context.require_explanation ? "Yes" : "No"}
			</Typography>
			<Typography className="font-weight-bold">
				Description:
			</Typography>
			<Typography>
				{attachment.context.explanation_description}
			</Typography>
		</>}
	</>
}
// End of Survey Component

// Start of Link Component
export const ElementLinkCreate = injectIntl(({ params, element, intl, element_index, elements, setElements, setSelectedElement }) => {
	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			title: "",
			description: "",
			links: [{ title: "", description: "" }],
		}}
		validate={values => {
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			let new_values = { ...values };
			new_values.links = JSON.stringify(values.links);
			create_owner_element_attachment(new_values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
				<div className="form-group">
					<TextField
						label="Title"
						color="secondary"
						multiline
						name="title"
						placeholder="Title"
						margin="normal"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.title}
						helperText={touched.title && errors.title}
						error={Boolean(touched.title && errors.title)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<FieldArray 
					name="links"
					render={links => (<>
						<div className="row" style={{maxHeight: "400px", overflowY: "auto"}}>
							{values.links.map((indiv_link, link_index) => {
								return <div key={link_index} className="col-6">
									<div style={{textAlign: "center"}}>
										<div>
											{(link_index != 0) ?
												<IconButton
													onClick={() => {
														let links = values.links;
														links.splice(link_index, 1);
														setFieldValue("links", [...links]);
													}}
												>
													<DeleteIcon style={{color: "red"}}/>
												</IconButton>
												: <div></div>
											}

											<TextField
												label="Link"
												color="secondary"
												multiline
												name={`links.${link_index}.title`}
												placeholder="Insert link here"
												margin="normal"
												style={{width: "80%"}}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.links[link_index].title}
												helperText={
													touched.links &&
													touched.links[link_index] &&
													touched.links[link_index].title &&
													errors.links && 
													errors.links[link_index] && 
													errors.links[link_index].title
												}
												error={Boolean(
													touched.links &&
													touched.links[link_index] &&
													touched.links[link_index].title &&
													errors.links && 
													errors.links[link_index] && 
													errors.links[link_index].title
												)}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										</div>
										
										<TextField
											label="Description"
											color="secondary"
											multiline
											name={`links.${link_index}.description`}
											placeholder="Description is not required and could be multiple lines..."
											variant="outlined"
											margin="normal"
											style={{width: "80%"}}
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.links[link_index].description}
											helperText={
												touched.links &&
												touched.links[link_index] &&
												touched.links[link_index].description &&
												errors.links && 
												errors.links[link_index] && 
												errors.links[link_index].description
											}
											error={Boolean(
												touched.links &&
												touched.links[link_index] &&
												touched.links[link_index].description &&
												errors.links && 
												errors.links[link_index] && 
												errors.links[link_index].description
											)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</div>
								</div>
							})}
						</div>

						<div className="form-group" style={{textAlignLast: "center"}}>
							<button
								type="button"
								className="btn text-success" 
								onClick={() => links.push({ title: "", description: "" })}
							>
								Add More Links
							</button>
						</div>
						
					</>)}
				/>

				<div style={{display: "inline-flex"}}>
					<button
						type="submit"
						disabled={isSubmitting}
						className="btn btn-success my-auto mr-1"
					>
						Submit
					</button>
					
					<DeleteOwnerElement
						params={params}
						element={element}
						elementIndex={element_index}
						setSelectedElement={setSelectedElement}
						elements={elements}
						setElements={setElements}
					/>
				</div>
			</form>
		)}
	</Formik>
})

export const ElementLinkEdit = injectIntl(({ params, element, intl, element_index, elements, setElements, setEditElementId }) => {
	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			title: element.attachments[0].title,
			description: element.attachments[0].description,
			links: element.attachments[0].attachment_additionals,
		}}
		validate={values => {
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			let new_values = { ...values };
			new_values.links = JSON.stringify(values.links);
			update_owner_element_attachment(new_values).then(({ data: { element_query } }) => {
				if(element_query) {
					let newElements = [...elements];
					newElements[element_index] = element_query;
					setElements([]);
					setElements(newElements);
					setEditElementId(0)
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<div className="form-group">
					<TextField
						label="Title"
						color="secondary"
						multiline
						name="title"
						placeholder="Title"
						margin="normal"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.title}
						helperText={touched.title && errors.title}
						error={Boolean(touched.title && errors.title)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<FieldArray 
					name="links"
					render={links => (<>
						<div className="row" style={{maxHeight: "400px", overflowY: "auto"}}>
							{values.links.map((indiv_link, link_index) => {
								return <div key={link_index} className="col-6">
									<div style={{textAlign: "center"}}>
										<div>
											{(link_index != 0) ?
												<IconButton
													onClick={() => {
														let links = values.links;
														links.splice(link_index, 1);
														setFieldValue("links", [...links]);
													}}
												>
													<DeleteIcon style={{color: "red"}}/>
												</IconButton>
												: <div></div>
											}

											<TextField
												label="Link"
												color="secondary"
												multiline
												name={`links.${link_index}.title`}
												placeholder="Insert link here"
												margin="normal"
												style={{width: "80%"}}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.links[link_index].title}
												helperText={
													touched.links &&
													touched.links[link_index] &&
													touched.links[link_index].title &&
													errors.links && 
													errors.links[link_index] && 
													errors.links[link_index].title
												}
												error={Boolean(
													touched.links &&
													touched.links[link_index] &&
													touched.links[link_index].title &&
													errors.links && 
													errors.links[link_index] && 
													errors.links[link_index].title
												)}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										</div>
										
										<TextField
											label="Description"
											color="secondary"
											multiline
											name={`links.${link_index}.description`}
											placeholder="Description is not required and could be multiple lines..."
											variant="outlined"
											margin="normal"
											style={{width: "80%"}}
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.links[link_index].description}
											helperText={
												touched.links &&
												touched.links[link_index] &&
												touched.links[link_index].description &&
												errors.links && 
												errors.links[link_index] && 
												errors.links[link_index].description
											}
											error={Boolean(
												touched.links &&
												touched.links[link_index] &&
												touched.links[link_index].description &&
												errors.links && 
												errors.links[link_index] && 
												errors.links[link_index].description
											)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</div>
								</div>
							})}
						</div>

						<div className="form-group" style={{textAlignLast: "center"}}>
							<button
								type="button"
								className="btn text-success" 
								onClick={() => links.push({ title: "", description: "" })}
							>
								Add More Links
							</button>
						</div>
						
					</>)}
				/>

				<button
					type="submit"
					disabled={isSubmitting}
					className="btn btn-success d-block mx-auto"
				>
					Submit
				</button>
			</form>
		)}
	</Formik>
})


export const ElementLinkView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Document" 
				src="https://lernnex.s3.amazonaws.com/element_type_icon/link_icon.png" 
				style={{ maxHeight: 50 }}
				className="pr-2"
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title || "Check this out"}
			</Typography> 
		</div>
		
		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 
			</div>
		)}

		<div className="row" style={{maxHeight: "400px", overflowY: "auto"}}>
			{attachment.attachment_additionals.map((attachment_additional, additional_index) => {
				return <div key={additional_index} className="col-6" style={{marginTop: "20px"}}>
					<div style={{marginBottom: "20px"}}>
						<ReactTinyLink
							cardSize="small"
							showGraphic={true}
							maxLine={2}
							minLine={1}
							url={attachment_additional.title}
						/>
					</div>
					<span style={{width: "500px"}}>{attachment_additional.description}</span>
				</div>
			})}
		</div>
	</>
}
// End of Link Component

// Start of Document Component
export const ElementDownloadCreate = injectIntl(({ params, element, intl, element_index, elements, setElements, setSelectedElement }) => {
	const [documentTempURL, setDocumentTempURL] = useState([{document: ""}]);
	const [additionalCounter, setAdditionalCounter] = useState([0]);

	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			files: [{file: null, description: ""}],
			element_index: element_index,
			title: "",
			description: "",
		}}
		validate={values => {
			const errors = {};

			if (!values.title) {
				errors.title = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			errors.files = [];
			values.files.forEach(document => {
				const error = {};

				if (!document.file) {
					error.file = intl.formatMessage({
						id: "AUTH.VALIDATION.REQUIRED_FIELD"
					});
				}

				errors.files.push(error);
			});

			let stop = false;
			errors.files.map((error, index) => {
				if(!stop) {
					if(Object.keys(error).length) {
						stop = true;
					}
					else if(index+1 === errors.files.length) {
						delete errors.files
					}
				}
			})

			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
				<div className="form-group">
					<TextField
						label="Title"
						color="secondary"
						multiline
						name="title"
						placeholder="Download for more info"
						margin="normal"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.title}
						helperText={touched.title && errors.title}
						error={Boolean(touched.title && errors.title)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>
				
				<FieldArray
					name="files"
					render={additionals => (<>
						<div className="row" style={{maxHeight: "400px", overflowY: "auto"}}>
							{
								//used simple array values that is not updating even after removing or add index in fieldarray
								//to avoid input type file onchange event not working bug
								additionalCounter.map((file, file_index) => {
									return <div key={file} className="col-6">
										<input
											id={"document_input_" + file_index}
											type="file"
											name={"document_input_" + file_index}
											accept=".doc, .docx, .csv, .xls, .xlsx, .ppt, .pptx, .pdf, .png, .jpg, .jpeg"
											className="d-none"
											onChange={documentBLOB => {
												let reader = new FileReader();

												if (documentBLOB.currentTarget.files[0]) {
													let files = values.files;
													  
													files[file_index].file = documentBLOB.currentTarget.files[0];

													setFieldValue("files", files);

													reader.readAsDataURL(
														documentBLOB.currentTarget.files[0]
													);

													reader.onload = document => {
														let documents = documentTempURL;
														documents[file_index].document = document.target.result;

														if(typeof documentTempURL[file_index].id !== "undefined") {
															documents[file_index].updated = true;
														}

														setDocumentTempURL([...documents]);
													};
												}
											}}
										/>
										
										<div style={{display: "flex", placeContent: "center", alignItems: "center"}}>
											<div>
												{(file_index != 0) ?
													<IconButton
														onClick={() => {
															if(typeof documentTempURL[file_index].id !== "undefined") {
																let existing_file_ids = values.del_existing_file;
																existing_file_ids.push(documentTempURL[file_index].id)
																setFieldValue("del_existing_file", existing_file_ids);
															}

															let files = values.files;
															files.splice(file_index, 1);
															setFieldValue("files", [...files]);

															let documents = documentTempURL;
															documents.splice(file_index, 1);
															setDocumentTempURL([...documents]);

															let counter_arr = additionalCounter;
															counter_arr.splice(file_index, 1);

															setAdditionalCounter([...counter_arr]);
														}}
													>
														<DeleteIcon style={{color: "red"}}/>
													</IconButton>
													: <div></div>
												}
											</div>

											<label htmlFor={"document_input_" + file_index} className="btn">
												{documentTempURL[file_index].document !== "" ? (
													<div className="text-center">
														<a
															href={
																typeof documentTempURL[file_index].id === "undefined" || typeof documentTempURL[file_index].updated !== "undefined"? 
																	window.URL.createObjectURL(values.files[file_index].file)
																:	process.env.REACT_APP_S3_BUCKET + element.attachments[0].directory + values.files[file_index].file
															}
															download={
																typeof documentTempURL[file_index].id === "undefined" || typeof documentTempURL[file_index].updated !== "undefined"? 
																	values.files[file_index].file.name
																:	values.files[file_index].file
															}
															className="btn p-0"
                                                            target="_blank"
														>
															<span>{
																typeof documentTempURL[file_index].id === "undefined" || typeof documentTempURL[file_index].updated !== "undefined" ? 
																	values.files[file_index].file.name
																:	typeof values.files[file_index].file == "string" && values.files[file_index].file //bugging if not using typeof string condition even if false in ternary operator
															}</span>
															<br />
															<span>Check!</span>
															
														</a>

														<label htmlFor={"document_input_" + file_index} className="text-center w-100 m-0 p-0 btn">
															<img
																width="50"
																src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
																alt="document"
																className="d-block mx-auto"
																style={{ maxHeight: "20vh" }}
															/>
															<span>Change!</span>
														</label>
													</div>
												) : (
													<div>
														<img
															alt="upload"
															className="w-100 d-block rounded border p-2"
															src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
															style={{ maxHeight: "20vh" }}
														/>
														<Typography>
															Attach
														</Typography>
														{Boolean((touched.files && touched.files[file_index] && touched.files[file_index].file && errors.files && errors.files[file_index] && errors.files[file_index].file)) && 
															<Typography align="center" className="text-danger">
																Document upload is required
															</Typography>}
													</div>
												)}
											</label>

											<div className="w-50">
												<TextField
													label="Description"
													color="secondary"
													multiline
													name={`files[${file_index}].description`}
													placeholder="Insert Description here"
													margin="normal"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.files[file_index].description}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</div>
										</div>
									</div>
								})
							}
						</div>
							
						<div className="form-group">
							<button
								type="button"
								className="btn text-success" 
								style={{paddingRight: "0px", paddingTop: "20px"}}
								onClick={() => {
									additionals.push({file: null, description: ""})
									setDocumentTempURL(prevState => [
										...prevState,
										{document: ""}
									])
									
									let counter_arr = additionalCounter;
									counter_arr.push(additionalCounter[additionalCounter.length - 1] + 1)
									setAdditionalCounter([...counter_arr]);
								}}
							>
								Add more document
							</button>
						</div>
					</>)}
				/>

				<div style={{display: "inline-flex"}}>
					<button
						type="submit"
						disabled={isSubmitting}
						className="btn btn-success my-auto mr-1"
					>
						Submit
					</button>
					
					<DeleteOwnerElement
						params={params}
						element={element}
						elementIndex={element_index}
						setSelectedElement={setSelectedElement}
						elements={elements}
						setElements={setElements}
					/>
				</div>
			</form>
		)}
	</Formik>
})

export const ElementDownloadEdit = injectIntl(({ params, element, intl, element_index, elements, setElements, setEditElementId }) => {
	let documents = [];

	const [documentTempURL, setDocumentTempURL] = useState([]);
	const [additionalCounter, setAdditionalCounter] = useState([]);

	useEffect(() => {
		let counter_arr = additionalCounter;

		element.attachments[0].attachment_additionals.map((additional, additional_index) => {
			let doc = {}
			doc.id = additional.id;
			doc.document = additional.context.file;
			doc.file = additional.context.file;
			doc.description = additional.context.description;
	
			documents.push(doc);
	
			if(additionalCounter.length == 0) {
				counter_arr.push(0)
			}
			else {
				counter_arr.push(additionalCounter[additionalCounter.length - 1] + 1)
			}
		});

		setDocumentTempURL([...documents])
		setAdditionalCounter([...counter_arr])
	}, [])


	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			files: documents,
			del_existing_file: [],
			element_index: element_index,
			title: element.attachments[0].title,
			description: element.attachments[0].description,
		}}
		validate={values => {
			const errors = {};

			if (!values.title) {
				errors.title = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			errors.files = [];
			values.files.forEach(document => {
				const error = {};

				if (!document.file) {
					error.file = intl.formatMessage({
						id: "AUTH.VALIDATION.REQUIRED_FIELD"
					});
				}

				errors.files.push(error);
			});

			let stop = false;
			errors.files.map((error, index) => {
				if(!stop) {
					if(Object.keys(error).length) {
						stop = true;
					}
					else if(index+1 === errors.files.length) {
						delete errors.files
					}
				}
			})

			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			update_owner_element_attachment(values).then(({ data: { element_query } }) => {
				if(element_query) {
					let newElements = [...elements];
					newElements[element_index] = element_query;
					setElements([]);
					setElements(newElements);
					setEditElementId(0)
					// window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
				<div className="form-group">
					<TextField
						label="Title"
						color="secondary"
						multiline
						name="title"
						placeholder="Download for more info"
						margin="normal"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.title}
						helperText={touched.title && errors.title}
						error={Boolean(touched.title && errors.title)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>
				
				<FieldArray
					name="files"
					render={additionals => (<>
						<div className="row" style={{maxHeight: "400px", overflowY: "auto"}}>
							{
								//used simple array values that is not updating even after removing or add index in fieldarray
								//to avoid input type file onchange event not working bug
								additionalCounter.map((file, file_index) => {
									return <div key={file} className="col-6">
										<input
											id={"document_input_" + file_index}
											type="file"
											name={"document_input_" + file_index}
											accept=".doc, .docx, .csv, .xls, .xlsx, .ppt, .pptx, .pdf, .png, .jpg, .jpeg"
											className="d-none"
											onChange={documentBLOB => {
												let reader = new FileReader();

												if (documentBLOB.currentTarget.files[0]) {
													let files = values.files;
													  
													files[file_index].file = documentBLOB.currentTarget.files[0];

													setFieldValue("files", files);

													reader.readAsDataURL(
														documentBLOB.currentTarget.files[0]
													);

													reader.onload = document => {
														let documents = documentTempURL;
														documents[file_index].document = document.target.result;

														if(typeof documentTempURL[file_index].id !== "undefined") {
															documents[file_index].updated = true;
														}

														setDocumentTempURL([...documents]);
													};
												}
											}}
										/>
										
										<div style={{display: "flex", placeContent: "center", alignItems: "center"}}>
											<div>
												{(file_index != 0) ?
													<IconButton
														onClick={() => {
															if(typeof documentTempURL[file_index].id !== "undefined") {
																let existing_file_ids = values.del_existing_file;
																existing_file_ids.push(documentTempURL[file_index].id)
																setFieldValue("del_existing_file", existing_file_ids);
															}

															let files = values.files;
															files.splice(file_index, 1);
															setFieldValue("files", [...files]);

															let documents = documentTempURL;
															documents.splice(file_index, 1);
															setDocumentTempURL([...documents]);

															let counter_arr = additionalCounter;
															counter_arr.splice(file_index, 1);

															setAdditionalCounter([...counter_arr]);
														}}
													>
														<DeleteIcon style={{color: "red"}}/>
													</IconButton>
													: <div></div>
												}
											</div>

											<label htmlFor={"document_input_" + file_index} className="btn">
												{documentTempURL[file_index].document !== "" ? (
													<div className="text-center">
														<a
															href={
																typeof documentTempURL[file_index].id === "undefined" || typeof documentTempURL[file_index].updated !== "undefined"? 
																	window.URL.createObjectURL(values.files[file_index].file)
																:	process.env.REACT_APP_S3_BUCKET + element.attachments[0].directory + values.files[file_index].file
															}
															download={
																typeof documentTempURL[file_index].id === "undefined" || typeof documentTempURL[file_index].updated !== "undefined"? 
																	values.files[file_index].file.name
																:	values.files[file_index].file
															}
															className="btn p-0"
                                                            target="_blank"
														>
															<span>{
																typeof documentTempURL[file_index].id === "undefined" || typeof documentTempURL[file_index].updated !== "undefined" ? 
																	values.files[file_index].file.name
																:	typeof values.files[file_index].file == "string" && values.files[file_index].file //bugging if not using typeof string condition even if false in ternary operator
															}</span>
															<br />
															<span>Check!</span>
															
														</a>

														<label htmlFor={"document_input_" + file_index} className="text-center w-100 m-0 p-0 btn">
															<img
																width="50"
																src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
																alt="document"
																className="d-block mx-auto"
																style={{ maxHeight: "20vh" }}
															/>
															<span>Change!</span>
														</label>
													</div>
												) : (
													<div>
														<img
															alt="upload"
															className="w-100 d-block rounded border p-2"
															src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
															style={{ maxHeight: "20vh" }}
														/>
														<Typography>
															Attach
														</Typography>
														{Boolean((touched.files && touched.files[file_index] && touched.files[file_index].file && errors.files && errors.files[file_index] && errors.files[file_index].file)) && 
															<Typography align="center" className="text-danger">
																Document upload is required
															</Typography>}
													</div>
												)}
											</label>

											<div className="w-50">
												<TextField
													label="Description"
													color="secondary"
													multiline
													name={`files[${file_index}].description`}
													placeholder="Insert Description here"
													margin="normal"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.files[file_index].description}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</div>
										</div>
									</div>
								})
							}
						</div>
							
						<div className="form-group">
							<button
								type="button"
								className="btn text-success" 
								style={{paddingRight: "0px", paddingTop: "20px"}}
								onClick={() => {
									additionals.push({file: null, description: ""})
									setDocumentTempURL(prevState => [
										...prevState,
										{document: ""}
									])
									
									let counter_arr = additionalCounter;
									counter_arr.push(additionalCounter[additionalCounter.length - 1] + 1)
									setAdditionalCounter([...counter_arr]);
								}}
							>
								Add more document
							</button>
						</div>
					</>)}
				/>

				<button
					type="submit"
					disabled={isSubmitting}
					className="btn btn-success d-block mx-auto"
				>
					Submit
				</button>
			</form>
		)}
	</Formik>
})

export const ElementDownloadView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Document" 
				src="https://lernnex.s3.amazonaws.com/element_type_icon/download_icon.png"
				style={{ maxHeight: 50 }}
				className="pr-2"
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title || "Download for more info"}
			</Typography> 
		</div>
		
		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 
			</div>
		)}

		<div className="row" style={{maxHeight: "400px", overflowY: "auto"}}>
			{attachment.attachment_additionals.map((attachment_additional, additional_index) => {
				return <div key={additional_index} className="col-6" style={{paddingTop: "20px"}}>
					<div style={{backgroundColor: "#EEF0F8", boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
						{/* <div style={{display: "flex", placeContent: "center", alignItems: "center"}}> */}
						<div style={{display: "inline-flex"}}>
							<div className="pr-3">
								<label htmlFor="document_input">
									<a
										href={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment_additional.context.file}
										download={attachment_additional.context.file}
										className="btn p-0"
										target="_blank"
									>
										{(() => {
											switch(attachment_additional.context.file.split(".").pop()) {
												case "doc":
												case "docx":
													return <img width="50" src="https://lernnex.s3.amazonaws.com/download_element_type/doc.png" alt="document" className="d-block mx-auto" style={{ maxHeight: "20vh", marginTop: "10px" }}/>
												case "csv":
												case "xls":
												case "xlsx":
													return <img width="50" src="https://lernnex.s3.amazonaws.com/download_element_type/excel.png" alt="document" className="d-block mx-auto" style={{ maxHeight: "20vh", marginTop: "10px" }}/>
												case "ppt":
												case "pptx":
													return <img width="50" src="https://lernnex.s3.amazonaws.com/download_element_type/ppt.png" alt="document" className="d-block mx-auto" style={{ maxHeight: "20vh", marginTop: "10px" }}/>
												case "pdf":
													return <img width="50" src="https://lernnex.s3.amazonaws.com/download_element_type/pdf.png" alt="document" className="d-block mx-auto" style={{ maxHeight: "20vh", marginTop: "10px" }}/>
												case "png":
												case "jpg":
												case "jpeg":
													return <img width="50" src="https://lernnex.s3.amazonaws.com/download_element_type/img.png" alt="document" className="d-block mx-auto" style={{ maxHeight: "20vh", marginTop: "10px" }}/>
												default:
													return <img width="50" src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png" alt="document" className="d-block mx-auto" style={{ maxHeight: "20vh", marginTop: "10px" }}/>;
											}
										})()}
									</a>
								</label>
							</div>
							
							<div style={{alignSelf: "center"}}>
								<span>
									{attachment_additional.context.description}
								</span>
							</div>
						</div>
					</div>
				</div>
			})}
		</div>
	</>
}
// End of Download Component