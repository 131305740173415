import React, { useState, useEffect } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField
} from '@material-ui/core';
import { getUserByToken } from "../../../../../modules/Auth/_redux/authCrud";
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';
import { update_user_image, update_user_name} from "../../../../../modules/Auth/_redux/authCrud";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import * as currentValues from "../../../../../modules/Auth/_redux/authRedux"
import { bindActionCreators } from "redux";
import { injectIntl } from 'react-intl'

const SetupAccount = injectIntl(({ actions }) => {
	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};
	const [openDialog, setOpenDialog] = useState(false);
	const [userAccount, setUserAccount] = useState({});
	const [imageSet, setImageSet] = useState(false);
	const [imageFile, setImageFile] = useState("");
	const [imageTempURL, setImageTempURL] = useState("");
	const [nameSet, setNameSet] = useState(false);
	const [firstname, setFirstname] = useState(false);
	const [lastname, setLastname] = useState(false);

	useEffect(() => {
		getUserByToken().then(({ data: { user } }) => {
			if(user) {
				//check if name is set
				if((user.firstname && user.lastname) && (user.firstname !== "null" && user.lastname !== "null")) {
					setNameSet(true);
				}
				else {
					setNameSet(false);
				}

				//check if image is set
				if(user.image_file) {
					setImageTempURL(`${process.env.REACT_APP_S3_BUCKET}/user_uploads/images/${user.image_file}`);
					setImageSet(`${process.env.REACT_APP_S3_BUCKET}/user_uploads/images/${user.image_file}`);
				}
				else {
					setImageSet(false);
				}

				//check if dialog should be open
				if((!user.firstname && !user.lastname) || (user.firstname == "null" || user.firstname == "null") || !user.image_file) {
					setOpenDialog(true);
				}
				else {
					setOpenDialog(false);
				}

				setUserAccount(user);
			}
		})
		.catch((err) => {
			console.log(err);
		})
	}, [])

	const handleCloseDiag = () => {
		setOpenDialog(false);
		window.sessionStorage.setItem("newly_loggedin", "false");
	}

	const profileImageFunc = () => {
		update_user_image(imageFile).then(({ data: { output } }) => {
			if(output.status == "success") {
				actions.setUser(output.data);
				snackbarAlert(output.status, output.message);
				setOpenDialog(false);
			}
			else {
				snackbarAlert(output.status, output.message);
			}
		})
		.catch(err => {
			console.log(err)
		})
	}

	const userNameFunc = () => {
		if(firstname || lastname) {
			update_user_name(firstname, lastname).then(({ data: { output } }) => {
				if(output.status == "success") {
					actions.setUser(output.data);
					snackbarAlert(output.status, output.message);
					setNameSet(true);
				}
				else {
					snackbarAlert(output.status, output.message);
				}
			})
			.catch(err => {
				console.log(err);
			})
		}
	}

	return <>
		{(window.sessionStorage.getItem("newly_loggedin") == "true") && (
			<Dialog open={openDialog} onClose={() => {if(nameSet){handleCloseDiag()}}} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
				<DialogTitle id="form-dialog-title" className="mx-auto">Complete Your Profile</DialogTitle>

				<DialogContent className="mx-auto">
					{
						!nameSet ? <DialogContentText>
							<span className="font-weight-bolder">Kindly set your name.</span>
						</DialogContentText>
						: <DialogContentText>
							<span className="font-weight-bolder">Kindly upload your photo to complete your profile.</span>
						</DialogContentText>
					}
				</DialogContent>
				
				{
					!nameSet ? <div style={{textAlign: "center"}}>
						<TextField
							className="w-75"
							color="secondary"
							label={<span>First name<span className="text-danger">*</span></span>}
							name="first_name"
							onBlur={(e) => {
								setFirstname(e.target.value);
							}}
							helperText={firstname === false ? false : !firstname ? "First Name is required" : false}
                            error={firstname === false ? false : !firstname ? true : false}
						/>
						<TextField
							className="w-75"
							color="secondary"
							label={<span>Last name<span className="text-danger">*</span></span>}
							name="last_name"
							onBlur={(e) => {
								setLastname(e.target.value);
							}}
							helperText={lastname === false ? false : !lastname ? "Last Name is required" : false}
                            error={lastname === false ? false : !lastname ? true : false}
						/>
					</div>
					: <>
						<input
							id="image_file"
							type="file"
							name="image_file"
							accept="image/*"
							className="d-none"
							onChange={imageBLOB => {
								let reader = new FileReader();

								if (imageBLOB.currentTarget.files[0]) {
									setImageFile(imageBLOB.currentTarget.files[0]);

									reader.readAsDataURL(
										imageBLOB.currentTarget.files[0]
									);

									reader.onload = image => {
										setImageTempURL(image.target.result);
									};
								}
							}}
						/>
					
						<div className="form-group mx-auto">
							<div
								className="image-input image-input-outline"
								id="kt_profile_avatar"
							>
								<img
									className="image-input-wrapper"
									src={imageTempURL ? imageTempURL : toAbsoluteUrl("/media/users/blank.png")}
								/>

								<label
									className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
									data-action="change"
									data-toggle="tooltip"
									title=""
									data-original-title="Change avatar"
									htmlFor="image_file"
								>
									<i className="fa fa-pen icon-sm text-muted"></i>
									<input type="hidden" name="profile_avatar_remove" />
								</label>
								
								<span
									className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
									data-action="remove"
									data-toggle="tooltip"
									title=""
									data-original-title="Remove avatar"
									onClick={() => {
										setImageTempURL("");
										setImageFile("");
									}}
								>
									<i className="ki ki-bold-close icon-xs text-muted"></i>
								</span>
							</div>

							{/* <span className="form-text text-muted">
								Allowed file types: png, jpg, jpeg.
							</span> */}
						</div>
					</>
				}

				<DialogActions>
					{nameSet && (
						<label>
							<Button onClick={() => handleCloseDiag()} className="text-danger">
								Close
							</Button>
						</label>
					)}
					
					{
						!nameSet ? 
							<label>
								<Button onClick={() => userNameFunc()} color="primary">
									Submit
								</Button>
							</label>
						: (imageTempURL) ? (
							<label>
								<Button onClick={() => profileImageFunc()} color="primary">
									Submit
								</Button>
							</label>
						) : (
							<label htmlFor="image_file">
								<Button component="span" color="primary">
									Upload
								</Button>
							</label> 
						)
					}
				</DialogActions>
			</Dialog>
		)}
	</>
});

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators(
			Object.assign(currentValues.actions),
			dispatch
		)
	};
};

export default connect(null, mapDispatchToProps)(SetupAccount);